import APIRestfulProvider from './restful-provider'
import PlatformManagementEndpoints from './dataservice/platform-mgmt'
import PropertyManagementEndpoints from './dataservice/property-mgmt'
import TenantServiceEndpoints from './dataservice/tenant'
import BuildingServiceEndpoints from './dataservice/building'
import AuthRestful from './dataservice/account/auth'
import HkAuthRestful from './dataservice/account/hkAuth'
import LeasingManagementEndpoints from './dataservice/leasing-mgmt'
import ValueAddedService from './dataservice/value-added'
import VisitorServiceEndpoints from './dataservice/visitor'
import AnalyticService from './dataservice/analytics/analytic-service'
import FacilityServiceEndpoints from './dataservice/facility'
import MarketServiceEndpoints from './dataservice/market'
import ShuttleBusServiceEndpoints from './dataservice/shuttle-bus'
import HTServiceEndpoints from './dataservice/ht'


export type SERVICE_DESTINATION =
  | 'tenant-service'
  | 'properties-management-service'
  | 'building-service'
  | 'platform-management'
  | 'leasing-management'

/**
 *
 * Should triage based on service destination -> and then based on features -> compose ->
 *
 *  > apiCall(feature: 'user-management')(destination: SERVICE_DESTINATION) => {
 *
 *
 * }
 *
 */

/**
 * Data Service to serve the app communication with server side
 * Underlying transportation is interchangable
 */
class APIService {
  provider: APIRestfulProvider

  auth: AuthRestful | HkAuthRestful
  platform: PlatformManagementEndpoints
  property: PropertyManagementEndpoints
  tenant: TenantServiceEndpoints
  building: BuildingServiceEndpoints
  leasing: LeasingManagementEndpoints
  va: ValueAddedService
  visitor: VisitorServiceEndpoints

  defaultAuth: AuthRestful
  HkAuth: HkAuthRestful

  analytic: AnalyticService
  facility: FacilityServiceEndpoints
  market: MarketServiceEndpoints
  shuttleBus: ShuttleBusServiceEndpoints

  ht:HTServiceEndpoints

  /**
   * Config the endpoints with transportation
   */
  constructor() {
    const restfulProvider = new APIRestfulProvider()
    this.provider = restfulProvider

    this.defaultAuth = new AuthRestful(restfulProvider)
    this.HkAuth = new HkAuthRestful(restfulProvider)

    this.auth = new AuthRestful(restfulProvider)
    this.platform = new PlatformManagementEndpoints(restfulProvider)
    this.property = new PropertyManagementEndpoints(restfulProvider)
    this.tenant = new TenantServiceEndpoints(restfulProvider)
    this.building = new BuildingServiceEndpoints(restfulProvider)
    this.leasing = new LeasingManagementEndpoints(restfulProvider)
    this.va = new ValueAddedService(restfulProvider)
    this.visitor = new VisitorServiceEndpoints(restfulProvider)
    this.analytic = new AnalyticService(restfulProvider)
    this.facility = new FacilityServiceEndpoints(restfulProvider)
    this.market = new MarketServiceEndpoints(restfulProvider)
    this.shuttleBus = new ShuttleBusServiceEndpoints(restfulProvider)
    this.ht = new HTServiceEndpoints(restfulProvider)
  }

  applyAuth = (object: any) => this.provider.applyAuth(object)
  removeAuth = () => this.provider.removeAuth()
  switchRegionCd = (code: string) => {
    switch (code) {
      case 'HK':
        this.auth = this.HkAuth
        break
      case 'CN':
      default:
        this.auth = this.defaultAuth
        break
    }
  }
}

export default new APIService()
