import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import asyncComponent from '../../util/asyncComponent'
import AppLayout from '../../containers/AppLayout'
import dashboardRoutes from './dashboard/DashboardRoutes'
import BMSRoutes from './bms/BMSRoutes'
import IOTRoutes from './platform-portal/iot/IOTRoutes'
import IAMRoutes from './iam/IAMRoutes'
import AccessControlRoutes from './access-control/AccessControlRoutes'
import TenantRoutes from './tenant-portal'
import PropertyRoutes from './property-portal'
import PlatformRoutes from './platform-portal'
import LeasingRoutes from './leasing-portal'
import ProfileRoutes from './profile'
import SettingRoutes from './setting'
import { useSelector } from 'state/root-store'


const errorRoute = [
  {
    path: "error/401",
    component: asyncComponent(() => import('./extraPages/routes/401'))
  },
  {
    component: asyncComponent(() => import('./extraPages/routes/404'))
  },
]

const FakeApp = () => <div>Route testing</div>

const UserRouting = () => {
  //
  const navigations: any[] = useSelector(s => s.navigation) as any[]

  console.log('[Navigations] Default Routing', navigations)

  if (navigations.length > 0){
    return <Redirect to={navigations[0].link} />
  }
  return <Redirect to="/app/dashboard" />
}

const routes = [{
    path: "/app",
    component: AppLayout, /// < Auth checking also here
    routes: [
      ...dashboardRoutes,
      ...BMSRoutes,
      ...IOTRoutes,
      ...TenantRoutes,
      ...PropertyRoutes,
      ...LeasingRoutes,
      ...PlatformRoutes,
      ...IAMRoutes,
      ...AccessControlRoutes,
      ...ProfileRoutes,
      ...SettingRoutes,
      {
        path: "/app",
        exact: true,
        component: () => <UserRouting />
      },
      ...errorRoute
    ]
  }]

export default routes
