import * as R from 'ramda'
import {
  // axios,
  AxiosInstance,
  AxiosResponse,
} from 'axios'
import { SpendingTransaction } from '../../../schema'
import APIRestfulProvider, {
  PaginatedResponse,
  throwError,
  APIHTTPResponse,
  parseResult,
  parseResults,
} from '../../restful-provider'

class SpendingTransactionRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/self-service-points'

  // getRepeciptsByPageLeasing = (campus_id, params) => {
  //   const args = R.reject(
  //     R.isEmpty,
  //     R.pick(
  //       [
  //        //'page_number',
  //         'page_size',
  //         'order_by',
  //         'sort_by',
  //         'receipt_date_to',
  //         'receipt_date_from',
  //         'search',
  //         'is_receipt_duplicated',
  //         'status',
  //       ],
  //       params,
  //     ),
  //   )
  //   console.log('params',params)
  //   return this.client
  //     .post<PaginatedResponse<SpendingTransaction>>(`${this.subRoute}/receipt/list/page/leasing`, {
  //       ...args,
  //       campus_ids: campus_id ? [campus_id] : [],
  //      // page_number:1
  //     })
  //     .then((d) => d.data)
  //     .catch(throwError)
  // }

  getRepeciptsByPageLeasing = (campusId: string, params) => {
    const is_receipt_duplicated = params?.is_receipt_duplicated
      ? { is_receipt_duplicated: params?.is_receipt_duplicated === 'false' ? false : true }
      : {}
    const args = {
      campus_ids: campusId ? [campusId] : [],
      merchant_ids: params?.merchant_ids ? [params?.merchant_ids] : [],
      status: params?.status ? [params?.status] : [],
      ...is_receipt_duplicated,
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(
          ['page_number', 'page_size', 'order_by', 'sort_by', 'receipt_date_to', 'receipt_date_from', 'search'],
          params,
        ),
      ),
    }
    console.log('args', args)
    return this.client
      .post<PaginatedResponse<SpendingTransaction>>(`${this.subRoute}/receipt/list/page/leasing`, args)
      .then((d) => d.data)
  }

  getRepeciptsByMember = (id) => {
    return this.client
      .post<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/receipt/list`, {
        related_receipt_id: id,
      })
      .then((d) => d.data.result)
      .catch(throwError)
  }

  approvalRepecipts = (id: number, params) => {
    return this.client
      .put<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/receipt/${id}/approval`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getChangeHistoryData = (id: string) => {
    return this.client
      .get<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/receipt/${id}/histories`)
      .then(parseResults)
      .catch(throwError)
  }

  getMerchantSummaryByPage = (campusId: string, params) => {
    const args = {
      campus_ids: campusId ? [campusId] : [],
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'receipt_date_to', 'receipt_date_from'], params),
      ),
    }
    return this.client
      .post<PaginatedResponse<SpendingTransaction>>(`${this.subRoute}/receipt/merchant/summary/page`, args)
      .then((d) => d.data)
      .catch(throwError)
  }

  getMerchantSummaryTotal = (campusId: string, params) => {
    const args = {
      campus_ids: campusId ? [campusId] : [],
      search: '',
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['receipt_date_to', 'receipt_date_from'], params)),
    }
    return this.client
      .post<APIHTTPResponse<{ amount: number }>>(`${this.subRoute}/receipt/merchant/summary`, args)
      .then(parseResult)
      .catch(throwError)
  }

  downloadMerchantSummary = (campusId: string, params) => {
    const args = {
      campus_ids: campusId ? [campusId] : [],
      search: '',
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['receipt_date_to', 'receipt_date_from'], params)),
    }
    return this.client
      .post<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/receipt/merchant/summary/export`, args)
      .then(parseResult)
      .catch(throwError)
  }

  getDenyReasonByPage = (params) => {
    const args = {
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['order_by', 'sort_by'], params)),
    }
    return this.client
      .post<PaginatedResponse<SpendingTransaction>>(`${this.subRoute}/deny-reason/list/page`, args)
      .then((d) => d.data)
      .catch(throwError)
  }

  getDenyReasonById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/deny-reason/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  cerateDenyReason = (params) => {
    return this.client
      .post<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/deny-reason`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateDenyReason = (id: string, params) => {
    return this.client
      .put<APIHTTPResponse<SpendingTransaction>>(`${this.subRoute}/deny-reason/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getDenyReasonlist = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${this.subRoute}/deny-reason/list`)
      .then(parseResults)
      .catch(throwError)
  }
}

export default SpendingTransactionRestful
