import { AxiosInstance } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

/**
 * Restful endpoints for AccessControl module
 */
class PropertyAccessControlManagement {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }


    
}

export default PropertyAccessControlManagement
