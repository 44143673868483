import { RequestActionFunctions, action } from './index'

import {
    // AuthUser,
    Device,
    DataModelRecordPair,
    SimpleSpace,
    DataModelType,
    WorkOrderListItem,
    PaginationResponse,
} from '../../schema'
import { createRequestActionStrings } from './bms.helper'

// Devices Related
export const ACTION_KEY_WORK_ORDER = {


    WORK_ORDER_OVERVIEW: createRequestActionStrings('WORK_ORDER_OVERVIEW'),
    WORK_ORDER_DETAIL: createRequestActionStrings('WORK_ORDER_DETAIL'),

    RESET_FILTER: 'WORK_ORDER_RESET_FILTER',
    UPDATE_FILTER: 'WORK_ORDER_UPDATE_FILTER',
    UPDATED_FILTER: 'WORK_ORDER_UPDATED_FILTER',
    UPDATED_FILTER_FAIL: 'WORK_ORDER_UPDATED_FILTER_FAIL',

    UPDATE_SEARCH_STRING: 'WORK_ORDER_UPDATE_SEARCH_STRING',
}

const changeFilterResult = (filter: any, orders: WorkOrderListItem[], pagination: PaginationResponse, types, statuses, campuses, areas, overview = null) => action(ACTION_KEY_WORK_ORDER.UPDATED_FILTER, { filter, orders, pagination, types, statuses, campuses, areas, overview })
const changeFilterResultFailed = (error) => action(ACTION_KEY_WORK_ORDER.UPDATED_FILTER_FAIL, error)


const changeFilter = (filter: any) => action(ACTION_KEY_WORK_ORDER.UPDATE_FILTER, filter)
const changeSearchString = (search: string) => action(ACTION_KEY_WORK_ORDER.UPDATE_SEARCH_STRING, search)

const resetFilter = () => action(ACTION_KEY_WORK_ORDER.RESET_FILTER)

export const workOrderOverview: RequestActionFunctions = {
    request: () => action(ACTION_KEY_WORK_ORDER.WORK_ORDER_OVERVIEW.REQUEST),
    success: ({ overview, statuses }) =>
        action(ACTION_KEY_WORK_ORDER.WORK_ORDER_OVERVIEW.SUCCESS, { overview, statuses }),
    failure: (error) => action(ACTION_KEY_WORK_ORDER.WORK_ORDER_OVERVIEW.FAILURE, error),
}


export const workOrderDetail: RequestActionFunctions = {
    request: (id: string) => action(ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.REQUEST, id),
    success: (
        order: WorkOrderListItem
    ) => action(ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.SUCCESS, { order }),
    failure: (error) => action(ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.FAILURE, error),
}



export const WorkOrderActions = {
    changeFilter,
    changeSearchString,
    changeFilterResult,
    changeFilterResultFailed,
    resetFilter,
    workOrderOverview,
    workOrderDetail,
}
