import localStorageService from "./localStorageService"
import APIService from "./apiService"
import { TokenResult, AuthUser } from "../schema"

const decode = require('jwt-decode')

class JwtAuthService {





  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (email, password, organization_identifier) => {

    return APIService.auth.authorize(email, password, organization_identifier).then(data => {
      console.warn('[JwtAuthService] loginWithEmailAndPassword', data)
      let obj: AuthUser = null
      if (Array.isArray(data)) {
        obj = data[0]
      } else {
        obj = data
      }

      this.setUser(obj)
      this.setSession(this.extractToken(obj))
      return obj
    })

  }

  // You can define roles in app/auth/authRoles.js
  loginWithSMS = (area_code, phone_number, verify_code) => {

    return APIService.auth.verifySMS(area_code, phone_number, verify_code).then(data => {
      console.warn('APIService.auth.verifySMS', data)
      
      let obj: AuthUser | any = null

      if (Array.isArray(data)) {
        obj = data[0]
      } else {
        obj = data
      }
      
      console.warn(obj)
      if (!obj?.registration_token) {
        this.setUser(obj)
        this.setSession(this.extractToken(obj))
      } else {
        console.warn('new user', obj.registration_token)
        throw {message: 'User is not being registered'}
      }

      return obj
    })

  }

  

  assertJWT = (jwt: string) => {
    const decoded = decode(jwt)
    const now = Date.now().valueOf() / 1000
    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
      // throw new Error(`AUTH JWT expired: ${JSON.stringify(decoded)}`)
      return false
    } else {
      return true
    }
  }

  verifyToken = () => {
    const token: TokenResult = this.getToken()
    console.warn('[JwtAuthService] verifyToken')
    if (!token) return null
    const { access_token } = token
    
    try {
      const valid = this.assertJWT(access_token)
      if (!valid) {
        console.warn('[JwtAuthService] expired. Refresh Token now ')
        return this.refreshToken()
      } else {
        console.warn('[JwtAuthService] notExpired')
        this.setSession(token)
        return this.getUser()
      }
    } catch (error) {
        return null
    }
  }

 

  refreshToken = () => {
    const token = this.getToken()
    if (!token) return null
    const { refresh_token } = token
    return APIService.auth.refreshToken(refresh_token).then(data => {
      const newToken = data[0]
      this.setSession(newToken)

      const user = {...this.getUser(), 
        access_token: newToken.access_token, 
        refresh_token: newToken.refresh_token,
        expires_in: newToken.expires_in
      }

      this.setUser(user)
      return user
    }).catch(error => {
      return null
    })
  }

  extractToken = (token: TokenResult) => {
    const { access_token, refresh_token, token_type, expires_in } = token
    return { access_token, refresh_token, token_type, expires_in }
  }


  logout = () => {
    this.setSession(null)
    this.removeUser()
  }

  currentToken: TokenResult = null

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token: TokenResult) => {

    if (this.currentToken && token && 
      this.currentToken.access_token === token.access_token && 
      this.currentToken.refresh_token === token.refresh_token) return 

    if (token) {
      console.warn('[JwtAuthService] setSession', token.access_token)
      localStorageService.setItem("token", token)
      APIService.applyAuth({ token: token.access_token })
    } else {
      console.warn('[JwtAuthService] remove token in local storage')
      localStorageService.removeItem("token")
      APIService.removeAuth()
    }
    this.currentToken = token
  }

  getToken = (() => localStorageService.getItem("token"))
  getUser = () => localStorageService.getItem("auth_user")

  setUser = (user: AuthUser) => localStorageService.setItem("auth_user", user)
  removeUser = () => localStorageService.removeItem("auth_user")

}

export default new JwtAuthService()
