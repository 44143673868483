import APIRestfulProvider from "../../restful-provider"
import LeasingAPIManagementRestful from "./api-key"
import LeasingAnalyticsRestful from "./analytics"
import AppConfigServiceRestful from "./app-config-service"
import LeasingCampusInfoRestful from "./leasing-campus"
import LeasingUserManagementRestful from "./leasing-user"
import PropertyMgmtComapnyRestful from "./property-mgmt-company"
import LeasingTenantRestful from "./tenant"
import LeasingTenantUserRestful from "./tenant-user"
import LeasingTenantVisitorRestful from "./tenant-visitor"
import CommunityServiceRestful from "./community-service"
import GameServiceEndpoints from './game'
import MerchantsEndpoints from './merchants'


/**
 * Leasing Management Services
 */

class LeasingManagementEndpoints {
    analytics: LeasingAnalyticsRestful
    appConfigService: AppConfigServiceRestful
    tenantManagement: LeasingTenantRestful
    tenantUserManagement: LeasingTenantUserRestful
    tenantVisitorManagement: LeasingTenantVisitorRestful
    userManagement: LeasingUserManagementRestful
    apiKeyManagement: LeasingAPIManagementRestful
    PMCManagement: PropertyMgmtComapnyRestful
    campusInfo: LeasingCampusInfoRestful
    communityService: CommunityServiceRestful
    game: GameServiceEndpoints
    merchant:MerchantsEndpoints

    constructor(provider: APIRestfulProvider) {
        this.analytics = new LeasingAnalyticsRestful(provider)
        this.appConfigService = new AppConfigServiceRestful(provider)
        this.tenantManagement = new LeasingTenantRestful(provider)
        this.tenantUserManagement = new LeasingTenantUserRestful(provider)
        this.tenantVisitorManagement = new LeasingTenantVisitorRestful(provider)
        this.userManagement = new LeasingUserManagementRestful(provider)
        this.apiKeyManagement = new LeasingAPIManagementRestful(provider)
        this.PMCManagement = new PropertyMgmtComapnyRestful(provider)
        this.campusInfo = new LeasingCampusInfoRestful(provider)
        this.communityService = new CommunityServiceRestful(provider)
        this.game = new GameServiceEndpoints(provider)
        this.merchant=new MerchantsEndpoints(provider)
    }
}

export default LeasingManagementEndpoints
