import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { Tenant, User, UserGroup, BaseUser } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class LeasingTenantUserRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }
    

    subRoute = '/leasing-management/tenant-management/user-management'

    //// ============================= ////

    adminsInTenant = (tenant_id: string, user_id?: string) =>
        this.client
            .get<APIHTTPResponse<BaseUser>>(`${this.subRoute}/get-tenant-admins-in-tenant`, {
                params: { 
                    tenant_id,
                    user_id 
                },
            })
            .then(parseResult)
            .catch(throwError)

    addUserToTenant = (user_id: string, tenant_id: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/add-user-to-tenant`, {
                user_id,
                tenant_id
            })
            .then(parseResult)
            .catch(throwError)

    dropUserFromTenant = (user_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/drop-user-from-tenant`, {
                params: {user_id}
            })
            .then(parseResult)
            .catch(throwError)


    getUserGroupInTenant = (tenant_id: string, user_group_id?: string) =>
        this.client
            .get<APIHTTPResponse<UserGroup>>(`${this.subRoute}/get-user-groups-in-tenant`, {
                params: {
                    tenant_id,
                    user_group_id,
                }
            })
            .then(parseResult)
            .catch(throwError)

            
    addUserAsTenantAdmin = (tenant_id: string, username: string) =>
    this.client
        .post<APIHTTPResponse<Tenant>>(`${this.subRoute}/set-user-as-tenant-admin`, {
            tenant_id,
            username,
        })
        .then(parseResult)
        .catch(throwError)

            
    dropUserToTenantUserGroup = (user_id: string, tenant_id: string, user_group_id: string) =>
    this.client
        .delete<APIHTTPResponse<Tenant>>(`${this.subRoute}/drop-user-from-tenant-user-group`, {
            params: {
                tenant_id,
                user_group_id,
                user_id
            }
        })
        .then(parseResult)
        .catch(throwError)
    

    assignRoleToTenantUserGroup = (role_id: string, tenant_id: string, user_group_id: string) =>
    this.client
        .post<APIHTTPResponse<Tenant>>(`${this.subRoute}/assign-role-to-tenant-user-group`, {
                tenant_id,
                user_group_id,
                role_id
        })
        .then(parseResult)
        .catch(throwError)
    

    unassignRoleFromTenantUserGroup = (role_id: string, tenant_id: string, user_group_id: string) => 
    this.client
        .delete<APIHTTPResponse<Tenant>>(`${this.subRoute}/unassign-role-from-tenant-user-group`, {
            params: {
                tenant_id,
                user_group_id,
                role_id
            }
        })
        .then(parseResult)
        .catch(throwError)
    


}

export default LeasingTenantUserRestful
