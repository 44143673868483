import {
  all,
  // take,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import {
  MerchantType
} from '../../schema'
import { Action, ACTION_KEY_HT, HTActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'

function* MerchantTypeRequest() {
  console.group('[SAGA] MerchantTypeRequest')

  try {

      yield put(hrdpActions.fetchStart())
      const merchantType: MerchantType[] = yield call(apiService.leasing.merchant.getMerchantTypes) ||[]
      console.log('merchantType', merchantType)

      yield put(HTActions.merchantType.success(merchantType))
      yield put(hrdpActions.fetchSuccess())
  } catch (error) {
      console.error('[SAGA] deviceDetailRequest', error)
      yield put(HTActions.merchantType.failure(error))
      yield put(
          NotificationActionFunctions.enqueue({
              message: error,
              type: 'error',
          }),
      )
      yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

export const htSagas = [
  takeEvery(ACTION_KEY_HT.MERCHANT_TYPE.REQUEST, MerchantTypeRequest),

]