import APIRestfulProvider from '../../restful-provider'
import CampusRestful from '../platform-mgmt/campus'
import PropertyVisitorServices from './visitor'
import TenantRestful from './tenant'
import PropertyUserManagement from './user'
import PropertyAccessControlManagement from './access-control'
import PropertyGeneralService from './general'
import SpecialAccessManagement from './special-access'
import PropertyCampusInfoRestful from './property-campus'
import WorkOrderRestful from './work-order'

/**
 * Platform Management Services
 */

class PropertyManagementEndpoints {
    user: PropertyUserManagement
    visitor: PropertyVisitorServices
    tenant: TenantRestful
    accessControl: PropertyAccessControlManagement
    general: PropertyGeneralService
    specialAccess: SpecialAccessManagement
    campus: PropertyCampusInfoRestful
    workOrder: WorkOrderRestful

    constructor(provider: APIRestfulProvider) {
        this.user = new PropertyUserManagement(provider)
        this.visitor = new PropertyVisitorServices(provider)
        this.general = new PropertyGeneralService(provider)
        this.tenant = new TenantRestful(provider)
        this.accessControl = new PropertyAccessControlManagement(provider)
        this.specialAccess = new SpecialAccessManagement(provider)
        this.campus = new PropertyCampusInfoRestful(provider)
        this.workOrder = new WorkOrderRestful(provider)
    }
}

export default PropertyManagementEndpoints
