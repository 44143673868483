import { RequestActionFunctions, action } from './index'
import { createRequestActionStrings } from './bms.helper'
import { DeviceType, SimpleBuilding, SimpleCampus, CreatingBuilding, SimpleFloor, SimpleSpace } from '../../schema'

// BMS Related
export const ACTION_KEY_BMS = {
    CAMPUS_LIST: createRequestActionStrings('CAMPUS_LIST'),
    BUILDING_LIST: createRequestActionStrings('BUILDING_LIST'),
    FLOOR_LIST: createRequestActionStrings('FLOOR_LIST'),
    SPACE_LIST: createRequestActionStrings('SPACE_LIST'),

    CAMPUS_DETAIL: createRequestActionStrings('CAMPUS_DETAIL'),
    BUILDING_DETAIL: createRequestActionStrings('BUILDING_DETAIL'),
    FLOOR_DETAIL: createRequestActionStrings('FLOOR_DETAIL'),
    SPACE_DETAIL: createRequestActionStrings('SPACE_DETAIL'),

    CAMPUS_DETAIL_CREATE_BUILDING: createRequestActionStrings('CAMPUS_DETAIL_CREATE_BUILDING'),
    CAMPUS_DETAIL_CREATE_SPACE: createRequestActionStrings('CAMPUS_DETAIL_CREATE_SPACE'),

    BUILDING_DETAIL_CREATE_FLOOR: createRequestActionStrings('BUILDING_DETAIL_CREATE_FLOOR'),
    BUILDING_DETAIL_CREATE_SPACE: createRequestActionStrings('BUILDING_DETAIL_CREATE_SPACE'),

    FLOOR_DETAIL_CREATE_SPACE: createRequestActionStrings('FLOOR_DETAIL_CREATE_SPACE'),
    SPACE_DETAIL_CREATE_SPACE: createRequestActionStrings('SPACE_DETAIL_CREATE_SPACE'),
}

// Campus List
const campusList = {
    request: () => action(ACTION_KEY_BMS.CAMPUS_LIST.REQUEST),
    success: (campuses: SimpleCampus[]) => action(ACTION_KEY_BMS.CAMPUS_LIST.SUCCESS, campuses),
    failure: (error) => action(ACTION_KEY_BMS.CAMPUS_LIST.FAILURE, error),
}

// Building List
const buildingList = {
    request: () => action(ACTION_KEY_BMS.BUILDING_LIST.REQUEST),
    success: (buildings: SimpleBuilding[]) => action(ACTION_KEY_BMS.BUILDING_LIST.SUCCESS, buildings),
    failure: (error) => action(ACTION_KEY_BMS.BUILDING_LIST.FAILURE, error),
}

// Floor List
const floorList = {
    request: () => action(ACTION_KEY_BMS.FLOOR_LIST.REQUEST),
    success: (floors: DeviceType[]) => action(ACTION_KEY_BMS.FLOOR_LIST.SUCCESS, floors),
    failure: (error) => action(ACTION_KEY_BMS.FLOOR_LIST.FAILURE, error),
}

// Space List
const spaceList = {
    request: () => action(ACTION_KEY_BMS.SPACE_LIST.REQUEST),
    success: (spaces: DeviceType[]) => action(ACTION_KEY_BMS.SPACE_LIST.SUCCESS, spaces),
    failure: (error) => action(ACTION_KEY_BMS.SPACE_LIST.FAILURE, error),
}

/////////////

// Campus Detail
const campusDetail = {
    request: (id: string) => action(ACTION_KEY_BMS.CAMPUS_DETAIL.REQUEST, id),
    success: (detail: { campus: SimpleCampus, buildings: SimpleBuilding[], spaces: SimpleSpace[] }) =>
        action(ACTION_KEY_BMS.CAMPUS_DETAIL.SUCCESS, detail),
    failure: (error) => action(ACTION_KEY_BMS.CAMPUS_DETAIL.FAILURE, error),
}

// Campus Detail - Create Building
const campusDetailCreateBuilding = {
    request: (building: CreatingBuilding) => action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.REQUEST, building),
    success: (buildingResponse: any) => action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.SUCCESS, buildingResponse),
    failure: (error) => action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.FAILURE, error),
}

// Campus Detail - Create Space
const campusDetailCreateSpace = {
    request: (creatingSpace: { campus_id: string, space_name: string }) =>
        action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.REQUEST, creatingSpace),
    success: (space: any) => action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.SUCCESS, space),
    failure: (error) => action(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.FAILURE, error),
}

// Building Detail
const buildingDetail = {
    request: (id: string ) => action(ACTION_KEY_BMS.BUILDING_DETAIL.REQUEST, id),
    success: (detail: { building: SimpleBuilding, floors: SimpleFloor[], spaces: SimpleSpace[] }) =>
        action(ACTION_KEY_BMS.BUILDING_DETAIL.SUCCESS, detail),
    failure: (error) => action(ACTION_KEY_BMS.BUILDING_DETAIL.FAILURE, error),
}

// Building Detail - Create Floor
const buildingDetailCreateFloor = {
    request: (creatingFloor: { building_id: string, display_name: string, idx: number }) =>
        action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.REQUEST, creatingFloor),
    success: (response: any) => action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.FAILURE, error),
}

// Building Detail - Create Space
const buildingDetailCreateSpace = {
    request: (creatingSpace: { building_id: string, space_name: string }) =>
        action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.REQUEST, creatingSpace),
    success: (response: any) => action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.FAILURE, error),
}

// Floor Detail
const floorDetail = {
    request: (id: string) => action(ACTION_KEY_BMS.FLOOR_DETAIL.REQUEST, id),
    success: (detail: { floor: SimpleFloor, spaces: SimpleSpace[] }) =>
        action(ACTION_KEY_BMS.FLOOR_DETAIL.SUCCESS, detail),
    failure: (error) => action(ACTION_KEY_BMS.FLOOR_DETAIL.FAILURE, error),
}

// Floor Detail - Create Space
const floorDetailCreateSpace = {
    request: (creatingSpace: { floor_id: string, space_name: string }) =>
        action(ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.REQUEST, creatingSpace),
    success: (response: any) => action(ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.FAILURE, error),
}

// Space Detail
const spaceDetail = {
    request: (id: string) => action(ACTION_KEY_BMS.SPACE_DETAIL.REQUEST, id),
    success: (spaceInfo: any) => action(ACTION_KEY_BMS.SPACE_DETAIL.SUCCESS, spaceInfo),
    failure: (error) => action(ACTION_KEY_BMS.SPACE_DETAIL.FAILURE, error),
}

// Building Detail - Create Space
const spaceDetailCreateSpace = {
    request: (id: string) => action(ACTION_KEY_BMS.SPACE_DETAIL_CREATE_SPACE.REQUEST, id),
    success: (response: any) => action(ACTION_KEY_BMS.SPACE_DETAIL_CREATE_SPACE.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_BMS.SPACE_DETAIL_CREATE_SPACE.FAILURE, error),
}

export const BMSActions = {
    campusList,
    buildingList,
    floorList,
    spaceList,

    campusDetail,
    buildingDetail,
    floorDetail,
    spaceDetail,

    campusDetailCreateBuilding,
    campusDetailCreateSpace,

    buildingDetailCreateFloor,
    buildingDetailCreateSpace,

    floorDetailCreateSpace,
    spaceDetailCreateSpace,
}
