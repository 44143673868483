import { omit } from 'ramda'
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { LocalizationObject, Tenant } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class LeasingTenantRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/leasing-management/tenant-management'

    get = (id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    list = (occupy?: boolean) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants`, {
                params: occupy !== undefined ? { occupy } : {},
            })
            .then(parseResult)
            .catch(throwError)

    listByCampus = (campus_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants-by-campus-id`, {
                params: { campus_id },
            })
            .then(parseResult)
            .catch(throwError)

    listByBuilding = (building_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants-by-building-id`, {
                params: { building_id },
            })
            .then(parseResult)
            .catch(throwError)

    createTenant = (tenant: Tenant & LocalizationObject) =>
        this.client
            .post<APIHTTPResponse<Tenant>>(`${this.subRoute}/create-tenant`, omit(['display_name'], tenant))
            .then(parseResult)
            .catch(throwError)

    removeTenant = (tenant: Tenant) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/remove-tenant`, { params: { id: tenant?.id } })
            .then(parseResult)
            .catch(throwError)

    edit = ({ id, ...args }: { id: string, description: string } & LocalizationObject) =>
        this.client
            .put<APIHTTPResponse<Tenant>>(
                `${this.subRoute}/edit-tenant-info`,
                args,
                {
                    params: { id },
                },
            )
            .then(parseResult)
            .catch(throwError)

    getAvailableSpaceInFloor = (floor_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-unoccupied-spaces-in-floor`, { params: { floor_id } })
            .then(parseResult)
            .catch(throwError)

    getNumberOfStaff = (id: string) =>
        this.client
            .get<APIHTTPResponse<{ number_of_staff: number }>>(`${this.subRoute}/get-number-of-staff-by-tenant-id`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    associateLocation = (id: string, space_id: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/associate-tenant-with-space`, {
                tenant_id: id,
                space_id,
            })
            .then(parseResult)
            .catch(throwError)

    unassociateLocation = (id: string, space_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/unassociate-tenant-with-space`, {
                params: { tenant_id: id, space_id },
            })
            .then(parseResult)
            .catch(throwError)
}

export default LeasingTenantRestful
