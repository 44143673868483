import React from "react"

const SettingRoutes = [
    {
        path: "/app/setting",
        component: React.lazy(() => import("./setting")),
      },

]

export default SettingRoutes

