import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux'
import { createStore, compose, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import sagaMonitor from '@redux-saga/simple-saga-monitor'
import { routerMiddleware } from 'connected-react-router'
import { NavigationReducer } from '@exaleap/common'
import  HRDPRootReducer from './reducers/root-reducer'
import { history } from '@exaleap/common'
import { RootSaga } from '@exaleap/common'
import { apiService } from '@exaleap/common'
const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

const middlewares = [sagaMiddleware, routerMiddleware(history)]
let devtools = (x) => x

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && composeWithDevTools) {
    devtools = composeWithDevTools()
}

const initialState = {}

// console.log('const combined = HRDPRootReducer(history, NavigationReducer)',history)
const combined = HRDPRootReducer(history, NavigationReducer)

type RootHRDPState = ReturnType<typeof combined>

const HRDPStore = createStore(
    combined,
    initialState,
    devtools ? compose(applyMiddleware(...middlewares), devtools) : applyMiddleware(...middlewares),
)

console.log('RootSaga', RootSaga)
sagaMiddleware.run(RootSaga)

export const useSelector: TypedUseSelectorHook<RootHRDPState> = useReduxSelector
export { HRDPStore }

apiService.provider.setStore(HRDPStore)
