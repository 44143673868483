import { call, put, takeEvery, all, select } from 'redux-saga/effects'
import {
    Action,
    NotificationActionFunctions,
    ACTION_KEY_ACCESS_CONTROL,
    AccessControlActions,
    ACTION_KEY_LOCATION_SELECT,
    LocationSelectActions,
} from '../actions'
import { apiService, hrdpActions } from '../../..'
import { HierarchyBuilding, HierarchySpace, PermissionType, SimpleBuilding, SimpleSpace } from '../../schema'


const campusInfo = (permissionType: PermissionType) => {
    switch (permissionType) {
        case 'TM':
            return apiService.building.campusInfo
        case 'PMS':
            return apiService.property.campus
        case 'LTUM':
            return apiService.leasing.campusInfo
       default:
            return apiService.building.campusInfo
    }
}

function* locationExclusiveSelectStart(action: Action) {
    console.warn('[SAGA] locationSelectStart')
    yield put(hrdpActions.fetchStart())

    try {
        let campuses = yield select((s) => s.locationSelect.campusOptions)
        const permissionType = yield select((s) => s.locationSelect.permissionType)

        if (campuses.length === 0) {
            campuses = yield call(campusInfo(permissionType).getCampuses)
        }

        yield put(LocationSelectActions.showLocationExclusiveSelection.loaded(campuses))
        yield put(hrdpActions.fetchSuccess())
    } catch (error) {
        console.warn('[SAGA] locationSelectStart error', error)
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}

function* locationSelectStart(action: Action) {
    console.warn('[SAGA] locationSelectStart')

    yield put(hrdpActions.fetchStart())

    try {
        let campuses = yield select((s) => s.locationSelect.campusOptions)
        const permissionType = yield select((s) => s.locationSelect.permissionType)

        if (campuses.length === 0) {
            campuses = yield call(campusInfo(permissionType).getCampuses)
        }

        yield put(LocationSelectActions.showLocationSelection.loaded(campuses))
        yield put(hrdpActions.fetchSuccess())
    } catch (error) {
        console.warn('[SAGA] locationSelectStart error', error)
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}

function* fetchBuilding(action: Action) {
    console.warn('[SAGA] fetchBuilding')
    const campus_id: string = action.payload.id

    const filterTenant = yield select((s) => s.locationSelect.filterTenant)
    const spaceTypes = yield select((s) => s.locationSelect.spaceTypes)
    const permissionType = yield select((s) => s.locationSelect.permissionType)


    yield put(hrdpActions.fetchStart())
    try {
        const buildings = yield call(campusInfo(permissionType).getBuildingsInCampus, campus_id)
        const simpleSpaces = yield call(
            campusInfo(permissionType).getSpacesInCampus,
            campus_id,
            filterTenant,
            spaceTypes,
        )
        const spaces: HierarchySpace[] = simpleSpaces.map((s) => ({
            ...s,
            hierarchy: {
                campus: action.payload,
            },
        }))

        yield put(hrdpActions.fetchSuccess())
        yield put(LocationSelectActions.fetchedBuilding(buildings, spaces))
    } catch (error) {
        console.warn('[SAGA] fetchBuilding error', error)
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}

function* fetchFloor(action: Action) {
    console.warn('[SAGA] fetchFloor')

    const locationSelect = yield select((state) => state.locationSelect)

    const filterTenant = yield select((s) => s.locationSelect.filterTenant)
    const spaceTypes = yield select((s) => s.locationSelect.spaceTypes)

    const permissionType = yield select((s) => s.locationSelect.permissionType)


    const building_id: string = action.payload.id
    yield put(hrdpActions.fetchStart())

    try {
        const floors = yield call(campusInfo(permissionType).getFloorsInBuilding, building_id)
        const simpleSpaces: SimpleSpace[] = yield call(
            campusInfo(permissionType).getSpacesInBuilding,
            building_id,
            filterTenant,
            spaceTypes,
        )
        const spaces: HierarchySpace[] = simpleSpaces.map((s) => ({
            ...s,
            hierarchy: {
                campus: locationSelect.selectedCampus,
                building: action.payload,
            },
        }))

        yield put(hrdpActions.fetchSuccess())
        yield put(LocationSelectActions.fetchedFloors(floors, spaces))
    } catch (error) {
        console.warn('[SAGA] fetchFloor error', error)
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}

function* fetchSpaces(action: Action) {
    console.warn('[SAGA] fetchFloor')
    yield put(hrdpActions.fetchStart())
    const locationSelect = yield select((state) => state.locationSelect)

    const floor_id: string = action.payload.id
    const filterTenant = yield select((s) => s.locationSelect.filterTenant)
    const spaceTypes = yield select((s) => s.locationSelect.spaceTypes)

    const permissionType = yield select((s) => s.locationSelect.permissionType)


    try {
        let simpleSpaces: SimpleSpace[] = null
        if (filterTenant) {
            simpleSpaces = yield call(apiService.leasing.tenantManagement.getAvailableSpaceInFloor, floor_id)
        } else {
            simpleSpaces = yield call(
                campusInfo(permissionType).getSpacesInFloor,
                floor_id,
                filterTenant,
                spaceTypes,
            )
        }
        const spaces: HierarchySpace[] = simpleSpaces.map((s) => ({
            ...s,
            hierarchy: {
                campus: locationSelect.selectedCampus,
                building: locationSelect.selectedBuilding,
                floor: action.payload,
            },
        }))
        yield put(hrdpActions.fetchSuccess())
        yield put(LocationSelectActions.fetchedSpaces(spaces))
    } catch (error) {
        console.warn('[SAGA] fetchFloor error', error)
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}

// function* fetchViewCampus(action: Action) {
//     console.warn('[SAGA] fetchViewCampus')

//     try {
//         let campuses = yield select(s => s.locationSelect.campusOptions)

//         if (campuses.length === 0) {
//             campuses = yield call(apiService.building.campusInfo.getCampuses)
//         }

//         // const navigationCampus = yield select(s => s.locationSelect.navigationCampus)
//         yield put(LocationSelectActions.loadViewCampuses.success(campuses))

//         // if (!navigationCampus) {
//         yield put(LocationSelectActions.selectViewCampus(campuses[0]))
//         // }

//     } catch (error) {
//         console.warn('[SAGA] fetchViewCampus error', error)
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

export const locationSelectionSagas = [
    takeEvery(ACTION_KEY_LOCATION_SELECT.LOCATION_SELECTION_START, locationSelectStart),
    takeEvery(ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_START, locationExclusiveSelectStart),

    takeEvery(ACTION_KEY_LOCATION_SELECT.CAMPUS_SELECT, fetchBuilding),
    takeEvery(ACTION_KEY_LOCATION_SELECT.BUILDING_SELECT, fetchFloor),
    takeEvery(ACTION_KEY_LOCATION_SELECT.FLOOR_SELECT, fetchSpaces),

    // takeEvery(ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_LOAD.REQUEST, fetchViewCampus),
    // takeEvery(ACTION_KEY_LOCATION_SELECT.FLOOR_SELECT, fetchBuilding),
]
