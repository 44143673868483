import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse } from '../../restful-provider'
import { SimpleCampus, SimpleBuilding, SimpleFloor, SimpleSpace, SpaceType } from '../../../schema'
import { CampusService } from '../../../constants'

export type CreateBuildingResponse = { campus_id: string; building: SimpleBuilding }
export type CreateSpaceInCampusResponse = { campus_id: string; space: SimpleSpace }

export type CreateFloorResponse = { building_id: string; floor: SimpleFloor }
export type CreateSpaceInBuildingResponse = { building_id: string; space: SimpleSpace }

export type CreateSpaceInFloorResponse = { floor_id: string; space: SimpleSpace }
export type CreateSpaceInSpaceResponse = { space_id: string; space: SimpleSpace }

export type CreateFloorParam = {
  building_id: string
  display_name: string
  idx: number
}

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class CampusInfoRestful {
  client: AxiosInstance = null
  subRoute = `${CampusService}/building-service/campus-info`

  constructor(provider: APIRestfulProvider, subPath = 'building-service') {
    this.client = provider.client
    this.subRoute = `${CampusService}/${subPath}/campus-info`
  }

  /////////////////////////////// Campus

  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${this.subRoute}/get-campuses`, {
        params: { id },
      })
      .then(parseResult)

  listAllCampuses = () =>
    this.client.get<APIHTTPResponse<SimpleCampus>>(`${this.subRoute}/list-campuses`).then(parseResult)

  /////////////////////////////// Building

  getBuildings = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${this.subRoute}/get-buildings`, { params: { id } })
      .then(parseResult)

  getBuildingsInCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${this.subRoute}/get-buildings-in-campus`, {
        params: { id: campus_id },
      })
      .then(parseResult)

  /////////////////////////////// Floor

  getFloors = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${this.subRoute}/get-floors`, {
        params: { id },
      })
      .then(parseResult)

  getFloorsInBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${this.subRoute}/get-floors-in-building`, {
        params: { id: building_id },
      })
      .then(parseResult)

  /////////////////////////////// Get Spaces

  getSpaces = (id?: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id }

    if (types) {
      params = { ...params, types: types.join(',') }
    }
    // const params = {id,  withTenant === undefined && ...{ tenant: withTenant}, ...{} }
    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInCampus = (campus_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: campus_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-campus`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInBuilding = (building_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: building_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-building`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInFloor = (floor_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: floor_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-floor`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInSpace = (space_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { space_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-space`, {
        params,
      })
      .then(parseResult)
  }

  getSpaceHierarchy = (ids: string[], types?: SpaceType[]) => {
    let params: any = { space_id: ids }

    if (types) {
      params = { ...params, types }
    }
    return this.client
      .get<APIHTTPResponse<any>>(`${this.subRoute}/get-spaces-hierarchy`, {
        params,
      })
      .then(parseResult)
  }
}

export default CampusInfoRestful
