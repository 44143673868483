import {
  INIT_URL,
  USER_DATA,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes"
import { REGION_CODES } from '../../constants/Configs'
import localStorageService from "../../services/localStorageService"
import { TokenResult, AuthUser, UserProfile } from "../../schema"
import { ACTION_KEY_AUTH } from "../actions"
import { isReactNative } from "../../utils/runtime"


interface AuthStore {
  token: TokenResult,
  initURL: string,
  authUser: AuthUser,
  profile: UserProfile,
  loader: boolean,
  requestStatus: "requested" | "failed" | "none",
  regionCd: string,
  smsTimer: number,
  validCodeFailure: boolean,
  validCodeFailureTimer: number,
  failedError:Record<string,string>,
}

export const loadAsyncInitState = async () => {
  const token = await localStorageService.getItemAsync('token')
  const authUser = await localStorageService.getItemAsync('auth_user')

  return {
    token: token,
    initURL: '',
    authUser: authUser,
    loader: true,
    profile: null,
    requestStatus: "none",
    regionCd: REGION_CODES[0],
    smsTimer: 0,
    validCodeFailure: false,
    validCodeFailureTimer: 0,
    failedError:null
  }
}

const INIT_STATE: AuthStore = isReactNative ? {
  token: null,
  initURL: '',
  authUser: null,
  profile: null,
  loader: true,
  requestStatus: "none",
  regionCd: REGION_CODES[0],
  smsTimer: 0,
  validCodeFailure: false,
  validCodeFailureTimer: 0,
  failedError:null
} : {
  token: localStorageService.getItem('token'),
  initURL: '',
  authUser: localStorageService.getItem('auth_user'),
  loader: false,
  profile: null,
  requestStatus: "none",
  regionCd: localStorageService.getItem('regionCd') ?? REGION_CODES[0],
    smsTimer: 0,
    validCodeFailure: false,
    validCodeFailureTimer: 0,
    failedError:null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return { ...state, initURL: action.payload }
    }

    case ACTION_KEY_AUTH.SET_SMS_TIMER: {
      return { ...state, smsTimer: action.payload }
    }

    case ACTION_KEY_AUTH.REMOVE_CURRENT_AUTH_USER: {
      localStorageService.removeItemAsync('token')
      localStorageService.removeItemAsync('auth_user')
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      }
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      }
    }

    case ACTION_KEY_AUTH.SET_CURRENT_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.REQUEST: {
      return {
        ...state,
        loader: true,
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.SUCCESS: {
      return {
        ...state,
        loader: false,
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.FAILURE: {
      return {
        ...state,
        loader: false,
        validCodeFailure: true,
        validCodeFailureTimer:state.validCodeFailureTimer+1
      }
    }


    case ACTION_KEY_AUTH.GET_PROFILE.SUCCESS: {
      return {
        ...state,
        loader: false,
        profile: action.payload
      }
    }

    case ACTION_KEY_AUTH.GET_PROFILE.FAILURE: {
      return {
        ...state,
        loader: false,
        requestStatus: "failed",
      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.REQUEST: {
      localStorageService.setItem('regionCd', action.payload.regionCd)
      return {
        ...state,
        loader: true,
        smsTimer:0,
        regionCd: action.payload.regionCd
      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.SUCCESS: {
      return {
        ...state,
        loader: false,
        requestStatus: "requested",
        validCodeFailure: false,
        validCodeFailureTimer: 0,
        failedError:null
      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.FAILURE: {
      return {
        ...state,
        token: null,
        authUser: null,
        loader: false,
        requestStatus: "failed",
        failedError:action.payload,
        validCodeFailure: false,
        validCodeFailureTimer:0,
      }
    }


    case ACTION_KEY_AUTH.REFRESH_TOKEN.SUCCESS: {
      return {
        ...state,
        token: action.payload
      }
    }

    case ACTION_KEY_AUTH.REFRESH_TOKEN.FAILURE: {
      return {
        ...state,
        token: null,
        authUser: null
      }
    }

    default:
      return state
  }
}
