import React from 'react'
import { AuthRoles, ACCESS_POLICY } from '@exaleap/common'
import { Redirect } from 'react-router-dom'


const TenantVisitor = ACCESS_POLICY.tenantService.visitorService

const TenantPortalRoutes = [
    {
        path: '/app/tenant/overview',
        component: React.lazy(() => import('./TenantOverview')),
        auth: [
            ACCESS_POLICY.tenantService.generalService.tenantInfo.readOnly,
            ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess,
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    {
        path: '/app/tenant/edit',
        component: React.lazy(() => import('./TenantEdit')),
        auth: [
            ACCESS_POLICY.tenantService.generalService.tenantInfo.readOnly,
            ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess,
        ],
    },
    {
        path: '/app/tenant/visitor/new',
        component: React.lazy(() => import('./VisitorRequestDetailPage')),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    {
        path: '/app/tenant/visitor/:id',
        component: React.lazy(() => import('./VisitorRequestDetailPage')),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    {
        path: '/app/tenant/visitor',
        component: React.lazy(() => import('./VisitorRequestList')),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    // {
    //     path: '/app/tenant/staff/:subpage',
    //     component: React.lazy(() => import('./TenantStaff')),
    //     auth: [
    //         ACCESS_POLICY.tenantService.userManagement.readOnly,
    //         ACCESS_POLICY.tenantService.userManagement.fullAccess,
    //     ],
    // },
    {
        path: '/app/tenant/staff/',
        component: React.lazy(() => import('./TenantStaff')),
        auth: [
            ACCESS_POLICY.tenantService.userManagement.readOnly,
            ACCESS_POLICY.tenantService.userManagement.fullAccess,
        ],
    },
    {
        path: '/app/tenant',
        exact: true,
        component: () => <Redirect to="/app/tenant/overview" />,
    },
]

export default TenantPortalRoutes
