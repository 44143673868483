import * as R from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import {
  DisplayUser,
  ValueAddedCampusSummary,
  ValueAddedOrder,
  ValueAddedOrderComment,
  ValueAddedOrderHistory,
  ValueAddedOrderRequest,
  ValueAddedOrderStatus,
  ValueAddedType,
  SimpleDisplayObject,
} from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result[0]
const parseResults = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class General {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/value-added-service'

  appliedUsers = (bId: string, cId: string) =>
    this.client
      .get<APIHTTPResponse<DisplayUser>>(`${this.subRoute}/order/apply_users/${cId}/${bId}`)
      .then(parseResults)
      .catch(throwError)

  attachFile = (id: string, params: { file_ids: string[] }) =>
    this.client.put<APIHTTPResponse<any>>(`${this.subRoute}/order/${id}/file/attach`, params)
     .catch(throwError)

  comment = (id: string, params: {
    comment: string,
    current_status_id: ValueAddedOrderStatus,
    file_ids: string[],
    photo_ids: string[],
  }) =>
    this.client.post<APIHTTPResponse<any>>(`${this.subRoute}/order/${id}/comment`, params)
      .then(parseResult)
      .catch(throwError)

  deleteFile = (id: string) =>
    this.client.delete<APIHTTPResponse<any>>(`${this.subRoute}/file/${id}`)
      .then(parseResult)
      .catch(throwError)

  overview = () =>
    this.client
      .get<APIHTTPResponse<ValueAddedCampusSummary>>(`${this.subRoute}/order/summaries`)
      .then(parseResults)
      .catch(throwError)

  order = (id: string) =>
    this.client.get<APIHTTPResponse<ValueAddedOrder>>(`${this.subRoute}/order/${id}`)
      .then(parseResult)
      .catch(throwError)

  orders = (params: ValueAddedOrderRequest) => {
    const args = R.reject(R.isEmpty, R.pick([
      'apply_user_ids', 'building_id', 'page_number', 'page_size', 'search', 'status_ids', 'tenant_ids', 'type_ids',
    ], params))

    if (!!args.apply_user_ids && typeof params.apply_user_ids === 'string') {
      args.apply_user_ids = [args.apply_user_ids as string]
    }
    if (!!args.status_ids && typeof params.status_ids === 'string') {
      args.status_ids = [args.status_ids as string]
    }
    if (!!args.tenant_ids && typeof params.tenant_ids === 'string') {
      args.tenant_ids = [args.tenant_ids as string]
    }
    if (!!args.type_ids && typeof params.type_ids === 'string') {
      args.type_ids = [args.type_ids as string]
    }

    return this.client.post<PaginatedResponse<ValueAddedOrder>>(`${this.subRoute}/orders/pmc`, args)
      .then(d => d.data)
      .catch(throwError)

  }

  orderComments = (id: string) =>
    this.client.get<APIHTTPResponse<ValueAddedOrderComment>>(`${this.subRoute}/order/${id}/comments`)
      .then(parseResults)
      .catch(throwError)

  orderHistory = (id: string) =>
    this.client.get<APIHTTPResponse<ValueAddedOrderHistory>>(`${this.subRoute}/order/${id}/histories`)
      .then(parseResults)
      .catch(throwError)

  setOrderStatus = (id: string, status: ValueAddedOrderStatus) =>
    this.client.put<APIHTTPResponse<any>>(`${this.subRoute}/order/${id}/${status}`)
      .then(parseResult)
      .catch(throwError)

  status = () =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/status`)
      .then(parseResults)
      .catch(throwError)

  tenants = (bId: string, cId: string) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/order/tenants/${cId}/${bId}`)
      .then(parseResults)
      .catch(throwError)

  types = (id: string) =>
    this.client
      .get<APIHTTPResponse<ValueAddedType>>(`${this.subRoute}/${id}/types`)
      .then(parseResults)
      .catch(throwError)

  uploadFile = (file: FormData) =>
    this.client.post<APIHTTPResponse<{id: string, url: string}>>(`${this.subRoute}/file/upload`, file, {headers: {
      'Content-Type': 'multipart/form-data'
    }}).then(parseResult)
}

export default General