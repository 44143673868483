import { SimpleCampus, SimpleBuilding, SimpleFloor, SimpleSpace, HierarchySpace, SpaceType, SpaceFromHierarchy, LocationStringToIds, LocationStringFromSpace, LocationPermission, PermissionType } from '../../schema'
import { ACTION_KEY_LOCATION_SELECT, Action } from '../actions'

/**

"aia:aia.tower:1:spaceA"
"aia:aia.tower:2:*"
"aia:aia.tower:*"


const tree = {
    "aia": {
        "aia.tower": {
            '1': [
                'space A'
            ],
            '2': [
                '*'
            ],
            '*': 'all',
        }
    }
}

 * 
 */

// for both location selection dialog / app navigation
interface LocationSelectState {
    campusOptions: SimpleCampus[],

    buildingOptions: {
        buildings: SimpleBuilding[],
        spaces: HierarchySpace[],
    },
    floorOptions: {
        floors: SimpleFloor[],
        spaces: HierarchySpace[],
    },
    spaceOptions: HierarchySpace[],

    selectedCampus?: SimpleCampus,
    selectedBuilding?: SimpleBuilding,
    selectedFloor?: SimpleFloor,
    selectedSpaces?: HierarchySpace[],

    selectionMode: 'inclusive' | 'exlusive',
    permissionType: PermissionType,

    
    allowRules: LocationPermission[],
    excludeRules: LocationPermission[],

    excludeRuleTree?: any,

    selectedLocations?: string[],

    filterTenant?: boolean,
    spaceTypes: SpaceType[],

    navigationCampus: SimpleCampus,
}

const initialState: LocationSelectState = {

    selectionMode: 'inclusive',
    permissionType: 'TM',

    allowRules: [],
    excludeRules: [],

    campusOptions: [],
    buildingOptions: { buildings: [], spaces: [] },
    floorOptions: { floors: [], spaces: [] },
    spaceOptions: [],


    selectedBuilding: null,
    selectedCampus: null,
    selectedFloor: null,
    selectedSpaces: [],

    filterTenant: null,
    spaceTypes: null,

    navigationCampus: null,
}

const LocationSelectReducer = function (state = initialState, action: Action): LocationSelectState {
    switch (action.type) {
        case ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_START: {
            const { allows, excludes, permissionType } = action.payload
            return {
                ...state,
                buildingOptions: { buildings: [], spaces: [] },
                floorOptions: { floors: [], spaces: [] },
                spaceOptions: [],
                campusOptions: [],
                filterTenant: null,
                spaceTypes: null,
                selectedCampus: null,
                selectedBuilding: null,
                selectedFloor: null,
                selectedSpaces: [],
                selectionMode: 'exlusive',
                allowRules: allows || [],
                excludeRules: excludes || [],
                permissionType
            }
        }

        case ACTION_KEY_LOCATION_SELECT.LOCATION_SELECTION_START:
            const { filterTenant, spaceTypes, selectingSpaces } = action.payload
            return {
                ...state,
                buildingOptions: { buildings: [], spaces: [] },
                floorOptions: { floors: [], spaces: [] },
                spaceOptions: [],
                filterTenant: filterTenant || null,
                spaceTypes: spaceTypes || null,
                selectedCampus: null,
                selectedBuilding: null,
                selectedFloor: null,
                selectedSpaces: selectingSpaces || [],
            }


        
        case ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_READY:
            return {
                ...state,
                campusOptions: action.payload,
            }
        
        case ACTION_KEY_LOCATION_SELECT.LOCATION_SELECTION_READY:
            return {
                ...state,
                campusOptions: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.BUILDING_READY:
            return {
                ...state,
                buildingOptions: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.FLOOR_READY:
            return {
                ...state,
                floorOptions: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.SPACE_READY:
            return {
                ...state,
                spaceOptions: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.CAMPUS_SELECT:
            return {
                ...state,
                buildingOptions: { buildings: [], spaces: [] },
                floorOptions: { floors: [], spaces: [] },
                spaceOptions: [],
                selectedCampus: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.BUILDING_SELECT:
            return {
                ...state,
                floorOptions: { floors: [], spaces: [] },
                spaceOptions: [],
                selectedBuilding: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.FLOOR_SELECT:
            return {
                ...state,
                spaceOptions: [],
                selectedFloor: action.payload,
            }

        case ACTION_KEY_LOCATION_SELECT.SPACE_SELECT: {
            const found = state.selectedSpaces.find((space) => space.id === action.payload.id)

            if (found) {
                return {
                    ...state,
                    selectedSpaces: state.selectedSpaces.filter((space) => space.id !== action.payload.id),
                }
            }

            return {
                ...state,
                selectedSpaces: [...state.selectedSpaces, action.payload],
            }
        }

        case ACTION_KEY_LOCATION_SELECT.CAMPUS_CLEAR_EXCLUDES: {

            const campus: SimpleCampus = action.payload
            const excludeRules = state.excludeRules.filter(rule => rule.campus.id !== campus.id)
            return { ...state,  excludeRules }
        }

        case ACTION_KEY_LOCATION_SELECT.CAMPUS_OPT_IN: {

            const campus: SimpleCampus = action.payload
            const found = state.allowRules.find((rule) => rule.campus.id === campus.id && rule.all.building)


            if (found) {

                const allowRules = state.allowRules.filter((rule) => rule !== found)
                const excludeRules = state.excludeRules.filter((rule => rule.campus.id !== campus.id))

                return {
                    ...state,
                    allowRules,
                    excludeRules
                }

            } else {

                const permission: LocationPermission = {
                    type: 'LTUM',
                    campus,
                    building: null,
                    floor: null,
                    space: null,
                    all: {
                        campus: false,
                        building: true,
                        floor: true,
                        space: true
                    }
                }

                const allowRules = [...state.allowRules, permission]
                const excludeRules = state.excludeRules.filter(rule => rule.campus.id !== campus.id)

                return { ...state, allowRules, excludeRules }
            }


        }


        case ACTION_KEY_LOCATION_SELECT.BUILDING_OPT: {

            const campus: SimpleCampus = action.payload.campus
            const building: SimpleBuilding = action.payload.building

            const found = state.excludeRules.find((rule) => rule.building?.id === building?.id && rule.all.floor)

            let excludeRules = null

            if (found) {

                excludeRules = state.excludeRules.filter((rule) => rule !== found)

            } else {
                // new add permission, also need to loop the children nodes 

                const permission: LocationPermission = {
                    type: 'LTUM',
                    campus,
                    building,
                    floor: null,
                    space: null,
                    all: {
                        campus: false,
                        building: false,
                        floor: true,
                        space: true
                    }
                }

                // filter out children
                const withoutChildren = state.excludeRules.filter(rule => !(rule.building?.id === building.id && !rule.all.floor))

                excludeRules = [...withoutChildren, permission]
            }



            return {
                ...state,
                excludeRules
            }
        }



        case ACTION_KEY_LOCATION_SELECT.FLOOR_OPT: {

            const campus: SimpleCampus = action.payload.campus
            const building: SimpleBuilding = action.payload.building
            const floor: SimpleFloor = action.payload.floor

            // found exact match
            const found = state.excludeRules.find((rule) => rule.floor?.id === floor?.id && rule.all.space)

            let excludeRules = null


            if (found) {

                excludeRules = state.excludeRules.filter((rule) => rule !== found)

            } else {
                // new add permission, also need to loop the children nodes 

                const permission: LocationPermission = {
                    type: 'LTUM',
                    campus,
                    building,
                    floor,
                    space: null,
                    all: {
                        campus: false,
                        building: false,
                        floor: false,
                        space: true
                    }
                }

                // filter out children, delete all space specific rule 
                const withoutChildren = state.excludeRules.filter(rule => !(rule.floor?.id === floor.id && !rule.all.space && rule.space))


                // rearrange parents, if parent contains 
                // const 

                excludeRules = [...withoutChildren, permission]
            }



            return {
                ...state,
                excludeRules
            }

        }


        case ACTION_KEY_LOCATION_SELECT.SPACE_OPT: {


            const campus: SimpleCampus = action.payload.campus
            const building: SimpleBuilding = action.payload.building
            const floor: SimpleFloor = action.payload.floor
            const space: SimpleSpace = action.payload.space

            const found = state.excludeRules.find((rule) => rule.space?.id === space?.id)

            const permission: LocationPermission = {
                type: 'LTUM',
                campus,
                building,
                floor,
                space,
                all: {
                    campus: false,
                    building: false,
                    floor: false,
                    space: false
                }
            }

            const excludeRules = found ? state.excludeRules.filter((rule) => rule !== found) : [...state.excludeRules, permission]

            return {
                ...state,
                excludeRules
            }

        }



        case ACTION_KEY_LOCATION_SELECT.SPACES_SELECT: {

            return {
                ...state,
                selectedSpaces: action.payload,
            }
        }
        // case ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_LOAD.SUCCESS:
        //     return {
        //         ...state,
        //         campusOptions: action.payload,
        //         navigationCampus: null
        //     }

        // case ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_SELECT:
        //     return {
        //         ...state,
        //         navigationCampus: action.payload
        //     }

        default: {
            return state
        }
    }
}

export default LocationSelectReducer
