import { User, TokenResult, AuthUser, UserProfile } from '../../../schema'
import axios, { AxiosInstance } from 'axios'
import APIRestfulProvider, { APIHTTPResponse } from '../../restful-provider'

/**
 * Restful endpoints for Auth related
 */
class HkAuthRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/auth/account'

    apiVersion = () => this.client.get<APIHTTPResponse<{version: string}>>(`/api/info`).then((d) => d.data.result[0])

    getProfile = () =>
        this.client.get<APIHTTPResponse<UserProfile>>(`${this.subRoute}/profile`).then((d) => d.data.result)

    updateProfile = (object: any) =>
        this.client.put<APIHTTPResponse<{id: string}>>(`${this.subRoute}/profile`, object).then((d) => d.data.result)

    uploadProfile = (file: FormData) =>
        this.client.post<APIHTTPResponse<{id: string, profile_picture_url: string}>>(`${this.subRoute}/upload-profile-picture`, file, {headers: {
            'Content-Type': 'multipart/form-data'
        }}).then((d) => d.data.result)

    refreshToken = (refreshToken: string) =>
        this.client
            .post<APIHTTPResponse<TokenResult>>(`${this.subRoute}/refresh-token`, {
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            })
            .then((d) => d.data.result)
    authorize = async (username: string, password: string, organization_identifier: string) =>
        this.client
            .post<APIHTTPResponse<AuthUser>>(`${this.subRoute}/authorize`, {
                username,
                password,
                organization_identifier,
            })
            .then((d) => d.data.result)
            .catch((err) => {
                console.warn('authorize', err)
                throw err
            })

    requestSMS = async (area_code: string, phone_number: string, organization_identifier: string) =>
        this.client
            .post<APIHTTPResponse<AuthUser>>(`${this.subRoute}/authorize`, {
                area_code: area_code,
                phone_number: phone_number,
                organization_identifier,
            })
            .then((d) => d.data.result)
            .catch((err) => {
                console.warn('requestSMS', err)
                throw err
            })

    verifySMS = async (area_code: string, phone_number: string, verify_code: string) =>
        this.client
            .post<APIHTTPResponse<AuthUser>>(`${this.subRoute}/verify-code`, {
                area_code: area_code,
                phone_number: phone_number,
                verify_code: verify_code,
            })
            .then((d) => d.data.result)
            .catch((err) => {
                console.warn('verify_code', err)
                throw err
            })

    logout = () => async () => this.client.post(`${this.subRoute}/logout`).then((d) => d.data.result)
}

export default HkAuthRestful
