import React from "react"
import AppLayouts from "./AppLayouts"
import Routes from "../../app/routes"
import {useSelector} from "react-redux"
import AppAuthContent from "./AppAuthContent"

const AppLayout = ({route}) => {

  const horizontalNavPosition = useSelector(({settings}: any) => settings.horizontalNavPosition)
  const navigationStyle = useSelector(({settings}: any) => settings.navigationStyle)
  const onGetLayout = (layout) => {
    switch (layout) {
      case "inside_the_header":
        return "InsideHeaderNav"

      case "above_the_header":
        return "AboveHeaderNav"

      case "below_the_header":
        return "BelowHeaderNav"
      default:
        return "Vertical"
    }
  }

  const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)]
  console.log(Layout)

  return (
      <Layout>
        {/* <Routes/> */}
        <AppAuthContent route={route} />
      </Layout>
    )
}

export default AppLayout
