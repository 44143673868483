import { createRequestActionStrings } from './bms.helper'
import { RequestActionFunctions, action } from './index'
import { MerchantType } from '../../schema'

export const ACTION_KEY_HT = {
  MERCHANT_TYPE: createRequestActionStrings('MERCHANT_TYPE'),
}

// htMerchant-type
export const merchantType: RequestActionFunctions = {
  request: () => action(ACTION_KEY_HT.MERCHANT_TYPE.REQUEST),
  success: (merchantType: MerchantType[]) => action(ACTION_KEY_HT.MERCHANT_TYPE.SUCCESS, merchantType),
  failure: (error) => action(ACTION_KEY_HT.MERCHANT_TYPE.FAILURE, error),
}

export const HTActions = {
  merchantType,
}
