import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  throwError,
  parseResult,
  parseResults,
} from '../../restful-provider'
import { Package, TransactionRequired, TransactionRequest } from '../../../schema'

class MarketServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/market'

  getPackages = (id: string) =>
    this.client
      .get<APIHTTPResponse<Package>>(`${this.subRoute}/products`, {
        params: {
          facility_id: id,
        },
      })
      .then(parseResults)
      .catch(throwError)

  updateProductDisplay = (id: string, display: boolean) =>
    this.client
      .put<APIHTTPResponse<any>>(
        `${this.subRoute}/products`,
        { display },
        {
          params: { id },
        },
      )
      .then(parseResult)
      .catch(throwError)

  getTransactionList = (campusId: string, params: TransactionRequired) =>
    this.client
      .get<PaginatedResponse<TransactionRequest>>(`${this.subRoute}/facility/transactions`, {
        params: {
          campus_id: campusId,
          ...R.reject(
            R.either(R.isEmpty, R.isNil),
            R.pick(
              ['from_time', 'to_time', 'page_number', 'page_size', 'order_by', 'sort_by', 'order_no', 'state'],
              params,
            ),
          ),
        },
      })
      .then((d) => d.data)
      .catch(throwError)
}

export default MarketServiceEndpoints
