import React from 'react'
import { ACCESS_POLICY, USER_GROUPS } from '@exaleap/common'
import { Redirect } from 'react-router-dom'

const dashboardRoutes = [
    {
        path: '/app/dashboard/campus/:id',
        component: React.lazy(() => import('./routes/Campus/CampusAnalysis')),
        auth: [
            ACCESS_POLICY.platformManagement.campusManagement.readOnly,
            ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
            ACCESS_POLICY.propertiesManagementService.tenantManagement.readOnly,
        ],
    },
    {
        path: '/app/dashboard/campus',
        auth: [
            ACCESS_POLICY.platformManagement.campusManagement.readOnly,
            ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
            ACCESS_POLICY.propertiesManagementService.tenantManagement.readOnly,
        ],
        component: () => <Redirect to="/app/dashboard/campus/all" />,
    },
    {
        path: '/app/dashboard/overview',
        exact: true,
        component: React.lazy(() => import('./routes/overview')),
    },
    {
        path: '/app/dashboard',
        exact: true,
        component: () => <Redirect to="/app/dashboard/overview" />,
    },
]

export default dashboardRoutes
