import {
  all,
  // take,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'

import { Action, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'
import { ACTION_KEY_BMS, BMSActions } from '../actions/bms'
import { getValueWithLocale } from 'util/localeHelper'


function* campusListRequest(action: Action) {
  console.warn('[SAGA] campusListRequest')

  yield put(hrdpActions.fetchStart())
  try {
    const [campuses] = yield all([call(apiService.platform.campus.getCampuses)])
    yield put(BMSActions.campusList.success(campuses))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.warn('[SAGA] campusListRequest error', error)
    yield put(BMSActions.campusList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
}

function* buildingListRequest(action: Action) {
  console.warn('[SAGA] buildingListRequest')
  const campus_id = action.payload
  try {
    const [buildings] = yield all([call(apiService.platform.campus.getBuildings, campus_id)])
    yield put(BMSActions.buildingList.success(buildings))
  } catch (error) {
    console.warn('[SAGA] buildingListRequest error', error)
    yield put(BMSActions.buildingList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* floorListRequest(action: Action) {
  console.warn('[SAGA] floorListRequest')
  try {
    const [floors] = yield all([call(apiService.platform.campus.getFloors, action.payload)])
    yield put(BMSActions.floorList.success(floors))
  } catch (error) {
    console.warn('[SAGA] floorListRequest error', error)
    yield put(BMSActions.floorList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* spaceListRequest(action: Action) {
  console.warn('[SAGA] spaceListRequest')
  try {
    const [spaces] = yield all([call(apiService.platform.campus.getSpaces)])
    yield put(BMSActions.spaceList.success(spaces))
  } catch (error) {
    console.warn('[SAGA] spaceListRequest error', error)
    yield put(BMSActions.spaceList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* campusDetailRequest(action: Action) {
  console.warn('[SAGA] campusDetailRequest')
  const id = action.payload

  try {
    const [campuses, buildings, spaces] = yield all([
      call(apiService.platform.campus.getCampuses, id),
      call(apiService.platform.campus.getBuildingsInCampus, id),
      call(apiService.platform.campus.getSpacesInCampus, id),
    ])
    if (campuses.length === 0) {
      throw new Error('Campus not found')
    }
    yield put(
      BMSActions.campusDetail.success({
        campus: campuses[0],
        buildings,
        spaces,
      }),
    )
  } catch (error) {
    console.warn('[SAGA] campusDetailRequest error', error)
    yield put(BMSActions.campusDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* buildingDetailRequest(action: Action) {
  console.warn('[SAGA] buildingDetailRequest')
  const id = action.payload

  try {
    const [buildings, floors, spaces] = yield all([
      call(apiService.platform.campus.getBuildings, id),
      call(apiService.platform.campus.getFloorsInBuilding, id),
      call(apiService.platform.campus.getSpacesInBuilding, id),
    ])
    if (buildings.length === 0) {
      throw new Error('Building not found')
    }

    yield put(
      BMSActions.buildingDetail.success({
        building: buildings[0],
        floors,
        spaces,
      }),
    )
  } catch (error) {
    console.warn('[SAGA] buildingDetailRequest error', error)
    yield put(BMSActions.buildingDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* floorDetailRequest(action: Action) {
  console.warn('[SAGA] floorDetailRequest')
  const id = action.payload

  try {
    const [floors, spaces] = yield all([
      call(apiService.platform.campus.getFloors, id),
      call(apiService.platform.campus.getSpacesInFloor, id),
    ])
    if (floors.length === 0) {
      throw new Error('Floor not found')
    }
    yield put(
      BMSActions.floorDetail.success({
        floor: floors[0],
        spaces,
      }),
    )
  } catch (error) {
    console.warn('[SAGA] floorDetailRequest error', error)
    yield put(BMSActions.floorDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* spaceDetailRequest(action: Action) {
  console.warn('[SAGA] spaceDetailRequest')
  const id = action.payload

  try {
    const [space, spaces] = yield all([
      call(apiService.platform.campus.getSpaces, id),
      call(apiService.platform.campus.getSpacesInSpace, id),
    ])

    if (space.length === 0) {
      throw new Error('Space not found')
    }

    yield put(
      BMSActions.spaceDetail.success({
        space: space[0],
        spaces,
      }),
    )
  } catch (error) {
    console.warn('[SAGA] spaceDetailRequest error', error)
    yield put(BMSActions.spaceDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* campusDetailCreateBuilding(action: Action) {
  console.warn('[SAGA] campusDetailCreateBuilding')
  // const id = action.payload

  try {
    const [spaces] = yield all([call(apiService.platform.campus.createBuilding, action.payload)])
    if (spaces.length === 0) {
      throw new Error('Space not found')
    }
    const buildingObj = {
      campus_id: spaces[0].id,
      building_id: spaces[0].building.id,
      name: getValueWithLocale('display_name', spaces[0].building),
    }

    yield put(BMSActions.campusDetailCreateBuilding.success(buildingObj))
  } catch (error) {
    console.warn('[SAGA] campusDetailCreateBuilding error', error)
    yield put(BMSActions.campusDetailCreateBuilding.failure(error))
  }
}

function* campusDetailCreateSpace(action: Action) {
  console.warn('[SAGA] campusDetailCreateSpace')
  // const id = action.payload

  try {
    const [spaces] = yield all([call(apiService.platform.campus.createSpaceInCampus, action.payload)])
    if (spaces.length === 0) {
      throw new Error('Space not found')
    }
    yield put(BMSActions.campusDetailCreateSpace.success(spaces[0]))
  } catch (error) {
    console.warn('[SAGA] campusDetailCreateSpace error', error)
    yield put(BMSActions.campusDetailCreateSpace.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* buildingDetailCreateFloor(action: Action) {
  console.warn('[SAGA] buildingDetailCreateFloor')
  // const id = action.payload

  try {
    const [floors] = yield all([call(apiService.platform.campus.createFloor, action.payload)])
    if (floors.length === 0) {
      throw new Error('Space not found')
    }

    const floorObj = floors[0]
    // building_id

    yield put(BMSActions.buildingDetailCreateFloor.success(floorObj))
  } catch (error) {
    console.warn('[SAGA] buildingDetailCreateFloor error', error)
    yield put(BMSActions.buildingDetailCreateFloor.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* buildingDetailCreateSpace(action: Action) {
  console.warn('[SAGA] buildingDetailCreateSpace')
  // const id = action.payload

  try {
    const [spaces] = yield all([call(apiService.platform.campus.createSpaceInBuilding, action.payload)])
    if (spaces.length === 0) {
      throw new Error('Space not found')
    }

    yield put(BMSActions.buildingDetailCreateSpace.success(spaces[0]))
  } catch (error) {
    console.warn('[SAGA] buildingDetailCreateSpace error', error)
    yield put(BMSActions.buildingDetailCreateSpace.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* floorDetailCreateSpace(action: Action) {
  console.warn('[SAGA] floorDetailCreateSpace')
  // const id = action.payload

  try {
      const [spaces] = yield all([call(apiService.platform.campus.createSpaceInFloor, action.payload)])
    if (spaces.length === 0) {
      throw new Error('Space not found')
    }

      yield put(BMSActions.floorDetailCreateSpace.success(spaces[0]))
  } catch (error) {
    console.warn('[SAGA] floorDetailCreateSpace error', error)
    yield put(BMSActions.floorDetailCreateSpace.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* spaceDetailCreateSpace(action: Action) {
  console.warn('[SAGA] spaceDetailCreateSpace')
  // const id = action.payload

  try {
    const [spaces] = yield all([call(apiService.platform.campus.createSpaceInSpace, action.payload)])
    if (spaces.length === 0) {
      throw new Error('Space not found')
    }
    yield put(BMSActions.spaceDetailCreateSpace.success(spaces[0]))
  } catch (error) {
    console.warn('[SAGA] spaceDetailCreateSpace error', error)
    yield put(BMSActions.spaceDetailCreateSpace.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

export const bmsSagas = [
  takeEvery(ACTION_KEY_BMS.CAMPUS_LIST.REQUEST, campusListRequest),
  takeEvery(ACTION_KEY_BMS.BUILDING_LIST.REQUEST, buildingListRequest),
  takeEvery(ACTION_KEY_BMS.FLOOR_LIST.REQUEST, floorListRequest),
  takeEvery(ACTION_KEY_BMS.SPACE_LIST.REQUEST, spaceListRequest),
  takeEvery(ACTION_KEY_BMS.CAMPUS_DETAIL.REQUEST, campusDetailRequest),
  takeEvery(ACTION_KEY_BMS.BUILDING_DETAIL.REQUEST, buildingDetailRequest),
  takeEvery(ACTION_KEY_BMS.FLOOR_DETAIL.REQUEST, floorDetailRequest),
  takeEvery(ACTION_KEY_BMS.SPACE_DETAIL.REQUEST, spaceDetailRequest),
  takeEvery(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.REQUEST, campusDetailCreateBuilding),
  takeEvery(ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.REQUEST, campusDetailCreateSpace),
  takeEvery(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.REQUEST, buildingDetailCreateFloor),
  takeEvery(ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.REQUEST, buildingDetailCreateSpace),
  takeEvery(ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.REQUEST, floorDetailCreateSpace),
  takeEvery(ACTION_KEY_BMS.SPACE_DETAIL_CREATE_SPACE.REQUEST, spaceDetailCreateSpace),
]
