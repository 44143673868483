import { AxiosInstance } from 'axios'
import { LocationPermission } from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'


/**
 * Restful endpoints for AccessControl module
 */
class AccessControlService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/building-service​/access-control-service​'

    accessQRCode = (building_id: string) =>
        this.client
            .post<APIHTTPResponse<{id: string, code: string}>>(`${this.subRoute}/access-qrcode`, { building_id  })
            .then((d) => d.data.result)
            .catch(throwError)

    getAccessPermissions = () =>
        this.client
            .get<APIHTTPResponse<{profile_picture_url: string, permissions: LocationPermission[]}>>(`${this.subRoute}/get-access-permissions`)
            .then((d) => d.data.result)
            .catch(throwError)
}

export default AccessControlService
