import {
    AuthUser, UserProfile,
    // Device,
    // DeviceType,
    // DataModelRecordPair,
    // CreatingDevice,
} from '../../schema'
import { SnackbarAction } from 'notistack'

import { createRequestActionStrings } from './bms.helper'

export * from './LayoutActions'

// <- Previous actions
// New redux actions ->

export const UPDATE_ROUTER_STATE = 'UPDATE_ROUTER_STATE'

// Action Function

export interface RequestActionFunctions {
    request: Function,
    success: Function,
    failure: Function,
}

export interface Action {
    type: string,
    payload: any,
}

export function action(type, payload = {}, extra = {}): Action {
    return { type, payload, ...extra }
}

// Auth Related
export const ACTION_KEY_AUTH = {
    REQUEST_SMS: createRequestActionStrings('AUTH_REQUEST_SMS'),
    VERIFY_SMS: createRequestActionStrings('AUTH_VERIFY_SMS'),
    REFRESH_TOKEN: createRequestActionStrings('AUTH_REFRESH_TOKEN'),
    GET_PROFILE: createRequestActionStrings('GET_PROFILE'),
    SET_SMS_TIMER: 'SET_SMS_TIMER',
    ACCESS_TOKEN_EXPIRED: 'AUTH_ACCESS_TOKEN_EXPIRED',
    SET_CURRENT_AUTH_USER: 'AUTH_SET_USER',
    REMOVE_CURRENT_AUTH_USER: 'AUTH_REMOVE_USER',
}

const requestToken: RequestActionFunctions = {
    request: ({ areaCode, phone, organization_identifier, regionCd }) =>
        action(ACTION_KEY_AUTH.REQUEST_SMS.REQUEST, {
            phone_number: phone,
            organization_identifier,
            area_code: areaCode,
            regionCd,
        }),
    success: (response) => action(ACTION_KEY_AUTH.REQUEST_SMS.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_AUTH.REQUEST_SMS.FAILURE, error),
}

const verifyToken: RequestActionFunctions = {
    request: ({ areaCode, phone, sms }) =>
        action(ACTION_KEY_AUTH.VERIFY_SMS.REQUEST, {
            areaCode,
            phone,
            sms,
        }),
    success: (response) => action(ACTION_KEY_AUTH.VERIFY_SMS.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_AUTH.VERIFY_SMS.FAILURE, error),
}

const refreshToken: RequestActionFunctions = {
    request: (refreshToken) => action(ACTION_KEY_AUTH.REFRESH_TOKEN.REQUEST, { refreshToken }),
    success: (response) => action(ACTION_KEY_AUTH.REFRESH_TOKEN.SUCCESS, response),
    failure: (error) => action(ACTION_KEY_AUTH.REFRESH_TOKEN.FAILURE, error),
}

const requestProfile: RequestActionFunctions = {
    request: () => action(ACTION_KEY_AUTH.GET_PROFILE.REQUEST),
    success: (profile: UserProfile) => action(ACTION_KEY_AUTH.GET_PROFILE.SUCCESS, profile),
    failure: (error) => action(ACTION_KEY_AUTH.GET_PROFILE.FAILURE, error),
}

export const AuthActions = {
    requestToken,
    verifyToken,
    refreshToken,
    requestProfile,
    setCurrentAuthUser: (user: AuthUser) => action(ACTION_KEY_AUTH.SET_CURRENT_AUTH_USER, user),
    logoutAuthUser: () => action(ACTION_KEY_AUTH.REMOVE_CURRENT_AUTH_USER),
    setTokenTimeer: (timer: number) => action(ACTION_KEY_AUTH.SET_SMS_TIMER, timer),
}

// User Related
export const ACTION_KEY_USER_MGMT = {
    USER_LIST: createRequestActionStrings('USER_LIST'),
    USER_DETAIL: createRequestActionStrings('USER_DETAIL'),
}

const userList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_USER_MGMT.USER_LIST.REQUEST, {}),
    success: (users) => action(ACTION_KEY_USER_MGMT.USER_LIST.SUCCESS, users),
    failure: (error) => action(ACTION_KEY_USER_MGMT.USER_LIST.FAILURE, error),
}

export const UserMgmtActions = {
    userList,
}

export const ACTION_KEY_NOTIFICATION = {
    ENQUEUE: 'ACTION_KEY_NOTIFICATION.ENQUEUE',
    CLOSE: 'ACTION_KEY_NOTIFICATION.CLOSE',
    REMOVE: 'ACTION_KEY_NOTIFICATION.REMOVE',
}

export const NotificationActionFunctions = {
    enqueue: (notification: { message: string | JSX.Element | any , type: 'success' | 'error', action?: SnackbarAction }) =>
        action(ACTION_KEY_NOTIFICATION.ENQUEUE, { notification }),
    close: (key?: string | number) => action(ACTION_KEY_NOTIFICATION.CLOSE, { dismissAll: !key, key }),
    remove: (key?: string | number) => action(ACTION_KEY_NOTIFICATION.REMOVE, { key }),
}

export * from './iot'
export * from './bms'
export * from './bms.helper'
export * from './overview'
export * from './tenant'
export * from './access-control'
export * from './visitor'
export * from './dashboard'
export * from './location-select'
export * from './property-management'
export * from './work-order'
export * from './ht'

export const ACTION_KEY_NAVIGATE = 'NAVIGATE'
export const navigate = (pathname) => action(ACTION_KEY_NAVIGATE, { pathname })
