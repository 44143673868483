import * as R from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { HistoryRequired, SimpleDisplayObject, Register, Statistics, Visitor } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class PropertiesManagementAccess {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/visitor-service/properties-management-access'

  getTenantsByFloor = (buildingId: string, floorIdx: number) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/building/${buildingId}/floor/${floorIdx}`)
      .then(parseResult)
      .catch(throwError)

  history = (id: string, params: HistoryRequired) =>
    this.client
      .get<PaginatedResponse<Visitor>>(`${this.subRoute}/building/${id}/visitors`, {
        params: R.reject(
          R.either(R.isEmpty, R.isNil),
          R.pick([
            'end_time',
            'filter_by_time',
            'floor_index',
            'order_by',
            'page_number',
            'page_size',
            'search',
            'sort_by',
            'start_time',
            'tenant_id',
          ], params),
        ),
      })
      .then(d => d.data)
      .catch(throwError)

  overview = () =>
    this.client
      .get<APIHTTPResponse<Statistics>>(`${this.subRoute}/overview`)
      .then(parseResult)
      .catch(throwError)

  register = (params: Register) =>
    this.client
    .post<APIHTTPResponse<{id: string, visitor_id: string}>>(`${this.subRoute}/invitation`, params)
    .then(parseResult)
    .catch(throwError)

  remove = ({ invitation_id, id }: Visitor) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${this.subRoute}/invitation/${invitation_id}/visitor/${id}`)
      .then(parseResult)
      .catch(throwError)
}

export default PropertiesManagementAccess