import { useReducer } from 'react'
import { SimpleCampus } from '@exaleap/common'
import systemInfoReducer, { State } from '../reducers/systemInfo'
import systemInfoActions from '../actions/systemInfoActions'

const initialState: State = {
  campuses: [],
}

export type SystemInfoState = {
  campuses: SimpleCampus[]
  setCampuses: (buildings: SimpleCampus[]) => void
}

const useSystemInfo = (): SystemInfoState => {
  const [systemInfo, systemInfoDispatcher] = useReducer(systemInfoReducer, initialState)
  const { setCampuses } = systemInfoActions(systemInfoDispatcher)

  return {
    ...systemInfo,
    setCampuses,
  }
}

export default useSystemInfo
