import axios, { AxiosError, AxiosResponse } from 'axios'
import { AuthActions } from '../redux/actions'
import { isReactNative } from '../utils/runtime'
import jwtAuthService from './jwtAuthService'

export interface APIHTTPResponse<T> {
  status_code: 'SUCCESS' | string,
  msg: 'SUCCESS' | string,
  result: T[],
}

export interface APIHTTPResponse2<T> {
  status_code: 'SUCCESS' | string,
  msg: 'SUCCESS' | string,
  result: T,
}

export interface PaginatedResponse<T> extends APIHTTPResponse<T> {
  current_page: number,
  page_size: number,
  total_pages: number,
  total_records: number,
}

export const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result[0]
export const parseResults = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

export const throwError = (e: AxiosError<APIHTTPResponse<null>>) => {
  if (!e.response || !e.response.data) {
    throw e
  }

  throw {
    name: e.response.data.status_code,
    message: e.response.data.msg,
  }
}

// const REACT_APP_API_URL = window['_env_'].API_ENDPOINT || isReactNative && '/api/v1/'
// const REACT_APP_API_URL = process.env.REACT_APP_API_URL || (isReactNative && '/api/v1/')
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || (isReactNative && '/api/v1/')

// Http transportation
export class APIRestfulProvider {
  client = axios.create({ baseURL: REACT_APP_API_URL })
  store = null
  constructor() {
    console.log('REACT_APP_API_URL', REACT_APP_API_URL)
    this.client.interceptors.response.use(
      (response) => {
        // console.log(response)

        return response
      },
      (err) => {
        console.warn('interceptors error:', err)
        // alert(err)

        const result = err.response?.data?.result?.join(', ') || ''
        const message = err.response ? err.response?.data?.msg + ' ' + result || err.response?.statusText : err?.message
        const code = err.response?.data?.status_code || ''
        const status = err.response?.status

        if (code === 'ERR10002' || status === 401) {
          console.warn('interceptors 401')
          jwtAuthService.logout()
          this.removeAuth()
          if (this.store) {
            this.store.dispatch(AuthActions.logoutAuthUser())
          }
        }

        return Promise.reject({ code, status, message })
      },
    )
  }

  applyAuth = (object: any) => {
    const { token } = object
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + token

    console.warn('[APIRestfulService] applyAuth', this.client.defaults.headers.common['Authorization'])
  }

  setStore = (store: any) => {
    this.store = store
  }

  removeAuth = () => {
    delete this.client.defaults.headers.common['Authorization']
  }
}

export default APIRestfulProvider
