import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  PaginatedResponse,
  parseResults,
  parseResult,
} from '../../restful-provider'
import { PrivacyPolicyList, PrivacyPolicyNew } from '@exaleap/common'

/**
 * Restful endpoints for Visitor System module
 */
class HTAPPFunctionsServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/app-config-service'

  // 隐私权政策列表
  getPrivacyPolicyByPage = (params) => {
    const filter = R.reject(R.isEmpty, R.pick(['end_time', 'start_time', 'query', 'tx_type', 'tx_change_type'], params))
    const args = R.reject(R.isEmpty, R.pick(['order_by', 'sort_by'], params))
    return this.client
      .post<PaginatedResponse<PrivacyPolicyList>>(`${this.subRoute}/privacy/policy/page`, { ...args, filter })
      .then((d) => d.data)
      .catch(throwError)
  }

  getPrivacyPolicyById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy?id=${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  createPrivacyPolicy = (params) => {
    return this.client
      .post<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy`, params)
      .then(parseResult)
      .catch(throwError)
  }

  uploadPrivacyPolicy = (params) => {
    return this.client
      .put<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy`, params)
      .then(parseResult)
      .catch(throwError)
  }

  uploadPdf = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ id: string, url: string }>>(`${this.subRoute}/privacy/policy/pdf/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)

  publishPrivacyPolicy = (id:string) => {
    return this.client
      .put<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/publish`,{id})
      .then(parseResult)
      .catch(throwError)
  }

  disablePrivacyPolicy = (id:string) => {
    return this.client
      .put<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/disable`,{id})
      .then(parseResult)
      .catch(throwError)
  }
}

export default HTAPPFunctionsServices
