import thunk from "redux-thunk"
import { createStore, applyMiddleware, compose } from "redux"
// import createLogger from 'redux-logger'
import AppRootReducer from "./reducers/AppRootReducer"


import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import history from '../utils/webHistory'
import { routerMiddleware } from 'connected-react-router'
import root from "./sagas"

const initialState = {}


const middlewares = [thunk, routerMiddleware(history)]
let devtools = x => x

if (
  process.env.NODE_ENV !== "production" &&
  typeof window !== 'undefined' &&
  composeWithDevTools
) {
  devtools = composeWithDevTools()
}

// const Store = null
// const Store = createStore(
//   RootReducer(history),
//   initialState,
//   devtools ? compose(applyMiddleware(...middlewares), devtools) : applyMiddleware(...middlewares)
// )



export { root as RootSaga, AppRootReducer}


