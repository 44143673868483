/* eslint-disable react/jsx-no-target-blank */
import { AuthActions, hrdpActions } from '@exaleap/common'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import IntlMessages from 'util/IntlMessages'
import * as R from 'ramda'
import clsx from 'clsx'
import OtpInput from 'react-otp-input'
import useCountDown from 'react-countdown-hook'

const useStyles = makeStyles((theme: Theme) => ({
  otpInputContainerStyle: {
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  otpInput: {
    width: '2.5rem !important',
    height: '4rem',
    fontSize: '2rem',
    borderRadius: 4,
    borderColor: 'rgba(0,0,0,0.3)',
    outline: 'none',
    '&:focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}))

const TIME = 90000

const LoginForm = props => {
  const { setStep, phone, areaCode, resendOtp } = props
  const { loader, regionCd, validCodeFailure, validCodeFailureTimer } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [counter, { start, pause }] = useCountDown(TIME, 1000)
  React.useEffect(() => {
    start()
    return () => pause()
  }, [])

  const handleResendOtp = () => {
    resendOtp()
    start(TIME)
  }
  const regionVersion = useRef(regionCd)
  regionVersion.current = useMemo(() => (R.includes('cn/signin', window?.location?.pathname ?? '') ? 'CN' : 'HK'), [])
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)

  const [sms, setSms] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (sms?.length === 6) {
      dispatch(hrdpActions.showAuthLoader())
      dispatch(AuthActions.verifyToken.request({ areaCode, phone, sms }))
    }
  }, [sms])

  useEffect(() => {
    if (validCodeFailureTimer === 3) {
      setOpen(true)
    }
  }, [validCodeFailureTimer])

  return (
    <>
      <div className="app-login-main-content animated slideInUpTiny animation-duration-3">
        <IconButton
          style={{ fontSize: 15, margin: '12px', borderRadius: '5px', position: 'absolute' }}
          onClick={() => setStep('request-otp')}
        >
          <ArrowBackIcon style={{ margin: 12 }} />
          <IntlMessages id="appModule.back" />
        </IconButton>

        <div className="app-login-content">
          <p style={{ width: 288, padding: '20px 0' }}>
            <IntlMessages id="appModule.login.notice" values={{ areaCode, phone }} />
          </p>
          <div className="app-login-form">
            <form>
              <OtpInput
                isInputNum
                containerStyle={clsx(classes.otpInputContainerStyle, 'w-full')}
                inputStyle={classes.otpInput}
                value={sms}
                onChange={code => setSms(code)}
                numInputs={6}
                shouldAutoFocus={true}
              />

              {sms?.length === 6 && loader && (
                <p style={{ paddingTop: '12px', textAlign: 'center' }}>
                  <IntlMessages id="appModule.login.check" />
                </p>
              )}
              {validCodeFailure && (
                <p style={{ paddingTop: '12px', textAlign: 'center', color: '#f00' }}>
                  <IntlMessages id="api.error.ERR10023" />
                </p>
              )}

              <Button
                onClick={() => handleResendOtp()}
                disabled={counter > 0}
                color="primary"
                variant="text"
                className="h-40 p-0 align-self-center"
                style={{
                  width: 288,
                  marginTop: 20,
                  fontSize: 15,
                }}
              >
                <IntlMessages id="login.resend_sms" />
                {counter > 0 ? ` (${counter / 1000})` : null}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: 'dialogPaper',
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id="login.sms.error" />
        </DialogTitle>
        <DialogContent style={{ height: 120, width: 380, color: '#ccc' }}>
          <DialogContentText id="alert-dialog-description">
            <IntlMessages id="login.get_new_code" />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #ccc' }}>
          <Button
            onClick={() => {
              setOpen(false)
              setStep('request-otp')
            }}
            color="primary"
          >
            <IntlMessages id="generic.action.got_it" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LoginForm
