import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class LeasingTenantVisitorRestful {
    client: AxiosInstance = null
    subRoute = '/leasing-management/tenant-management/visitor-management'

    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }


    // `${this.subRoute}/get-tenant-space-visitor-approval-groups`
    // `${this.subRoute}/set-tenant-user-group-as-tenant-space-visitor-approval-group`
    // `${this.subRoute}/unset-tenant-user-group-from-tenant-space-visitor-approval-group`


}

export default LeasingTenantVisitorRestful
