import React from "react"
import { AuthRoles, USER_GROUPS, ACCESS_POLICY } from "@exaleap/common"

import { Redirect } from "react-router-dom"


const campusPolicies = [
  ACCESS_POLICY.platformManagement.campusManagement.fullAccess, 
  ACCESS_POLICY.platformManagement.campusManagement.readOnly
]

const BMSRoutes = [
  {
    path: "/app/bms/campuses/:id",
    component: React.lazy(() => import("./CampusDetail")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/buildings/:id",
    component: React.lazy(() => import("./BuildingDetail")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/floors/:id",
    component: React.lazy(() => import("./FloorDetail")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/spaces/:id",
    component: React.lazy(() => import("./SpaceDetail")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/campuses",
    component: React.lazy(() => import("./CampusList")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/buildings",
    component: React.lazy(() => import("./BuildingList")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/floors",
    component: React.lazy(() => import("./FloorList")),
    auth: campusPolicies
  },
  {
    path: "/app/bms/spaces",
    component: React.lazy(() => import("./SpaceList")),
    auth: campusPolicies
  },
 
  {
    path: "/app/bms",
    component: () =>  <Redirect to="/app/bms/campuses" />,
    auth: campusPolicies
  },
]

export default BMSRoutes
