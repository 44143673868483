import { ACCESS_POLICY } from '@exaleap/common'
import React from 'react'

const HTMemberAndPointRoutes = [
  {
    path: '/app/leasing-mgmt/ht/member_and_point/qr-code',
    component: React.lazy(() => import('./ScanPoint')),
    auth: [ACCESS_POLICY.leasingManagement.scanPoint.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/member',
    component: React.lazy(() => import('./MemberDetail')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.membership],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/point-transaction',
    component: React.lazy(() => import('./PointTransaction')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.pointTransaction],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/invitation',
    component: React.lazy(() => import('./Invitation')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.invitation],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/settings/invitation-rule',
    component: React.lazy(() => import('./InvitationRules')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.invitationRule],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/spending-transaction',
    component: React.lazy(() => import('./SpendingTransaction')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.membership],
  },
  {
    path: '/app/leasing-mgmt/ht/member_and_point/settings/spending-transaction-deny-reason',
    component: React.lazy(() => import('./SpendingTransactionDenyReason')),
    auth: [ACCESS_POLICY.leasingManagement.memberService.membership],
  },
]

export default HTMemberAndPointRoutes
