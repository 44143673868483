import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { BaseModel, SimpleDisplayObject } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class TenantGeneralService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = "/permission-control/tenant-service"

    getSpaceTypes = () => this.client.get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/get-space-types`).then(parseResult).catch(throwError)

    getPermissionTypes = () => this.client.get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}//get-permission-types`).then(parseResult).catch(throwError)

    getUserCategories = () => this.client.get<APIHTTPResponse<BaseModel>>(`${this.subRoute}/get-user-categories`).then(parseResult).catch(throwError)

}

export default TenantGeneralService
