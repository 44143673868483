import { SimpleBuilding, Tenant, VisitorRequest, SimpleCampus } from '../../schema'
import { ACTION_KEY_VISITOR, Action } from '../actions'

interface VisitorState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,
    filterBuildings: SimpleBuilding[],
    filterTenants: Tenant[],
    requests: VisitorRequest[],
    requestDetail: VisitorRequest,
}


const initialState: VisitorState = {
    success: false,
    loading: false,
    error: null,
    filterBuildings: [],
    filterTenants: [],
    requests: [],
    requestDetail: null
}

const VisitorReducer = function(state = initialState, action: Action): VisitorState {

    if (!action.payload) return state
    
    switch (action.type) {
        case ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST.SUCCESS: {
            const {buildings, tenants, requests} = action.payload
            return {
                ...state,
                success: true,
                loading: false,
                filterBuildings: buildings,
                filterTenants: tenants,
                requests: requests
            }
        }

        case ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                requestDetail: action.payload
            }
        }

        case ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_APPROVE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                requests: state.requests.map(r => {
                    if (r.id === action.payload.id) {
                        return action.payload
                    } else {
                        return r 
                    }
                })
            }
        }

        case ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_REJECT.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                requests: state.requests.map(r => {
                    if (r.id === action.payload.id) {
                        return action.payload
                    } else {
                        return r 
                    }
                })
            }
        }

        case ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                requestDetail: action.payload
            }
        }

        case ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT_VISITOR.SUCCESS: {
            const visitor = action.payload
            return {
                ...state,
                success: true,
                loading: false,
                requestDetail: {...state.requestDetail, 
                    visitors: state.requestDetail.visitors.map(v => v.id === visitor.visitor_id ? visitor : v)
                }
            }
        }

        default: {
            return state
        }
    }
}

export default VisitorReducer
