import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { PropertyMgmtCompany } from '../../../schema'



const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for PropertyGeneralService module
 */
class PropertyGeneralService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }


    subRoute = "/properties-management-service/general-service"

    info = () => this.client.get<APIHTTPResponse<PropertyMgmtCompany>>(`${this.subRoute}/company-info`).then(parseResult).catch(throwError)

}

export default PropertyGeneralService
