import React from "react"
import { USER_GROUPS, ACCESS_POLICY } from "@exaleap/common"

const IAMRoutes = [
  {
    path: "/app/iam/users",
    component: React.lazy(() => import("./UserList")),
    auth:  [ACCESS_POLICY.platformManagement.iam.userManagement.fullAccess, ACCESS_POLICY.platformManagement.iam.fullAccess],
  }
]

export default IAMRoutes
