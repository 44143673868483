import APIRestfulProvider from "../../restful-provider"
import AccessControlService from "./access-control"
import CampusInfoRestful from "./campus-info"

/**
 * Building Services
 */


class BuildingServiceEndpoints {

    accessControlService: AccessControlService
    campusInfo: CampusInfoRestful

    constructor(provider: APIRestfulProvider) {
        this.accessControlService = new AccessControlService(provider)
        this.campusInfo = new CampusInfoRestful(provider)
    }

}


export default BuildingServiceEndpoints