import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { ListRequest, LocationPermission, User, UserGroup } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class PropertyUserManagement {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }


    subRoute = '/properties-management-service/user-management'

    user = (id: string) =>
        this.client
            .get<APIHTTPResponse<User>>(`${this.subRoute}/get-users`, {
                params: { user_id: id },
            })
            .then(parseResult)
            .catch(throwError)

    users = (params?: ListRequest) =>
        this.client.get<PaginatedResponse<User>>(`${this.subRoute}/get-users`, {
            params
        }).then(d => d.data).catch(throwError)

    addUser = (username: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/add-user`, {
                username,
            })
            .then(parseResult)
            .catch(throwError)

    dropUser = (user_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/drop-user`, {
                params: { user_id },
            })
            .then(parseResult)
            .catch(throwError)


    createUserGroup = (display_name: string, description: string) =>
        this.client
            .post<APIHTTPResponse<UserGroup>>(`${this.subRoute}/create-user-group`, {
                display_name,
                description
            })
            .then(parseResult)
            .catch(throwError)

    getUserGroups = (id?: string) =>
        this.client
            .get<APIHTTPResponse<UserGroup>>(`${this.subRoute}/get-user-groups`, { params: id ? { id } : null })
            .then(parseResult)
            .catch(throwError)

    getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
        this.client
            .get<PaginatedResponse<User>>(`${this.subRoute}/get-users-in-user-group`, { params: { user_group_id, ...params } })
            .then(d => d.data)
            .catch(throwError)

    getUsersNotInUserGroup = (user_group_id: string, params?: ListRequest) =>
        this.client
            .get<PaginatedResponse<User>>(`${this.subRoute}/get-users-not-in-user-group`, { params: { user_group_id, ...params } })
            .then(d => d.data)
            .catch(throwError)

    addUserToUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<User>>(`${this.subRoute}/add-user-to-user-group`, { user_id, user_group_id })
            .then(parseResult)
            .catch(throwError)

    dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<User>>(`${this.subRoute}/drop-user-from-user-group`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    getUsersInSupportedUserGroup = (user_group_id: string) =>
        this.client
            .get<APIHTTPResponse<User>>(`${this.subRoute}/get-users-in-supported-user-group`, {
                params: { user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    addUserToSupportedUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<User>>(`${this.subRoute}/add-user-to-supported-user-group`, {
                user_id,
                user_group_id,
            })
            .then(parseResult)
            .catch(throwError)

    dropUserFromSupportedUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<User>>(`${this.subRoute}/drop-user-from-supported-user-group`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    setUserAsGroupAdmin = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/set-user-as-user-group-admin`, { user_id, user_group_id })
            .then(parseResult)
            .catch(throwError)

    unsetUserFromGroupAdmin = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/unset-user-from-user-group-admin`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)



    setAccessPermission = (user_id: string, allow: string[], exclude: string[] = []) =>
        this.client
            .post<APIHTTPResponse<{
                allow: LocationPermission[],
                exclude: LocationPermission[],
                user_id: string
            }>>(`${this.subRoute}/set-user-access-permission`, {
                user_id,
                allow,
                exclude
            })
            .then(parseResult)
            .catch(throwError)
}

export default PropertyUserManagement
