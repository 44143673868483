import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import {
  SimpleCampus,
  SimpleBuilding,
  SimpleFloor,
  SimpleSpace,
  CreatingBuilding,
  SimpleDisplayObject,
  SpaceType,
  LocalizationObject,
} from '../../../schema'
import { CampusService } from '../../../constants'

export type CreateBuildingResponse = { campus_id: string; building: SimpleBuilding }
export type CreateSpaceInCampusResponse = { campus_id: string; space: SimpleSpace }

export type CreateFloorResponse = { building_id: string; floor: SimpleFloor }
export type CreateSpaceInBuildingResponse = { building_id: string; space: SimpleSpace }

export type CreateSpaceInFloorResponse = { floor_id: string; space: SimpleSpace }
export type CreateSpaceInSpaceResponse = { space_id: string; space: SimpleSpace }

export type CreateFloorParam = {
  building_id: string
  display_name: string
  idx: number
}

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class CampusRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = `${CampusService}/platform-management/campus-management`

  /////////////////////////////// Campus

  createCampus = (name: string) =>
    this.client.post<APIHTTPResponse<SimpleCampus>>(`${this.subRoute}/create-campus`, { name }).then(parseResult)

  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${this.subRoute}/get-campuses`, {
        params: { id },
      })
      .then(parseResult)

  /////////////////////////////// Building

  createBuilding = (param: CreatingBuilding) =>
    this.client
      .post<APIHTTPResponse<CreateBuildingResponse>>(`${this.subRoute}/create-building-in-campus`, param)
      .then(parseResult)

  getBuildings = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${this.subRoute}/get-buildings`, { params: { id } })
      .then(parseResult)

  getBuildingsInCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${this.subRoute}/get-buildings-in-campus`, {
        params: { id: campus_id },
      })
      .then(parseResult)

  /////////////////////////////// Floor

  createFloor = (param: CreateFloorParam) =>
    this.client
      .post<APIHTTPResponse<CreateFloorResponse>>(`${this.subRoute}/create-floor-in-building`, param)
      .then(parseResult)

  getFloors = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${this.subRoute}/get-floor-by-id`, {
        params: { id },
      })
      .then(parseResult)

  getFloorsInBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${this.subRoute}/get-floors-in-building`, {
        params: { id: building_id },
      })
      .then(parseResult)

  /////////////////////////////// Get Spaces

  getSpaces = (id?: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = {}

    if (id) {
      params = { id }
    }

    if (types) {
      params = { ...params, types: types.join(',') }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-space-by-id`, {
        params: { id },
      })
      .then(parseResult)
  }

  getSpacesInCampus = (campus_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: campus_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-campus`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInBuilding = (building_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: building_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-building`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInFloor = (floor_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: floor_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-floor`, {
        params,
      })
      .then(parseResult)
  }

  getSpacesInSpace = (space_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: space_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/get-spaces-in-space`, {
        params,
      })
      .then(parseResult)
  }

  getSpaceHierarchy = (ids: string[], types?: SpaceType[]) => {
    let params: any = { space_ids: ids }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<any>>(`${this.subRoute}/get-spaces-hierarchy`, {
        params,
      })
      .then(parseResult)
  }

  getSpaceTypes = (filterType?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(
        `${this.subRoute}/get-space-types${filterType ? `?filterFor=${filterType}` : ''}`,
      )
      .then(parseResult)
      .catch(throwError)

  /////////////////////////////// Create Space

  createSpaceInCampus = (creating: { campus_id: string; space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInCampusResponse>>(`${this.subRoute}/create-space-in-campus`, creating)
      .then(parseResult)

  createSpaceInBuilding = (creating: { building_id: string; space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInBuildingResponse>>(`${this.subRoute}/create-space-in-building`, creating)
      .then(parseResult)

  createSpaceInFloor = (creating: { floor_id: string; space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInFloorResponse>>(`${this.subRoute}/create-space-in-floor`, creating)
      .then(parseResult)

  createSpaceInSpace = (creating: { space_id: string; space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInSpaceResponse>>(`${this.subRoute}/create-space-in-space`, creating)
      .then(parseResult)

  /* remove  */
  removeFloorById = (id: string) =>
    this.client
      .delete<APIHTTPResponse<SimpleFloor>>(`${this.subRoute}/remove-floor-by-id`, { params: { id } })
      .then(parseResult)

  removeSpaceById = (id: string) =>
    this.client
      .delete<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/remove-space-by-id`, { params: { id } })
      .then(parseResult)

  /* edit */
  editFloorById = (
    id: string,
    data: LocalizationObject & {
      idx?: string
    },
  ) =>
    this.client.put<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/edit-floor-by-id?id=${id}`, data).then(parseResult)

  editSpaceById = (
    id: string,
    data: LocalizationObject & {
      type_id?: string
    },
  ) =>
    this.client.put<APIHTTPResponse<SimpleSpace>>(`${this.subRoute}/edit-space-by-id?id=${id}`, data).then(parseResult)
}

export default CampusRestful
