import React from 'react'
import { AuthRoles, ACCESS_POLICY } from '@exaleap/common'
import { Redirect } from 'react-router-dom'

const tenantManagement = [ACCESS_POLICY.propertiesManagementService.tenantManagement.readOnly]

// TODO: ADMIN PREVIEW
const workOrderManagement = [
  // ACCESS_POLICY.platformManagement.campusManagement.fullAccess
  ACCESS_POLICY.propertiesManagementService.workOrderManagement.readOnly,
]

const userManagement = [
  ACCESS_POLICY.propertiesManagementService.userManagement.readOnly,
  ACCESS_POLICY.propertiesManagementService.userManagement.fullAccess,
]

const visitorPolicies = [
  ACCESS_POLICY.propertiesManagementService.visitorService.readOnly,
  ACCESS_POLICY.propertiesManagementService.visitorService.fullAccess,
]

const PropertyPortalRoutes = [
  {
    path: '/app/property-mgmt/tenants/:id',
    component: React.lazy(() => import('./TenantDetail')),
    auth: tenantManagement,
  },
  {
    path: '/app/property-mgmt/tenants',
    component: React.lazy(() => import('./TenantList')),
    auth: tenantManagement,
  },
  {
    path: '/app/property-mgmt/staff',
    component: React.lazy(() => import('./PropertyStaff')),
    auth: userManagement,
  },
  {
    path: '/app/property-mgmt/value-added/order/:id',
    component: React.lazy(() => import('./ValueAddedOrderDetail')),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/value-added/orders',
    component: React.lazy(() => import('./ValueAddedRecords')),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/value-added',
    component: React.lazy(() => import('./ValueAddedOverview')),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/visitor/:id',
    component: React.lazy(() => import('./VisitorRequestDetailPage')),
    auth: visitorPolicies,
  },
  {
    path: '/app/property-mgmt/visitor',
    component: React.lazy(() => import('./VisitorRequestList')),
    auth: visitorPolicies,
  },
  {
    path: '/app/property-mgmt/visitors/records',
    component: React.lazy(() => import('./VisitorRecords')),
    auth: [ACCESS_POLICY.visitorService.propertiesManagementAccess.fullAccess],
    skipDomains: ['hendersonsmartplatform.com'],
  },
  {
    path: '/app/property-mgmt/visitors',
    component: React.lazy(() => import('./VisitorRecordsOverview')),
    auth: [ACCESS_POLICY.visitorService.propertiesManagementAccess.fullAccess],
    skipDomains: ['hendersonsmartplatform.com'],
  },
  {
    path: '/app/property-mgmt/work-orders/list',
    component: React.lazy(() => import('./WorkOrderListing')),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/work-orders/:id',
    component: React.lazy(() => import('./WorkOrderDetailTabContainer')),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/work-orders',
    component: React.lazy(() => import('./WorkOrderOverview')),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/facility',
    exact: true,
    component: React.lazy(() => import('./FacilityDetailPage')),
    auth: [ACCESS_POLICY.propertiesManagementService.facilityService.fullAccess],
  },
  {
    path: '/app/property-mgmt/overview',
    component: React.lazy(() => import('./Overview')),
    auth: [ACCESS_POLICY.propertiesManagementService.generalService.basic],
  },
  {
    path: '/app/property-mgmt/transaction-records',
    component: React.lazy(() => import('./TransactionRecords')),
    auth: [ACCESS_POLICY.propertiesManagementService.facilityService.fullAccess],
  },
  {
    path: '/app/property-mgmt/shuttle-bus',
    component: React.lazy(() => import('./ShuttleBus')),
    auth: [ACCESS_POLICY.propertiesManagementService.shuttleBusService.fullAccess],
  },
  {
    path: '/app/property-mgmt/gamification',
    component: React.lazy(() => import('./Game/Management')),
    auth: [ACCESS_POLICY.propertiesManagementService.gameManagement.fullAccess],
  },
  {
    path: '/app/property-mgmt/content-mgmt/merchant',
    component: React.lazy(() => import('../leasing-portal/MerchantsV2')),
    auth: [ACCESS_POLICY.propertiesManagementCompany.merchant.merchantManagement],
  },
  {
    path: '/app/property-mgmt',
    exact: true,
    component: React.lazy(() => import('./DefaultPage')),
  },
]

export default PropertyPortalRoutes
