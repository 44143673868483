import { ACTION_KEY_AUTH, AuthActions, Action, ACTION_KEY_USER_MGMT, UserMgmtActions } from '../actions'
import { apiService } from '../../..'
import { iotSagas } from './iot'
import { bmsSagas } from './bms'
import { tenantSagas } from './tenant'
import { overviewSagas } from './overview'
import { authSagas } from './auth'
import { staffSagas } from './staff'
import { dashboardSagas } from './dashboard'
import { accessControlSagas } from './access-control'
import { visitorSagas } from './visitor'
import { locationSelectionSagas } from './location-select'
import { propertyManagementSagas } from './property-tenant'
import {
    all,
    // take,
    call,
    put,
    takeEvery,
} from 'redux-saga/effects'
import { workOrderSagas } from './work-order'
import { htSagas } from './ht'

// resuable fetch Subroutine
// entity :  user | repo | starred | stargazers
// apiFn  : api.fetchUser | api.fetchRepo | ...
// id     : login | fullName
// url    : next page url. If not provided will use pass id to apiFn
// function* fetchEntity(entity, apiFn, id, url) {
// 	yield put(entity.request(id))
// 	const { response, error } = yield call(apiFn, url || id)
// 	if (response) yield put(entity.success(id, response))
// 	else yield put(entity.failure(id, error))
// }

function* userDetailRequest(action: Action) {
    console.error('[SAGA] userDetailRequest NOT implemented')
}

// Do all the general api error handling
// function* generalAPIError() {
// 	yield put(push("/session/login"))
// }

console.warn('authSagas:', authSagas)

export default function* root() {
    yield all([
        takeEvery(ACTION_KEY_USER_MGMT.USER_DETAIL.REQUEST, userDetailRequest),
        ...authSagas,
        ...iotSagas,
        ...bmsSagas,
        ...tenantSagas,
        ...overviewSagas,
        ...workOrderSagas,
        ...staffSagas,
        ...dashboardSagas,
        ...accessControlSagas,
        ...visitorSagas,
        ...locationSelectionSagas,
        ...propertyManagementSagas,
        ...htSagas
    ])
}
