import { MerchantType } from '../../schema'
import { ACTION_KEY_HT, Action } from '../actions'

interface htState {
  merchantType: MerchantType[],
}

const initialState: htState = {
  merchantType: [],
}

const HTReducer = function (state = initialState, action: Action): htState {
  if (!action.payload) return state

  switch (action.type) {
    case ACTION_KEY_HT.MERCHANT_TYPE.SUCCESS: {
      const  merchantType  = action.payload
      console.log('merchantType 9999', merchantType,action)
      return {
        ...state,
        merchantType,
      }
    }
    default: {
      return state
    }
  }
}

export default HTReducer
