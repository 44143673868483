import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { Tenant, TenantContact } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result


export interface EditingTenant {
    profile_picture_id?: string,
    contacts: {
        corporate?: TenantContact[],
        emergency?: TenantContact[],
    },
}

/**
 * Restful endpoints for Visitor System module
 */
class TenantGeneralService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = "/tenant-service/general-service"

    info = () => this.client.get<APIHTTPResponse<Tenant>>(`${this.subRoute}/tenant-info`).then(parseResult).catch(throwError)
    
    editInfo = (editingTenant: EditingTenant) => this.client.put<APIHTTPResponse<Tenant>>(`${this.subRoute}/edit-tenant-info`, editingTenant).then(parseResult).catch(throwError)

}

export default TenantGeneralService
