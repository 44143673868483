import React, { useState, Fragment, useMemo } from 'react'
import * as R from 'ramda'
import { useSelector } from 'state/root-store'
import { makeStyles } from '@material-ui/core/styles'
import IntlMessages from 'util/IntlMessages'
import { getValueWithLocale } from 'util/localeHelper'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  Collapse,
  List,
  ListItem,
  Icon,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  IconButton,
  Menu,
  SvgIcon,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
import { ACCESS_POLICY, apiService, AuthChecking, AuthUser, PropertyMgmtCompany, Tenant } from '@exaleap/common'
import { matchPath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { formatTenantCode } from 'utils'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { intl } from 'util/IntlHelper'

const useStyles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    paddingLeft: '20px',
    paddingTop: '2px',
    paddingBottom: '3px',
    color: '#a1a1a1',
    fontSize: '22px',
    backgroundColor: theme.palette.background.paper,
    '&:hover,&:hover .material-icons, &.active, &.active .material-icons': {
      color: theme.palette.primary.main,
    },
    '& >svg': {
      fontSize: '20px',
    },
  },
  nested: {
    paddingLeft: theme.spacing(8.5),
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  selectMenu: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root:hover, &.MuiInput-underline:hover:not(.Mui-disabled):before, &.MuiInput-underline:before, &.MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
}))

const MenuItemList = ({ items }) => {
  const classes = useStyles()
  const [openKeys, setOpenKeys] = React.useState([])
  const handleClickMenu = key => {
    setOpenKeys(currKeys => {
      const isOpened = currKeys.indexOf(key) > -1
      return isOpened ? currKeys.find(i => i !== key) || [] : [...currKeys, key]
    })
  }
  const isParentItemHighlight = item => {
    const windowPathname = window.location.pathname
    const link = item.link
    return windowPathname.indexOf(link) > -1 ? 'active' : ''
  }

  return (
    <List component="div" className="nav-menu-item">
      {items?.map((c, i) => {
        const itemChildren = c?.children || []
        if (itemChildren.length > 0) {
          return (
            <Fragment key={c.name}>
              <ListItem
                button
                onClick={() => handleClickMenu(c.name)}
                className={`${classes.listItem} ${isParentItemHighlight(c)}`}
              >
                {!!c?.icon && (
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <Icon
                      style={{
                        fontSize: 22,
                        marginRight: 12,
                      }}
                    >
                      {c.icon}
                    </Icon>
                  </ListItemIcon>
                )}
                {!!c?.pathD && (
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <SvgIcon style={{ fontSize: 22, marginRight: 12 }}>
                      <path d={c.pathD} />
                    </SvgIcon>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <span
                      className="nav-text"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      <IntlMessages id={c?.name} />
                    </span>
                  }
                />
                {openKeys.indexOf(c.name) > -1 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openKeys.indexOf(c.name) > -1}
                timeout="auto"
                unmountOnExit
                className={`${classes.nested} siderBar`}
              >
                <MenuItemList items={itemChildren} />
              </Collapse>
            </Fragment>
          )
        }
        return (
          <NavLink key={i} className="prepend-icon nav-menu-link" to={c?.link || ''}>
            {/* Display an icon if any */}
            {!!c?.icon && (
              <Icon
                style={{
                  fontSize: 22,
                  marginRight: 12,
                }}
              >
                {c.icon}
              </Icon>
            )}
            {!!c?.pathD && (
              <SvgIcon style={{ fontSize: 22, marginRight: 12 }}>
                <path d={c.pathD} />
              </SvgIcon>
            )}
            <span className="nav-text">
              <IntlMessages id={c?.name} />
            </span>
          </NavLink>
        )
      })}
    </List>
  )
}

type CompanyInformation = {
  image: string,
  title: string | JSX.Element,
  desc: string,
}

const FunctionSelection = ({
  handleMouseLeave,
  onToggleCollapsedNav,
  navCollapsed,
  width,
}: {
  handleMouseLeave: () => void,
  onToggleCollapsedNav: () => void,
  navCollapsed: boolean,
  width: number,
}) => {
  const classes = useStyles()
  // const sections = createSelector([(s: any) => s.navigation], s => s)
  const sections: any[] = useSelector(s => s.navigation) as any[]
  const user: AuthUser = useSelector(s => s.auth.authUser)

  // const [sections,_] = useState([])
  const leasingTitle = useMemo(
    () =>
      R.includes('hendersonsmartplatform', window?.location?.hostname) ? (
        '恒基（中国）租务部'
      ) : (
        <IntlMessages id="leasing.title" />
      ),
    [],
  )

  const history = useHistory()

  const [index, setIndex] = useState<number>(-1)

  const [tenant, setTenant] = useState<CompanyInformation>(null)
  const [leasing] = useState<CompanyInformation>({
    title: leasingTitle,
    desc: '',
    image: require('assets/images/leasing-logo-hld.jpg'),
  })
  const [apiv, setAPIV] = useState<string>(null)
  const [property, setProperty] = useState<CompanyInformation>(null)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const routePath = useSelector(s => s.router.location.pathname)

  React.useEffect(() => {
    // const path = routePath
    let pointIndex = -1
    sections.forEach((section, ind) => {
      const result = section.children.reduce((current, child) => {
        // console.log('routePath', routePath, child.link)
        const res = current || !!matchPath(routePath, child.link)
        if (res) {
          // console.log('setIndex matchPath', routePath, child.link, sections, ind)
        }
        return res
      }, false)
      // console.log('routePath', routePath, 'result', result, ind)
      if (result) {
        pointIndex = ind
        setIndex(ind)
        // console.log('setIndex @ React.useEffect routePath', index)
      } else {
        if (!!matchPath(routePath, section.link)) {
          pointIndex = ind
          setIndex(ind)
        }
      }
    })

    if (pointIndex === -1) {
      // console.log('setIndex @ React.useEffect cannot find path', routePath)
      if (index === -1) {
        setIndex(0)
      }
    }
  }, [routePath, sections])

  React.useEffect(() => {
    // console.log('FunctionSelection:: LocationSelectActions.loadViewCampuses.request')

    apiService.auth.apiVersion().then(apiver => {
      setAPIV(apiver?.version)
    })
    // dispatch(LocationSelectActions.loadViewCampuses.request())
  }, [])

  // fetch leasing / property management company / tenant company information
  React.useEffect(() => {
    // console.log('sections useEffect', sections)
    sections.map(section => {
      if (section.name === 'sidebar.property_management' && !property) {
        apiService.property.general.info().then(cs => {
          if (cs?.length > 0) {
            const p: PropertyMgmtCompany = cs[0]
            setProperty({
              image: p.profile_picture_url,
              title: getValueWithLocale('display_name', section),
              desc: p.description,
            })
          }
        })
      }

      if (section.name === 'sidebar.leasing_management') {
        // apiService.leasing.
      }
      if (section.name === 'sidebar.tenant_services' && !tenant) {
        apiService.tenant.general.info().then(tenants => {
          if (tenants?.length > 0) {
            const t: Tenant = tenants?.[0]

            if (t) {
              setTenant({
                image: t.profile_picture_url,
                title: getValueWithLocale('display_name', t),
                desc: `租户编号：${formatTenantCode(t.tenant_code)}`,
              })
            }
          }
        })
      }
    })

    // console.log('setIndex sections only', sections)
  }, [sections])

  const companyInfo = i => {
    const section = sections[i]?.name
    if (section === 'sidebar.property_management') {
      return property
    }

    if (section === 'sidebar.leasing_management') {
      return leasing
    }
    if (section === 'sidebar.tenant_services') {
      return tenant
    }
  }

  const placeholderPath = i => {
    const section = sections[i]?.name
    if (section === 'sidebar.property_management') {
      return require('assets/images/placeholder-pmc-light.jpg')
    }

    if (section === 'sidebar.leasing_management') {
      return require('assets/images/leasing-logo-hld.jpg')
    }
    if (section === 'sidebar.tenant_services') {
      return require('assets/images/placeholder-tenant-light.jpg')
    }

    return require('assets/images/logo-exaleap.jpg')
  }
  const landCd = intl?.locale
  return (
    <div
      onMouseLeave={handleMouseLeave}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {sections.length > 1 && (
        <Select
          value={index}
          style={{
            width: landCd === 'en-US' ? 212 : 120,
            paddingLeft: 20,
            height: 48,
            borderBottom: '2px solid #27AE60',
          }}
          disabled={sections.length === 1}
          className={classes.selectMenu}
          onChange={e => {
            const selectIndex = e.target.value as number
            setIndex(selectIndex)
            history.push(sections[selectIndex].link)
            // dispatch(LocationSelectActions.selectViewCampus(campuses.find(c => c.id ===e.target.value)))
          }}
        >
          {sections?.map((s, i) => (
            <MenuItem key={s.name} value={i}>
              <IntlMessages id={s.name} />
            </MenuItem>
          ))}
        </Select>
      )}

      {sections.length === 1 && (
        <ListItem style={{ fontSize: 16, color: '#000000', borderBottom: '1px solid #dddddd' }}>
          <Icon
            color="primary"
            style={{
              fontSize: 16,
              marginRight: 12,
            }}
          >
            {sections[0].icon}
          </Icon>
          <IntlMessages id={sections[0].name} />
        </ListItem>
      )}
      {sections[index]?.name === 'sidebar.tenant_services' && companyInfo(index)?.image && (
        <img
          src={companyInfo(index)?.image || placeholderPath(index)}
          style={{ height: 260, width: '100%', objectFit: 'cover' }}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {companyInfo(index)?.title && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', padding: 15 }}>
            <span style={{ fontSize: 14, fontWeight: 700, color: '#333', textIndent: '6px' }}>
              {companyInfo(index)?.title}
            </span>
            <span style={{ fontSize: 10 }}>{companyInfo(index)?.desc}</span>
          </div>
        )}

        {sections[index]?.name === 'sidebar.tenant_services' &&
        companyInfo(index)?.title &&
        AuthChecking(user, [ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess]) ? (
          <IconButton
            onClick={event => {
              setAnchorEl(event.currentTarget)
              // history.push('/app/tenant/edit')
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ) : null}

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              history.push('/app/tenant/edit')
            }}
          >
            <IntlMessages id="tenant.overview.edit" />
          </MenuItem>
        </Menu>
        <div
          style={{
            zIndex: 1500,
            position: 'absolute',
            top: 0,
            right: 0,
            height: '48px',
            width: landCd === 'en-US' ? '48px' : '140px',
            borderBottom: '2px solid #27AE60',
          }}
        >
          {width > 1200 && (
            <IconButton onClick={() => onToggleCollapsedNav()} style={{ float: 'right', padding: '12px' }}>
              {!navCollapsed ? (
                <SvgIcon style={{ fontSize: 24 }}>
                  <path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z" />
                </SvgIcon>
              ) : (
                <SvgIcon style={{ fontSize: 24 }}>
                  <path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z" />
                </SvgIcon>
              )}
            </IconButton>
          )}
        </div>
      </div>

      <MenuItemList items={sections[index]?.children} />

      <p style={{ position: 'absolute', bottom: 20, left: 10, fontSize: 10, color: 'white' }}>API ver. {apiv}</p>
      <p style={{ position: 'absolute', bottom: 0, left: 10, fontSize: 10 }}>v1.1.15</p>
    </div>
  )
}

const SideBarContent = props => {
  return <FunctionSelection {...props} />
}

export default SideBarContent
