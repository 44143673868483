import React from 'react'
import { ACCESS_POLICY } from '@exaleap/common'
import { Redirect } from 'react-router-dom'

const PlatformPortalRoutes = [
  {
    path: '/app/platform-mgmt/access-records',
    component: React.lazy(() => import('./AccessRecordList')),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
  },
  {
    path: '/app/platform-mgmt/access-permission',
    component: React.lazy(() => import('./AccessPermission')),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
  },
  {
    path: '/app/platform-mgmt/data',
    component: React.lazy(() => import('./DataSearching')),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
  },
  {
    path: '/app/platform-mgmt/user-group-management',
    component: React.lazy(() => import('./UserGroupManagement')),
    auth: [ACCESS_POLICY.platformManagement.customUserGroupManagement.fullAccess],
  },
  {
    path: '/app/platform-mgmt',
    exact: true,
    component: () => <Redirect to="/app/bms" />,
  },
]

export default PlatformPortalRoutes
