import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'


const QRPage = () => <div>
    <h2>进入大楼二维码</h2> 

    <p>梁志丰 已协助您申请一次性通行码，你可透过以下二维码进入 北京环球金融中心，15层</p>

     </div>

export default QRPage