import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse2,
  throwError,
  PaginatedResponse,
} from '../../restful-provider'
import { Invitation, PointTransaction, HTMemberDetail,InvitationRules } from '@exaleap/common'

/**
 * Restful endpoints for Visitor System module
 */
class HTMemberServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/member-service/api/v1'

  // getMembersByPage = (params?: HTMemberRequired) => {
  //   const args = R.reject(R.isEmpty, R.pick([
  //      'page_number', 'page_size','filter','sort'
  //   ], params))
  //   return this.client
  //     .post<PaginatedResponse<HTMember>>(`${this.subRoute}/membership/cms/page`, {...args,filter:{}})
  //     .then(d=>d.data)
  //     .catch(throwError)
  // }

  // 会员详情
  getMembersDetail = (phoneNumber: number) => {
    return this.client
      .get<APIHTTPResponse2<HTMemberDetail>>(`${this.subRoute}/membership/cms/detail?phone_number=${phoneNumber}`)
      .then((d) => d.data.result)
      .catch(throwError)
  }

  // 积分流水 列表
  getPointTransactionByPage = (params) => {
    const filter = R.reject(R.isEmpty, R.pick(['end_time', 'start_time', 'query', 'tx_type', 'tx_change_type'], params))
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by'], params))
    return this.client
      .post<PaginatedResponse<PointTransaction>>(`${this.subRoute}/point/transaction/cms/page`, {...args,filter})
      .then((d) => d.data)
      .catch(throwError)
  }

  // 好友推荐 列表 /member-service/api/v1/invitation/cms/page
  getInvitationByPage = (params) => {
    const filter = R.reject(R.isEmpty, R.pick(['end_time', 'start_time', 'query', 'time_field'], params))
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by'], params))
    return this.client
      .post<PaginatedResponse<Invitation>>(`${this.subRoute}/invitation/cms/page`, {...args,filter})
      .then((d) => d.data)
      .catch(throwError)
  }

    // 好友推荐 列表 
    getInvitationRulesByPage = (params) => {
      const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by'], params))
      return this.client
        .post<PaginatedResponse<InvitationRules>>(`${this.subRoute}/invitation/cms/rule/page`, args)
        .then((d) => d.data)
        .catch(throwError)
    }
}

export default HTMemberServices
