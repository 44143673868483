import React from 'react'
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import App from './containers/App'
import {createBrowserHistory} from 'history'
import routes from './containers/RootRoutes'

import FirebaseAnalytics from 'util/FirebaseComponent'
import { HRDPStore } from 'state/root-store'


const history = createBrowserHistory()
const AppContext = React.createContext({})

const MainApp = () => 
<AppContext.Provider value={{routes}}>
  <Provider store={HRDPStore}>
    <ConnectedRouter history={history}>
      {/* <Switch>
        <Route path="/" component={App}/>
      </Switch> */}
      <FirebaseAnalytics />
      <App/>
    </ConnectedRouter>
  </Provider>
</AppContext.Provider>

export {MainApp, AppContext, history}