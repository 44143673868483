import { createIntl, createIntlCache } from 'react-intl'
import { format } from 'date-fns'
import { zhCN, zhTW, enUS } from 'date-fns/locale'

let cache
let intl

/**
 * Generate IntlShape object
 * @param {Object} props
 * @param {String} props.locale - User specified language
 * @param {Object} props.messages - Messages
 * @returns {Object}
 */
const generateIntl = props => {
  if (cache) {
    cache = null
  }

  cache = createIntlCache()

  intl = createIntl(props, cache)
  return intl
}

const getLocale = () => {
  let locale
  switch (intl?.locale) {
    case 'en-US':
      locale = enUS
      break
    case 'zh-TW':
      locale = zhTW
      break
    case 'zh-CN':
    default:
      locale = zhCN
  }
  return locale
}

const formatDate = (value: number | string, dateFormat = `yyyy/MM/dd ah:mm`) => {
  return format(new Date(value), dateFormat, { locale: getLocale() })
}

export { formatDate, generateIntl, getLocale, intl }
