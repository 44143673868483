import { action } from './index'
import { Tenant, SimpleBuilding, VisitorRequest, VisitorEntity } from '../../schema'
import { createRequestActionStrings } from './bms.helper'
import { SERVICE_DESTINATION } from '../../services/apiService'

// Access Control Related
export const ACTION_KEY_VISITOR = {
    VISITOR_REQUEST_LIST: createRequestActionStrings('VISITOR_REQUEST_LIST'),
    VISITOR_REQUEST_DETAIL: createRequestActionStrings('VISITOR_REQUEST_DETAIL'),
    VISITOR_REQUEST_CREATE: createRequestActionStrings('VISITOR_REQUEST_CREATE'),
    VISITOR_REQUEST_LIST_APPROVE: createRequestActionStrings('VISITOR_REQUEST_LIST_APPROVE'),
    VISITOR_REQUEST_LIST_REJECT: createRequestActionStrings('VISITOR_REQUEST_LIST_REJECT'),
    VISITOR_REQUEST_DETAIL_REJECT: createRequestActionStrings('VISITOR_REQUEST_DETAIL_REJECT'),
    VISITOR_REQUEST_DETAIL_REJECT_VISITOR: createRequestActionStrings('VISITOR_REQUEST_DETAIL_REJECT_VISITOR'),
}

// property / tenant /

const visitorRequestList = {
    request: (destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST.REQUEST, { destination }),
    success: (buildings: SimpleBuilding[], tenants: Tenant[], requests: VisitorRequest[]) =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST.SUCCESS, { buildings, tenants, requests }),
    failure: (error) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST.FAILURE, error),
}

const visitorRequestDetail = {
    request: (id: string, destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL.REQUEST, { id, destination }),
    success: (request: VisitorRequest) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL.SUCCESS, request),
    failure: (error) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL.FAILURE, error),
}

const visitorRequestCreate = {
    request: (req: VisitorRequest, destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_CREATE.REQUEST, { req, destination }),
    success: (result: VisitorRequest) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_CREATE.SUCCESS, result),
    failure: (error) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_CREATE.FAILURE, error),
}

const listingRequestApprove = {
    request: (req: VisitorRequest, destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_APPROVE.REQUEST, { req, destination }),
    success: (result: VisitorRequest) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_APPROVE.SUCCESS, result),
    failure: (error) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_APPROVE.FAILURE, error),
}

const listingRequestReject = {
    request: (req: VisitorRequest, destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_REJECT.REQUEST, { req, destination }),
    success: (result: VisitorRequest) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_REJECT.SUCCESS, result),
    failure: (error) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_REJECT.FAILURE, error),
}

const detailRequestRejectSingleVisitor = {
    request: (visitor: VisitorEntity, reason: string, destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT_VISITOR.REQUEST, { visitor, reason, destination }),
    success: (visitor: VisitorEntity) =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT_VISITOR.SUCCESS, visitor),
}

const detailRequestReject = {
    request: (destination: SERVICE_DESTINATION = 'tenant-service') =>
        action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT.REQUEST, { destination }),
    success: (result: VisitorRequest) => action(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT.SUCCESS, result),
}

export const VisitorActions = {
    visitorRequestList,
    visitorRequestDetail,
    visitorRequestCreate,
    listingRequestApprove,
    listingRequestReject,
    detailRequestReject,
    detailRequestRejectSingleVisitor,
}
