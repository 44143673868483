import { AxiosInstance, AxiosResponse } from 'axios'
import { Banner, UpdateBannersRequest } from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class AppConfigServiceRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/app-config-service'

  banners = () =>
    this.client
      .get<APIHTTPResponse<Banner>>(`${this.subRoute}/properties-management-access/banners`)
      .then(parseResult)
      .catch(throwError)

  updateBanners = (params: UpdateBannersRequest[]) =>
    this.client
      .post<APIHTTPResponse<Banner>>(`${this.subRoute}/properties-management-access/banners`, params)
      .then(parseResult)
      .catch(throwError)

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/upload/single`, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(parseResult)
      .catch(throwError)
}

export default AppConfigServiceRestful
