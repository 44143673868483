import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { SpecialAccessRecord, Tenant } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for AccessControl module
 */

class SpecialAccessManagement {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/properties-management-service/special-access-management'

    verifyUser = (tenant_id: string, area_code: string, phone_number: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/verifiy-tenant-user`, {
                params: { tenant_id, area_code, phone_number },
            })
            .then(parseResult)
            .catch(throwError)

    list = (tenant_id: string) =>
        this.client
            .get<APIHTTPResponse<SpecialAccessRecord>>(`${this.subRoute}/get-special-accesses`, {
                params: { tenant_id },
            })
            .then(parseResult)
            .catch(throwError)

    get = (user_id: string) =>
        this.client
            .get<APIHTTPResponse<SpecialAccessRecord>>(`${this.subRoute}/get-special-accesses`, {
                params: { user_id },
            })
            .then(parseResult)
            .catch(throwError)

    create = (user_id: string, accesses: string[]) =>
        this.client
            .post<APIHTTPResponse<Tenant>>(`${this.subRoute}/create-special-access`, {
                user_id,
                accesses,
            })
            .then(parseResult)
            .catch(throwError)

    edit = (user_id: string, accesses: string[]) =>
        this.client
            .put<APIHTTPResponse<Tenant>>(`${this.subRoute}/edit-special-access`, { accesses }, { params: { user_id } })
            .then(parseResult)
            .catch(throwError)

    delete = (user_id: string) =>
        this.client
            .delete<APIHTTPResponse<Tenant>>(`${this.subRoute}/remove-special-access`, { params: { user_id } })
            .then(parseResult)
            .catch(throwError)
}

export default SpecialAccessManagement
