import { SimpleFloor, SimpleBuilding, SimpleCampus, SimpleSpace } from '../../../schema'
import { ACTION_KEY_BMS } from '../../actions/bms'
import { Action } from '../../actions'

interface BuildingState {
  // reuqest login status
  success: boolean,
  loading: boolean,
  error: any,

  buildings: SimpleBuilding[],
  buildingDetail: {
    building: SimpleBuilding,
    floors: SimpleFloor[],
    spaces: SimpleSpace[],
    addFloorSuccess: SimpleFloor[],
  },

  isRequestingCreateFloor: boolean,
  isRequestingCreateSpace: boolean,
}

const initialState: BuildingState = {
  success: false,
  loading: false,
  error: null,
  buildings: null,
  buildingDetail: {
    building: null,
    floors: null,
    spaces: null,
    addFloorSuccess: null,
  },

  isRequestingCreateFloor: false,
  isRequestingCreateSpace: false,
}

const BuildingReducer = function (state = initialState, action: Action): BuildingState {
  switch (action.type) {
    case ACTION_KEY_BMS.BUILDING_LIST.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        buildings: action.payload,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        buildingDetail: action.payload,
        isRequestingCreateFloor: false,
        isRequestingCreateSpace: false,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.REQUEST: {
      return {
        ...state,
        loading: true,
        isRequestingCreateFloor: true,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        isRequestingCreateFloor: false,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_FLOOR.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        isRequestingCreateFloor: false,
        buildingDetail: {
          ...state.buildingDetail,
          addFloorSuccess: action.payload,
          floors: [...state.buildingDetail.floors, action.payload],
        },
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.REQUEST: {
      return {
        ...state,
        loading: true,
        isRequestingCreateSpace: true,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        isRequestingCreateSpace: false,
        error: action.payload,
      }
    }

    case ACTION_KEY_BMS.BUILDING_DETAIL_CREATE_SPACE.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        isRequestingCreateSpace: false,
        buildingDetail: {
          ...state.buildingDetail,
          spaces: [...state.buildingDetail.spaces, action.payload],
        },
      }
    }

    default: {
      return state
    }
  }
}

export default BuildingReducer
