import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import QRPage from './qr-access'



const routes = [{
    path: "/access",
    component: QRPage
  }]

export default routes
