import React from "react"
import { AuthRoles, ACCESS_POLICY } from "@exaleap/common"

const tenantManagement = [ACCESS_POLICY.propertiesManagementService.tenantManagement.readOnly]
const userManagement = [ACCESS_POLICY.propertiesManagementService.userManagement.readOnly, ACCESS_POLICY.propertiesManagementService.userManagement.fullAccess]
const visitorPolicies = [ACCESS_POLICY.propertiesManagementService.visitorService.readOnly, ACCESS_POLICY.propertiesManagementService.visitorService.fullAccess]

const ProfileRoutes = [
    {
        path: "/app/profile",
        component: React.lazy(() => import("./personal")),
      },
      
]

export default ProfileRoutes

