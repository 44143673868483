import * as R from 'ramda'
import {
  // axios,
  AxiosInstance,
  AxiosResponse,
} from 'axios'
import { ScanPoint, ScanPointRequired, addScanPointRule,SpendingTransaction } from '../../../schema'
import APIRestfulProvider, { PaginatedResponse, throwError, APIHTTPResponse } from '../../restful-provider'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class ScanPointRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/scan-point'


  // getRepeciptsByPageLeasing = (campusId: string, params) => {
  //   const args = {
  //     campus_ids: campusId ? [campusId] : [],
  //     ...R.reject(
  //       R.either(R.isEmpty, R.isNil),
  //       R.pick(
  //         [
  //           'page_number',
  //           'page_size',
  //           'order_by',
  //           'sort_by',
  //           'receipt_date_to',
  //           'receipt_date_from',
  //           'search',
  //           'is_receipt_duplicated',
  //           'status',
  //         ],
  //         params,
  //       ),
  //     ),
  //   }
  //   return this.client
  //     .post<PaginatedResponse<SpendingTransaction>>(`/self-service-points/receipt/list/page/leasing`, args)
  //     .then((d) => d.data)
  // }

  getRepeciptsByPageLeasing = (campusId: string, params: ScanPointRequired) => {
    const dateArr = ['start_to', 'start_from', 'end_from', 'end_to']
    const args = {
      campus_id: [campusId || ''],
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search',...dateArr], params),
      ),
    }
    dateArr.forEach((key) => {
      if (!!args[key]) {
         args[key] = new Date(args[key]).getTime()
      }
    })
    return this.client.post<PaginatedResponse<ScanPoint>>(`/self-service-points/receipt/list/page/leasing`, args).then((d) => d.data)
  }

  // 扫码领分 列表
  getQrCodeList = (campusId: string, params: ScanPointRequired) => {
    const dateArr = ['start_to', 'start_from', 'end_from', 'end_to']
    const args = {
      campus_id: [campusId || ''],
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search',...dateArr], params),
      ),
    }
    dateArr.forEach((key) => {
      if (!!args[key]) {
         args[key] = new Date(args[key]).getTime()
      }
    })
    return this.client.post<PaginatedResponse<ScanPoint>>(`${this.subRoute}/rule/list`, args).then((d) => d.data)
  }
  // 扫码领分 查看规则
  getRuleById = (id: string) =>
    this.client
      .get<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rule/list/:${id}`)
      .then(parseResult)
      .catch(throwError)

  // 扫码领分 新增规则
  addRule = (params: addScanPointRule) =>
    this.client
      .post<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rule`, params)
      .then(parseResult)
      .catch(throwError)

  // 扫码领分 编辑规则
  editRule = (id: string, params: addScanPointRule) =>
    this.client
      .put<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rule/${id}`, params)
      .then(parseResult)
      .catch(throwError)

  // 扫码领分 导出二维码
  getQrCode = (rule_id: string) =>
    this.client
      .post<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/scan`, { rule_id })
      .then(parseResult)
      .catch(throwError)
}

export default ScanPointRestful
