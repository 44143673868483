import { ACTION_KEY_BMS } from '../../actions/bms'
import { Action, ACTION_KEY_ACCESS_CONTROL } from '../../actions'

interface AccessControlMgmtState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,
}

const initialState: AccessControlMgmtState = {
    success: false,
    loading: false,
    error: null,
}

const AccessControlMgmtReducer = function(state = initialState, action: Action): AccessControlMgmtState {
    switch (action.type) {
        case ACTION_KEY_ACCESS_CONTROL.ACCESS_CONTROL_TRANSCATIONS.REQUEST: {
            return {
                ...state,
                success: true,
                loading: false,
            }
        }

        default: {
            return state
        }
    }
}

export default AccessControlMgmtReducer
