import { Dispatch } from 'react'
import { SimpleBuilding, SimpleMerchant } from '@exaleap/common'

export enum ActionType {
  ACTION_SET_BUILDINGS = 'ACTION_SET_BUILDINGS',
  ACTION_SET_MERCHANTS = 'ACTION_SET_MERCHANTS',
}

export type Action =
  | { type: ActionType.ACTION_SET_BUILDINGS, value: SimpleBuilding[] }
  | { type: ActionType.ACTION_SET_MERCHANTS, value: SimpleMerchant[] }

const leasingAction = (dispatch: Dispatch<Action>) => {
  const setBuildings = (value: SimpleBuilding[]) =>
    dispatch({
      type: ActionType.ACTION_SET_BUILDINGS,
      value,
    })

  const setMerchants = (value: SimpleMerchant[]) =>
    dispatch({
      type: ActionType.ACTION_SET_MERCHANTS,
      value,
    })

  return { setBuildings, setMerchants }
}

export default leasingAction
