import { ListRequest, SimpleBase, LocalizationObject, ImageDisplayObject } from './base'

export enum FacilityType {
  gym_room = 'gym_room',
  swimming_pool = 'swimming_pool',
}

export interface FacilityLocalizedFields {
  display_name: string,
  facility_address: string,
  campus_name: string,
  campus_address?: string,
  building_name: string,
}

export enum FacilityStateType {
  closed = 'closed',
  opening = 'opening',
}

export interface OpeningHoursObject {
  weekday: number,
  open: boolean,
  timeslots: string[],
}
export interface Facility extends SimpleBase, FacilityLocalizedFields, LocalizationObject<FacilityLocalizedFields> {
  campus_id: string,
  building_id: string,
  type: FacilityType,
  enabled: boolean,
  display_on_app: boolean,
  icon_url?: string,
  gallery?: ImageDisplayObject[],
  hero?: ImageDisplayObject[],
  // index: 1
  // "active_session_duration_in_minutes": 180,
  // "last_entry_time_in_minutes": 30,
  // "last_entry_warning_message": "",
  show_notice?: boolean,
  opening_hours?: OpeningHoursObject[],
  state?: FacilityStateType,
  display_name: string,
  description?: string,
  additional_opening_description?: string,
  facility_address: string,
  notice_message?: string,
  campus_name: string,
  campus_address: string,
  building_name: string,
}

