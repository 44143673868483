import React from 'react'
import { Avatar } from '@material-ui/core'
import { DisplayUser } from '@exaleap/common'
import { getValueWithLocale } from 'util/localeHelper'

const UserAvatar = ({ user, ...props }: { user: DisplayUser } & React.HTMLAttributes<
    HTMLElement
>) => {
    return (
        <Avatar {...props} style={{ ...(props?.style ?? {}), backgroundColor: '#6FCF97' }}>
            {user?.profile_picture_url?.length ? (
                <img src={user.profile_picture_url} style={{ width: '100%', position: 'relative' }} />
            ) : (
                getValueWithLocale('last_name', user)[0] || getValueWithLocale('display_name', user)[0]
            )}
        </Avatar>
    )
}

export default UserAvatar
