import {
  // axios,
  AxiosInstance,
} from 'axios'
import {
  Device,
  DeviceType,
  // StatusRecord,
  // TokenResult,
  // Descriptor,
   DataModel,
  CreatingDevice,
  // DeviceInterface,
  LatestDeviceData,
} from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'

class DevicesRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/device-management'

  //////// Device Type
  // ***** 只是路径变化 
  getDeviceTypes = () =>
    this.client
      .get<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-type/get-device-types`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  getDeviceTypeById = (id: string) =>
    this.client
      .get<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-type/get-device-types`, {
        params: { id },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  // ???? 好像没使用
  createDeviceType = () =>
    this.client
      .post<APIHTTPResponse<DeviceType>>('/deviceType/create-device-type')
      .then((d) => d.data.result)
      .catch(throwError)

  // ???? 参数变化了，暂时没有使用
  updateDeviceType = (id: string) =>
    this.client
      .patch<APIHTTPResponse<DeviceType>>(`/deviceType/edit-device-type`, id)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  getDevices = (
    searchText?: string,
    device_type_ids?: string[],
    locations?: string[],
    page_number = 1,
    page_size = 1000,
  ) =>
    this.client
      .post<PaginatedResponse<Device>>(`${this.subRoute}/device/get-devices`, {
        display_name: searchText,
        device_type_ids: device_type_ids || [],
        locations: locations || [],
        page_number,
        page_size,
      })
      .then((d) => d.data)

  // ***** 只是路径变化
  getDevice = (id: string) =>
    this.client
      .get<APIHTTPResponse<Device>>(`${this.subRoute}/device/get-device`, {
        params: {
          id,
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  createDevice = (create: CreatingDevice) =>
    this.client
      .post<APIHTTPResponse<Device>>(`${this.subRoute}/device/create-device`, create)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  updateDevice = (id: string, device: Pick<CreatingDevice, 'display_name' | 'doc'>) =>
    this.client
      .put<APIHTTPResponse<Device>>(`${this.subRoute}/device/edit-device?id=${id}`, device)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  deleteDevice = (id: string) =>
    this.client
      .delete<APIHTTPResponse<{ id: string, status: string }>>(`${this.subRoute}/device/delete-device`, {
        params: { id },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  associateLocation = (device_id: string, space_id: string) =>
    this.client
      .post<APIHTTPResponse<{ space_id: string, device_id: string }>>(
        `${this.subRoute}/device/associate-device-with-space`,
        {
          device_id,
          space_id,
        },
      )
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  unassociateLocation = (device_id: string, space_id: string) =>
    this.client
      .delete<APIHTTPResponse<{ space_id: string, device_id: string }>>(
        `${this.subRoute}/device/unassociate-device-with-space`,
        {
          params: { device_id, space_id },
        },
      )
      .then((d) => d.data.result)
      .catch(throwError)

  // get-device-interface-data  唯一认证新增的接口，其他???? **** 待确认
  getDeviceInterfaceData = (device_id: string, data_model_id: string, start = 1617494000000, end = 1618494400000) =>
    this.client
      .get<APIHTTPResponse<LatestDeviceData>>(`${this.subRoute}/device/get-device-interface-data`, {
        params: {
          device_id,
          interface_id: data_model_id,
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)
  
  ////////// DataModels

  getDataModels = () =>
    this.client
      .get<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-model/get-data-models`)
      .then((d) => d.data.result)
      .catch(throwError)

  getDataModel = (id: string) => {
    return this.client
      .get<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-model/get-data-models`, {
        params: { id },
      })
      .then((d) => d.data.result)
      .catch(throwError)
  }

  // ???? 接口路径发生变化
  createDataModel = () =>
    this.client
      .post<APIHTTPResponse<DataModel>>('/data-model/create-data-model')
      .then((d) => d.data.result)
      .catch(throwError)
  
  // ????  新接口
  editDataModelById = (id: string) =>
    this.client
      .put<APIHTTPResponse<DataModel>>(`/data-model/edit-data-model?id=${id}`)
      .then((d) => d.data.result)
      .catch(throwError)
}

export default DevicesRestful
