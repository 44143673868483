import {
  all,
  // take,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import {
  Device,
  DeviceType,
  // DataModelRecordPair,
  CreatingDevice,
  PaginationResponse,
  // DeviceDataModel
  // LatestDeviceData
} from '../../schema'
import { Action, ACTION_KEY_IOT_MGMT, IOTActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'

function* deviceListRequest(action: Action) {
  console.log('[SAGA] deviceListRequest')
  const filterSelector = (state) => state.iot.device.filter
  const filter = yield select(filterSelector)
  const { device_type_ids, locations, display_name, page_size: input_page_size, page_number } = filter

  yield put(hrdpActions.fetchStart())

  try {
    const {
      result: devices,
      current_page,
      page_size,
      total_records,
      total_pages,
    } = yield call(
      apiService.platform.devices.getDevices,
      display_name,
      device_type_ids,
      locations,
      page_number,
      input_page_size,
    )

    const types = yield call(apiService.platform.devices.getDeviceTypes)
    const powers = null
    const connections = null

    const campuses = yield call(apiService.platform.campus.getCampuses)

    const pagination: PaginationResponse = {
      current_page,
      page_size,
      total_records,
      total_pages,
    }

    yield put(IOTActions.deviceList.success(devices, types, powers, connections, campuses, pagination))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.warn('[SAGA] deviceListRequest error', error)
    yield put(IOTActions.deviceList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
}

function* deviceListFilterChange(action: Action) {
  console.log('[SAGA] deviceListFilterChange')
  const filterSelector = (state) => state.iot.device.filter
  const filter = yield select(filterSelector)
  const { device_type_ids, locations, display_name, page_size: input_page_size, page_number } = filter

  yield put(hrdpActions.fetchStart())

  try {
    const {
      result: devices,
      current_page,
      page_size,
      total_records,
      total_pages,
    } = yield call(
      apiService.platform.devices.getDevices,
      display_name,
      device_type_ids,
      locations,
      page_number,
      input_page_size,
    )

    yield put(
      IOTActions.changeFilterResult(action.payload, devices, {
        current_page,
        page_size,
        total_records,
        total_pages,
      }),
    )

    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.warn('[SAGA] deviceListRequest error', error)
    yield put(IOTActions.deviceList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
}

function* deviceDetailAssociateLocation(action: Action) {
  console.warn('[SAGA] deviceDetailAssociateLocation')

  const { device_id, space } = action.payload

  try {
    yield call(apiService.platform.devices.associateLocation, device_id, space.id)
    yield put(IOTActions.associateLocation.success(space))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.warn('[SAGA] associateLocation error', error)
    yield put(IOTActions.associateLocation.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* deviceDetailUnssociateLocation(action: Action) {
  console.warn('[SAGA] deviceDetailUnssociateLocation')

  const { device_id, space_id } = action.payload

  yield put(hrdpActions.fetchStart())

  try {
    yield call(apiService.platform.devices.unassociateLocation, device_id, space_id)
    yield put(IOTActions.unassociateLocation.success())
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.warn('[SAGA] associateLocation error', error)
    yield put(IOTActions.unassociateLocation.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
}

function* deviceDetailRequest(action: Action) {
  console.group('[SAGA] deviceDetailRequest')
  const id = action.payload

  try {
    // const { device, type, records, spaces, location } = devices

    yield put(hrdpActions.fetchStart())

    const device: Device = (yield call(apiService.platform.devices.getDevice, id))[0]
    // const type= null
    const type = (yield call(apiService.platform.devices.getDeviceTypeById, device.device_type.id))[0]
    // console.log("type", type)
    // console.log("records", records)

    yield put(IOTActions.deviceDetail.success(device, type, [], null))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] deviceDetailRequest', error)
    yield put(IOTActions.deviceDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

function* deviceDelete(action: Action) {
  console.warn('[SAGA] deviceDelete')
  const id = action.payload

  try {
    const results: { id: string, status: string }[] = yield call(apiService.platform.devices.deleteDevice, id)
    if (results.length === 0) {
      throw new Error('Device Type not found')
    }
    yield put(IOTActions.deleteDevice.success(id))
  } catch (error) {
    console.warn('[SAGA] deviceDelete error', error)
    yield put(IOTActions.deleteDevice.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* deviceStartCreate(action: Action) {
  console.warn('[SAGA] deviceStartCreate')
  // const id = action.payload

  try {
    const types: DeviceType[] = yield call(apiService.platform.devices.getDeviceTypes)

    if (types.length === 0) {
      throw new Error('Device Type not found')
    }

    yield put(IOTActions.createDeviceBegin.success(types))
  } catch (error) {
    console.warn('[SAGA] deviceStartCreate', error)
    yield put(IOTActions.createDeviceBegin.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* deviceCreateRequest(action: Action) {
  console.warn('[SAGA] deviceCreateRequest')
  const createDevice: CreatingDevice = action.payload

  try {
    const devices: Device[] = yield call(apiService.platform.devices.createDevice, createDevice)

    if (devices.length === 0) {
      throw new Error('Device not found')
    }

    yield put(IOTActions.createDevice.success(devices[0]))
  } catch (error) {
    console.warn('[SAGA] deviceStartCreate', error)
    yield put(IOTActions.createDevice.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* deviceTypeListRequest(action: Action) {
  console.warn('[SAGA] deviceTypeListRequest')

  try {
    const types = yield call(apiService.platform.devices.getDeviceTypes)
    yield put(IOTActions.deviceTypeList.success(types))
  } catch (error) {
    console.warn('[SAGA] deviceTypeListRequest', error)
    yield put(IOTActions.deviceTypeList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* deviceTypeDetailRequest(action: Action) {
  console.warn('[SAGA] deviceTypeDetailRequest')
  const id = action.payload
  try {
    const types = yield call(apiService.platform.devices.getDeviceTypeById, id)
    if (types.length > 0) {
      yield put(IOTActions.deviceTypeDetail.success(types[0]))
    } else {
      throw new Error('Device Type not found')
    }
  } catch (error) {
    console.warn('[SAGA] deviceTypeDetailRequest', error)
    yield put(IOTActions.deviceTypeDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* dataModelListRequest(action: Action) {
  console.warn('[SAGA] dataModelListRequest')

  try {
    const models = yield call(apiService.platform.devices.getDataModels)
    yield put(IOTActions.dataModelList.success(models))
  } catch (error) {
    console.warn('[SAGA] dataModelListRequest', error)
    yield put(IOTActions.dataModelList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

function* dataModelDetailRequest(action: Action) {
  console.group('[SAGA] dataModelDetailRequest')
  const id = action.payload
  try {
    console.log('id', id)
    const models = yield call(apiService.platform.devices.getDataModel, id)
    console.log('models', models)
    if (models.length > 0) {
      yield put(IOTActions.dataModelDetail.success(models[0]))
    } else {
      throw new Error('Data Model not found')
    }
  } catch (error) {
    console.error('[SAGA] dataModelDetailRequest', error)
    yield put(IOTActions.dataModelDetail.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
  console.groupEnd()
}

export const iotSagas = [
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_LIST.REQUEST, deviceListRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.UPDATE_FILTER, deviceListFilterChange),
  takeEvery(ACTION_KEY_IOT_MGMT.UPDATE_SEARCH_STRING, deviceListFilterChange),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.REQUEST, deviceDetailRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_DELETE.REQUEST, deviceDelete),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_CREATE_BEGIN.REQUEST, deviceStartCreate),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_CREATE.REQUEST, deviceCreateRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.REQUEST, deviceTypeListRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.REQUEST, deviceTypeDetailRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DATA_MODEL_LIST.REQUEST, dataModelListRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DATA_MODEL_DETAIL.REQUEST, dataModelDetailRequest),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_ASSOCIATE_LOCATION.REQUEST, deviceDetailAssociateLocation),
  takeEvery(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_UNASSOCIATE_LOCATION.REQUEST, deviceDetailUnssociateLocation),
]
