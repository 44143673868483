import React, { useEffect, useContext, Component } from 'react'
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import URLSearchParams from 'url-search-params'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { SnackbarProvider } from 'notistack'
import 'assets/vendors/style'
import StateProvider from 'contexts/provider/StateProvider'
import greenTheme from './themes/greenTheme'
import AppLocale from '../lngProvider'
import RTL from 'util/RTL'
import asyncComponent from 'util/asyncComponent'
import AppLayout from './AppLayout'
import Notifier from './Notifier'
import { hrdpActions } from '@exaleap/common'
import { AppContext } from '../MainApp'
import { renderRoutes } from 'react-router-config'
import { useSelector } from 'state/root-store'
import { generateIntl } from '../util/IntlHelper'

const useStyles = makeStyles({
    success: { backgroundColor: 'green' },
    error: { color: '#fff !important' },
    warning: { backgroundColor: 'green' },
    info: { backgroundColor: 'yellow' },
})

const App = props => {
    const dispatch = useDispatch()
    const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(s => s.settings)
    const isDarkTheme = darkTheme
    const { match, location } = props
    const classes = useStyles()

    const { routes } = useContext(AppContext)

    // useEffect(() => {
    //     window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true
    //     // if (initURL === '') {
    //     //     dispatch(hrdpActions.setInitUrl(props.history.location.pathname))
    //     // }
    //     // const params = new URLSearchParams(props.location.search)
    //     // if (params.has('theme-name')) {
    //     //     dispatch(hrdpActions.setThemeColor(params.get('theme-name')))
    //     // }
    //     // if (params.has('dark-theme')) {
    //     //     dispatch(hrdpActions.setDarkTheme())
    //     // }
    // }, [dispatch, initURL])

    const getColorTheme = (themeColor, applyTheme) => {
        applyTheme = createMuiTheme(greenTheme)
        return applyTheme
    }

    let applyTheme = createMuiTheme(greenTheme)
    // if (isDarkTheme) {
    //     document.body.classList.add('dark-theme')
    //     applyTheme = createMuiTheme(darkTheme)
    // } else {
    //     applyTheme = getColorTheme(themeColor, applyTheme)
    // }
    applyTheme = getColorTheme(themeColor, applyTheme)

    console.log('applyTheme', applyTheme)
    // if (location.pathname === '/') {
    //     if (authUser === null) {
    //         return <Redirect to={'/signin'} />
    //     } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
    //         return <Redirect to={'/app/'} />
    //     } else {
    //         return <Redirect to={initURL} />
    //     }
    // }
    if (isDirectionRTL) {
        applyTheme.direction = 'rtl'
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl')
        applyTheme.direction = 'ltr'
    }

    const currentAppLocale = AppLocale[locale.locale]

    generateIntl({ locale: currentAppLocale.locale, messages: currentAppLocale.messages })
    return (
      <MuiThemeProvider theme={createMuiTheme(greenTheme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <StateProvider>
              <RTL>
                <div className="app-main">
                  <SnackbarProvider
                    classes={{
                      variantError: classes.error,
                    }}
                  >
                    <Notifier />
                    {renderRoutes(routes)}
                  </SnackbarProvider>
                </div>
              </RTL>
            </StateProvider>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    )
}

export default App
