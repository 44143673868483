import navigations from '../../constants/navigation-config'

export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'

const initialState = [...navigations]

const NavigationReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_NAVIGATION: {
      return [...action.payload]
    }
    default: {
      return [...state]
    }
  }
}

export default NavigationReducer
