import { ListRequest, SimpleBase, LocalizationObject } from './base'

export enum DatePeriod {
  All = 'all',
  Custom = 'custom',
  Last30Days = 'last_30_days',
  Last7Days = 'last_7_days',
  Today = 'today',
}

export enum HistoryFilterBy {
  EndTime = 'end_time',
  StartTime = 'start_time',
  Updated_at = 'updated_at',
}

export interface HistoryRequired extends ListRequest {
  end_time?: string
  filter_by_time?: HistoryFilterBy
  floor_index?: number
  order_by?: 'asc' | 'desc'
  sort_by?: 'end_time' | 'start_time' | 'visitor_number' | 'updated_at'
  start_time?: string
  tenant_id?: string
}

export interface LocationsLocalizedFields {
  space_name: string
  floor_name: string
}

export interface InvitationLocation extends LocationsLocalizedFields, LocalizationObject<LocationsLocalizedFields> {
  floor_index: number
  space_id: string
}

export interface Register {
  access_notification?: boolean
  area_code?: string
  company_name?: string
  email?: string
  end_time: string
  floor_index?: number
  floor_indexes?: number[]
  gender: string
  id_card_number: string
  is_auto_approve_require?: boolean
  notification_types: string[]
  phone_number?: string
  preferred_language: string
  remarks?: string
  space_types: string[]
  tenant_id: string
  visitor_name: string
  start_time: string
}

export interface Statistics {
  campus_address: string
  campus_id: string
  campus_name: string
  building_id: string
  building_name: string
  visitors_count: {
    [DatePeriod.All]: number
    [DatePeriod.Last30Days]: number
    [DatePeriod.Last7Days]: number
    [DatePeriod.Today]: number
  }
}

export interface Visitor extends SimpleBase {
  access_notification: boolean
  approver_name: string
  approver_role: string
  area_code: string
  company_name: string
  created_at: string
  end_time: string
  email: string
  floor_name: string
  gender: string
  id_card_number: string
  invitation_id: string
  locations: InvitationLocation[]
  notification_types: string[]
  phone_number: string
  preferred_language: string
  remarks: string
  start_time: string
  status: VisitorStatus
  tenant_name: string
  updated_at: string
  visitor_name: string
  visitor_number: string
  visitor_permission_types: string[]
}

export enum VisitorStatus {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Rejected = 'rejected',
}
