import { Dispatch } from 'react'
import { SimpleCampus } from '@exaleap/common'

export enum ActionType {
  ACTION_SET_CAMPUSES = 'ACTION_SET_CAMPUSES',
}

export type Action = { type: ActionType.ACTION_SET_CAMPUSES; value: SimpleCampus[] }

const systemInfoAction = (dispatch: Dispatch<Action>) => {
  const setCampuses = (value: SimpleCampus[]) =>
    dispatch({
      type: ActionType.ACTION_SET_CAMPUSES,
      value,
    })

  return { setCampuses }
}

export default systemInfoAction
