import IOTRestful from "./iot"
import APIRestfulProvider from "../../restful-provider"
import IAMRestful from "./iam"
import CampusRestful from "./campus"
import AccessRecordService from "./access-record"
import LookupService from "./entity-lookup"
import PermissionService from "./permission"
import SupplierManagementService from "./supplier"
import CustomUserManagementRestful from './user-group'
import DevicesRestful from './devices'

/**
 * Platform Management Services
 */


class PlatformManagementEndpoints {

    iot: IOTRestful
    iam: IAMRestful
    campus: CampusRestful
    access: AccessRecordService
    lookup: LookupService
    permission: PermissionService
    supplier: SupplierManagementService
    userGroup:CustomUserManagementRestful
    devices:DevicesRestful
    constructor(provider: APIRestfulProvider) {
        this.iot = new IOTRestful(provider)
        this.iam = new IAMRestful(provider)
        this.campus = new CampusRestful(provider)
        this.access = new AccessRecordService(provider)
        this.lookup = new LookupService(provider)
        this.permission = new PermissionService(provider)
        this.supplier = new SupplierManagementService(provider)
        this.userGroup=new CustomUserManagementRestful(provider)
        this.devices=new DevicesRestful(provider)
    }

}


export default PlatformManagementEndpoints