import { AuthChecking, User } from '@exaleap/common'
import navigations from '@exaleap/common/src/constants/navigation-config'
import { cloneDeep } from 'lodash'
import * as R from 'ramda'
import { matchRoutes } from 'react-router-config'

export const SET_USER_NAVIGATION = "SET_USER_NAVIGATION"

// App Menu function control

const getfilteredNavigations = (routes, user, navList = []) => {
  console.log('[Navigations] - user policies', user.policies)

  return navList.reduce((array, nav) => {

    const matched = matchRoutes(routes, nav.link)[0]
    if (matched && matched.route.auth) {
      const skipDomains: string[] = matched.route.skipDomains ?? []
      if (AuthChecking(user, matched.route.auth) && R.none(value => R.includes(value, window.location.hostname), skipDomains)) {
        console.log('[Navigations] Success - ', nav.link, matched.route.auth)
        array.push(nav)
      } else {
        console.log('[Navigations] FAILED - ', nav.link, matched.route.auth)
      }
    } else {
      if (nav.children) {
        nav.children = getfilteredNavigations(routes, user, nav.children) // set its children
        if (nav.children.length > 0) {
          array.push(nav)
        }
      } else {
        array.push(nav)
      }
    }
    return array
  }, [])
}

const getNavigationByUser = (routes: any[], user: User) => {
  console.log('[Navigations] getNavigationByUser before', navigations)
  const results = getfilteredNavigations(routes, user, cloneDeep(navigations)).filter(r => r.children.length !== 0)
  console.log('[Navigations] getNavigationByUser after', results)

  return results

  // dispatch({
  //   type: SET_USER_NAVIGATION,
  //   payload: [...filteredNavigations]
  // })

}

export default getNavigationByUser