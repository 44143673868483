export const POINT_TRANSACTION_TYPES = [
  'READ_FEATURED_STORY_REWARD',
  'SHARE_FEATURED_STORY_REWARD',
  'TIER_UPGRADE_REWARD',
  'POINT_ADJUSTMENT',
  'INVITATION_INVITER_REWARD',
  'INVITATION_INVITEE_REWARD',
  'SPENDING_REWARD',
  'SPENDING_BONUS',
  'SCAN_TO_EARN_REWARD',
  'ENTER_BUILDING_REWARD',
  'VISITOR_VISIT_REWARD',
  'RESERVE_GIFT',
  'RESERVE_EVENT',
  'BASIC_TIER_REWARD',
  'SILVER_TIER_REWARD',
  'VIP_TIER_REWARD',
  'MEMBERSHIP_UPGRADE_DEDUCT_POINT',
]

export const POINT_TRANSACTION_ADD_DEDUCT = [
  'ADD','DEDUCT'
]

export const MERCHANT_LOCATION_TYPE = [
  'IN_CAMPUS','OTHER_LOCATION'
] 