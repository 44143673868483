import React from "react"
import { USER_GROUPS, ACCESS_POLICY } from "@exaleap/common"

const iotPolicies = [ACCESS_POLICY.platformManagement.iotManagement.fullAccess]

const IOTRoutes = [
  {
    path: "/app/iot/devices/create",
    component: React.lazy(() => import("./CreateDevice")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices/:id/:mode",
    component: React.lazy(() => import("./DeviceDetailTabContainer")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices/:id",
    component: React.lazy(() => import("./DeviceDetailTabContainer")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices",
    component: React.lazy(() => import("./DeviceList")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/device_types/:id",
    component: React.lazy(() => import("./DeviceTypeDetail")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/device_types",
    component: React.lazy(() => import("./DeviceTypeList")),
    auth: iotPolicies
  },
  {
    path: "/app/iot/interfaces",
    component: React.lazy(() => import("./InterfaceList")),
    auth: iotPolicies
  },
  {
    path: "/data_models/:id",
    component: React.lazy(() => import("./DataModelDetail")),
    auth: iotPolicies
  },
  {
    path: "/data_models",
    component: React.lazy(() => import("./DataModelList")),
    auth: iotPolicies
  },
]

export default IOTRoutes
