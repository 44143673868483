import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { ListRequest, Entity, Policy } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class LookupService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }
    subRoute = '/platform-management/entity-lookup'

    search = (params?: ListRequest & { type: string }) =>
        this.client
          .get<PaginatedResponse<Entity>>(`${this.subRoute}/search`, {
            params
          })
          .then(d => d.data).
          catch(throwError)

    getPoliciesById = (id: string) =>
      this.client
        .get<APIHTTPResponse<Policy>>(`${this.subRoute}/get-policies-by-user-group-id`, {
          params: { id }
        })
        .then(parseResult)
        .catch(throwError)

    getUserGroupsByTypeAndId = (params: { type: string, type_id?: string, user_id?: string }) =>
      this.client
        .get<APIHTTPResponse<Pick<Entity, 'category' | 'display_name' | 'id' | 'user_groups'>>>(`${this.subRoute}/get-user-groups-by-type-and-id`, {
          params,
        })
        .then(parseResult)
        .catch(throwError)
}

export default LookupService