import { AuthUser, User } from '../schema'

const UI_PLATFORM_ADMIN = 'ui_platform_admin'
const UI_ORG_ADMIN = 'ui_org_admin'
const UI_PROPERTY_USER = 'ui_property_user'
const UI_TENANT_USER = 'ui_tenant_user'

const UI_VISITOR = 'ui_visitor' // fallback
export const AuthRoles = {
  platform: [UI_PLATFORM_ADMIN], // Re-route to platform management portal
  admin: [UI_ORG_ADMIN, UI_PROPERTY_USER], // admin portal
  normal: [UI_ORG_ADMIN, UI_PROPERTY_USER, UI_TENANT_USER], // normal user
  all: [UI_ORG_ADMIN, UI_PLATFORM_ADMIN, UI_TENANT_USER, UI_VISITOR],

  tenant_admin: [],
  tenant_user: [],
  visitor: [],
}

// const UI_TENANT_USER = 'ui_tenant_user'
// const UI_PROPERTY_USER = 'ui_property_user'
// const UI_PLATFORM_ADMIN = 'ui_platform_admin'

export const USER_GROUPS = {
  INTERFACE_ADMIN_GROUP: 'interface_admin_group',
  DEVICE_AND_DEVICE_TYPE_ADMIN_GROUP: 'device_and_device_type_admin_group',
  BUILDING_ADMIN_GROUP: 'building_admin_group',
  IAM_ADMIN_GROUP: 'iam_admin_group',
  DATA_MODEL_ADMIN_GROUP: 'data_model_admin_group',
  BASIC_USER_GROUP: 'basic_user_group',
  TENANT_ADMIN_GROUP: 'tenant_admin_group',
  SUPPLIER_ADMIN_GROUP: 'supplier_admin_group',
  DATA_VIEWER_GROUP: 'data_viewer_group',
  ACCESS_CONTROL_AND_VISITOR_SYSTEM_ADMIN_GROUP: 'access_control_and_visitor_system_admin_group',
}

export const ACCESS_POLICY = {
  analytics: {
    accessControl: {
      fullAccess: 'policy.analytics.accessControl.fullAccess',
    },
    butler: {
      fullAccess: 'policy.analytics.butler.fullAccess',
    },
    gamification: {
      fullAccess: 'policy.analytics.gamification.fullAccess',
    },
    member: {
      fullAccess: 'policy.analytics.member.fullAccess',
    },
    spendingEventsAndGift: {
      fullAccess: 'policy.analytics.spendingEventsAndGift.fullAccess',
    },
    visitor: {
      fullAccess: 'policy.analytics.visitor.fullAccess',
    },
    workOrder: {
      fullAccess: 'policy.analytics.workOrder.fullAccess',
    }
  },
  platformManagement: {
    supplierManagement: {
      fullAccess: 'policy.platformManagement.supplierManagement.fullAccess',
    },
    iotManagement: {
      fullAccess: 'policy.platformManagement.iotManagement.fullAccess',
    },
    campusManagement: {
      readOnly: 'policy.platformManagement.campusManagement.readOnly',
      fullAccess: 'policy.platformManagement.campusManagement.fullAccess',
    },
    customUserGroupManagement: {
      fullAccess: 'policy.platformManagement.customUserGroupManagement.fullAccess',
    },
    iam: {
      readOnly: 'policy.platformManagement.iam.readOnly',
      fullAccess: 'policy.platformManagement.iam.fullAccess',
      userManagement: {
        fullAccess: 'policy.platformManagement.iam.userManagement.fullAccess',
      },
    },
    accessControl: 'policy.accessControl.readOnly',
  },
  propertiesManagementService: {
    userManagement: {
      readOnly: 'policy.propertiesManagementService.userManagement.readOnly',
      fullAccess: 'policy.propertiesManagementService.userManagement.fullAccess',
    },

    tenantManagement: {
      readOnly: 'policy.propertiesManagementService.tenantManagement.readOnly',
    },

    specialAccessManagement: {
      readOnly: 'policy.propertiesManagementService.specialAccessManagement.readOnly',
      fullAccess: 'policy.propertiesManagementService.specialAccessManagement.fullAccess',
    },

    visitorService: {
      readOnly: 'policy.propertiesManagementService.visitorService.readOnly',
      fullAccess: 'policy.propertiesManagementService.visitorService.fullAccess',
    },

    workOrderManagement: {
      readOnly: 'policy.propertiesManagementService.workOrderManagement.readOnly',
    },

    generalService: {
      basic: 'policy.propertiesManagementService.generalService.basic',
    },

    facilityService: {
      fullAccess: 'policy.facilityService.propertiesManagementAccess.fullAccess',
    },
    shuttleBusService: {
      fullAccess: 'policy.shuttleBusService.propertiesManagementAccess.fullAccess',
    },
    gameManagement: {
      fullAccess: 'policy.propertiesManagementService.gameManagement.fullAccess',
    },
  },
  buildingService: {
    accessControlService: {
      genericQRCodeAccess: 'policy.buildingService.accessControlService.genericQRCodeAccess',
    },
  },
  communityService: {
    cmsService: {
      admin: 'policy.communityService.cms.admin',
      editor: 'policy.communityService.cms.editor',
    },
  },
  leasingManagement: {
    tenantManagement: {
      fullAccess: 'policy.leasingManagement.tenantManagement.fullAccess',
      readOnly: 'policy.leasingManagement.tenantManagement.readOnly',
      userManagement: {
        fullAccess: 'policy.leasingManagement.tenantManagement.userManagement.fullAccess',
      },
      visitorManagement: {
        fullAccess: 'policy.leasingManagement.tenantManagement.visitorManagement.fullAccess',
      },
    },
    apiKeyManagement: {
      fullAccess: 'policy.leasingManagement.apiKeyManagement.fullAccess',
    },
    propertiesManagementCompany: {
      fullAccess: 'policy.leasingManagement.propertiesManagementCompany.fullAccess',
      readOnly: 'policy.leasingManagement.propertiesManagementCompany.readOnly',
    },
    userManagement: {
      fullAccess: 'policy.leasingManagement.userManagement.fullAccess',
    },

    generalService: {
      basic: 'policy.leasingManagement.generalService.basic',
    },
    gameManagement: {
      fullAccess: 'policy.leasingManagement.gameManagement.fullAccess',
    },
    memberService: {
      fullAccess: 'policy.leasingManagement.memberService.fullAccess',
      membership: 'policy.leasingManagement.memberService.membership',
      invitationRule: 'policy.leasingManagement.memberService.invitationRule',
      invitation: 'policy.leasingManagement.memberService.invitation',
      pointTransaction: 'policy.leasingManagement.memberService.pointTransaction',
    },
    scanPoint: {
      fullAccess:"policy.leasingManagement.scanPoint.fullAccess"
    },
    merchant: {
      merchantSubTypeManagement: 'policy.leasingManagement.merchant.merchantSubTypeManagement',
      merchantManagement:'policy.leasingManagement.merchant.merchantManagement'
    },
    appConfigManagement: {
      fullAccess: 'policy.leasingManagement.appConfigManagement.fullAccess',
    },
    appConfigService: {
      privacyPolicy: {
        fullAccess:'policy.leasingManagement.appConfigService.privacyPolicy.fullAccess'
      }
    }
  },
  tenantService: {
    visitorService: {
      approval: 'policy.tenantService.visitorService.approval',
      myRequest: 'policy.tenantService.visitorService.myRequest',
      request: 'policy.tenantService.visitorService.request',
      approvalManagement: 'policy.tenantService.visitorService.approvalManagement',
    },
    generalService: {
      tenantInfo: {
        readOnly: 'policy.tenantService.generalService.tenantInfo.readOnly',
        fullAccess: 'policy.tenantService.generalService.tenantInfo.fullAccess',
      },
    },
    userManagement: {
      readOnly: 'policy.tenantService.userManagement.readOnly',
      fullAccess: 'policy.tenantService.userManagement.fullAccess',
    },
  },
  valueAddedService: {
    propertiesManagementAccess: {
      fullAccess: 'policy.valueAddedService.propertiesManagementAccess.fullAccess',
    },
  },
  visitorService: {
    propertiesManagementAccess: {
      fullAccess: 'policy.visitorService.propertiesManagementAccess.fullAccess',
    },
  },
  supplierService: {
    accessControlService: ['addIccardInfoToUser', 'addFacialInfoToUser'],
  },
  propertiesManagementCompany: { 
    merchant: {
      merchantManagement: 'policy.propertiesManagementCompany.merchant.merchantManagement'
    }
  },
  local: {
    development: 'policy.local.development',
  },
}

const policyStringLookup = {}

export const ROLES_UI_GROUPS = [UI_PLATFORM_ADMIN, UI_ORG_ADMIN, UI_PROPERTY_USER, UI_TENANT_USER]

export const UIRole = (user: AuthUser) =>
  (user && user.policies && user.policies.find((s) => ROLES_UI_GROUPS.includes(s))) || null

export const AuthChecking = (user: User, policies: string[]) => {
  console.log('Authchecking:', user)
  if (!policies || policies.length === 0) return true
  // if (user?.policies?.includes(ACCESS_POLICY.local.development)) return true
  return policies.filter((r) => user?.policies?.includes(r)).length > 0
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
