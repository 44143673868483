import APIRestfulProvider from "../../restful-provider"
import PropertiesManagementAccess from "./properties-mgmt-access"

/**
 * Visitor Service
 */


class VisitorServiceEndpoints {
    pmc: PropertiesManagementAccess

    constructor(provider: APIRestfulProvider) {
        this.pmc = new PropertiesManagementAccess(provider)
    }

}


export default VisitorServiceEndpoints