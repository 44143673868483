import { RequestActionFunctions, action } from './index'
import { SimpleCampus, Tenant, SimpleSpace, User } from '../../schema'
import { createRequestActionStrings } from './bms.helper'

// Tenant Related
export const ACTION_KEY_TENANT = {
    TENANT_LISTING: createRequestActionStrings('TENANT_LISTING'),
    TENANT_DETAIL: createRequestActionStrings('TENANT_DETAIL'),
    TENANT_DETAIL_SAVE: createRequestActionStrings('TENANT_DETAIL_SAVE'),
    TENANT_DETAIL_ASSOCIATE_SPACE: createRequestActionStrings('TENANT_DETAIL_ASSOCIATE_SPACE'),
    TENANT_DETAIL_UNASSOCIATE_SPACE: createRequestActionStrings('TENANT_DETAIL_UNASSOCIATE_SPACE'),

    TENANT_STAFF_ASSOCIATE_SPACE: createRequestActionStrings('TENANT_STAFF_ASSOCIATE_SPACE'),

    TENANT_ADD_USER: createRequestActionStrings('TENANT_ADD_USER'),
    TENANT_REMOVE_USER: createRequestActionStrings('TENANT_REMOVE_USER'),
    TENANT_USER_LISTING: createRequestActionStrings('TENANT_USER_LISTING'),
}

// Tenant List
export const tenantList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_TENANT.TENANT_LISTING.REQUEST),
    success: (tenants: Tenant[]) => action(ACTION_KEY_TENANT.TENANT_LISTING.SUCCESS, tenants),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_LISTING.FAILURE, error),
}

// Tenant Detail
export const tenantDetail: RequestActionFunctions = {
    request: (id) => action(ACTION_KEY_TENANT.TENANT_DETAIL.REQUEST, id),
    success: (tenant: Tenant, spaces: SimpleSpace[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL.SUCCESS, {tenant, spaces}),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_DETAIL.FAILURE, error),
}


// campus > building > space
// Tenant Detail
export const tenantDetailFetchLocationOptions: RequestActionFunctions = {
    request: (id) => action(ACTION_KEY_TENANT.TENANT_DETAIL.REQUEST, id),
    success: (tenant: Tenant, spaces: SimpleSpace[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL.SUCCESS, {tenant, spaces}),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_DETAIL.FAILURE, error),
}



// Tenant Detail
export const tenantDetailSave: RequestActionFunctions = {
    request: (tenant: Tenant) => action(ACTION_KEY_TENANT.TENANT_DETAIL_SAVE.REQUEST, tenant),
    success: (tenant: Tenant) => action(ACTION_KEY_TENANT.TENANT_DETAIL_SAVE.SUCCESS, tenant),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_DETAIL_SAVE.FAILURE, error),
}

// Tenant Detail Associate Space
export const tenantDetailAssociate: RequestActionFunctions = {
    request: (id: string, space_ids: string[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.REQUEST, {id, space_ids}),
    success: (spaces: SimpleSpace[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.SUCCESS, spaces),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.FAILURE, error),
}

// Tenant Detail Unassociate Space
export const tenantDetailUnassociate: RequestActionFunctions = {
    request: (id: string, space_ids: string[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.REQUEST, {id, space_ids}),
    success: (space_ids: string[]) => action(ACTION_KEY_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.SUCCESS, space_ids),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.FAILURE, error),
}

// Tenant Detail Add user
export const tenantAddUser: RequestActionFunctions = {
    request: (id: string, username: string) => action(ACTION_KEY_TENANT.TENANT_ADD_USER.REQUEST, {id, username}),
    success: (user: User) => action(ACTION_KEY_TENANT.TENANT_ADD_USER.SUCCESS, user),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_ADD_USER.FAILURE, error),
}

// Tenant User add location
export const tenantUpdateUserLocation: RequestActionFunctions = {
    request: (user: User, space_ids: string[]) => action(ACTION_KEY_TENANT.TENANT_STAFF_ASSOCIATE_SPACE.REQUEST, {user, space_ids}),
    success: (user_id: string, spaces: SimpleSpace) => action(ACTION_KEY_TENANT.TENANT_STAFF_ASSOCIATE_SPACE.SUCCESS, {user_id, spaces}),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_STAFF_ASSOCIATE_SPACE.FAILURE, error),
}

// Tenant Detail Remove user
export const tenantRemoveUser: RequestActionFunctions = {
    request: (id: string, user_id: string[]) => action(ACTION_KEY_TENANT.TENANT_REMOVE_USER.REQUEST, {id, user_id}),
    success: (user_id: string[]) => action(ACTION_KEY_TENANT.TENANT_REMOVE_USER.SUCCESS, user_id),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_REMOVE_USER.FAILURE, error),
}

// Tenant User List
export const tenantUserList: RequestActionFunctions = {
    request: (params?: {
        page_number?: number,
        page_size?: number
        search?: string,
    }) => action(ACTION_KEY_TENANT.TENANT_USER_LISTING.REQUEST, params),
    success: (users: User[], tenant: Tenant) => action(ACTION_KEY_TENANT.TENANT_USER_LISTING.SUCCESS, {users, tenant}),
    failure: (error) => action(ACTION_KEY_TENANT.TENANT_USER_LISTING.FAILURE, error),
}

export const TenantActions = {
    tenantList,
    tenantDetail,
    tenantDetailSave,
    tenantDetailAssociate,
    tenantDetailUnassociate,
    tenantUpdateUserLocation,
    tenantAddUser,
    tenantRemoveUser,
    tenantUserList,
}
