import { SimpleCampus } from '@exaleap/common'
import { Action, ActionType } from '../actions/systemInfoActions'

export type State = {
  campuses: SimpleCampus[]
}

const systemInfo = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ACTION_SET_CAMPUSES:
      return {
        ...state,
        campuses: action.value,
      }
    default:
      return state
  }
}

export default systemInfo
