import React from "react"
import { USER_GROUPS, ACCESS_POLICY } from "@exaleap/common"


const TenantVisitor = ACCESS_POLICY.tenantService.visitorService

const AccessControlRoutes = [
  {
    path: "/app/tenant/visitor/:id",
    component: React.lazy(() => import("./VisitorRequestDetailPage")),
    auth: [TenantVisitor.approvalManagement, TenantVisitor.approval, TenantVisitor.myRequest, TenantVisitor.request],
  },
  {
    path: "/app/tenant/visitor",
    component: React.lazy(() => import("./VisitorRequestList")),
    auth: [TenantVisitor.approvalManagement, TenantVisitor.approval, TenantVisitor.myRequest, TenantVisitor.request],
  },
  {
    path: "/app/access-control",
    component: React.lazy(() => import("./AccessControlList")),
    auth: []
  },

]

export default AccessControlRoutes
