const navigationMenus = [
  {
    name: 'sidebar.platform_management',
    type: 'section',
    icon: 'business',
    link: '/app/platform-mgmt',
    children: [
      {
        name: 'content.data_searching',
        type: 'item',
        icon: 'list',
        link: '/app/platform-mgmt/data',
      },
      {
        name: 'sidebar.iot.devices',
        type: 'item',
        icon: 'router',
        link: '/app/iot/devices',
      },
      {
        name: 'sidebar.iot.device_types',
        icon: 'developer_board',
        type: 'item',
        link: '/app/iot/device_types',
      },
      {
        name: 'sidebar.campus_management',
        icon: 'business',
        type: 'item',
        link: '/app/bms',
      },
      {
        name: 'pa.access_management.user_group_management',
        icon: 'people',
        type: 'item',
        link: '/app/platform-mgmt/user-group-management',
      },
      {
        name: 'sidebar.access_record',
        icon: 'recent_actors',
        type: 'item',
        link: '/app/platform-mgmt/access-records',
      },
      {
        name: 'sidebar.access_permission',
        icon: 'accessibility',
        type: 'item',
        link: '/app/platform-mgmt/access-permission',
        children: [
          {
            name: '2nd level a',
            children: [
              {
                name: '3rd level a',
                type: 'item',
                link: '/app/platform-mgmt/access-permission',
              },
              {
                name: '3rd level b',
                type: 'item',
                link: '/app/platform-mgmt/access-permission',
              },
              {
                name: '3rd level c',
                type: 'item',
                link: '/app/platform-mgmt/access-permission',
              },
            ],
          },
          {
            name: '2nd level b',
            children: [
              {
                name: '3rd level d',
                type: 'item',
                link: '/app/platform-mgmt/access-permission',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: 'sidebar.leasing_management',
    type: 'section',
    icon: 'apartment',
    link: '/app/leasing-mgmt',
    children: [
      {
        name: 'leasing.overview',
        icon: 'home',
        type: 'item',
        link: '/app/leasing-mgmt/overview',
      },
      {
        name: 'content.leasing.tenant_management',
        icon: 'store',
        type: 'item',
        link: '/app/leasing-mgmt/tenants',
      },
      {
        name: 'content.leasing.pmc_management',
        icon: 'assignment',
        type: 'item',
        link: '/app/leasing-mgmt/pmc',
      },
      {
        name: 'content.leasing_analytics',
        icon: 'show_chart',
        type: 'section',
        link: '/app/leasing-mgmt/analytics',
        children: [
          {
            name: 'content.leasing_analytics.member',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/member',
          },
          {
            name: 'content.leasing_analytics.access-control',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/access-control',
          },
          {
            name: 'content.leasing_analytics.work-order',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/work-order',
          },
          {
            name: 'content.leasing_analytics.visitor',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/visitor',
          },
          {
            name: 'content.leasing_analytics.spending-events-and-gift',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/spending-events-and-gift',
          },
          {
            name: 'content.leasing_analytics.butler',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/butler',
          },
        ],
      },
      {
        name: 'leasing.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/leasing-mgmt/staff/',
      },
      {
        name: 'leasing.api_key.title',
        icon: 'vpn_key',
        type: 'item',
        link: '/app/leasing-mgmt/api-keys',
      },
      {
        name: 'leasing.app_config.title',
        icon: 'smartphone',
        type: 'item',
        link: '/app/leasing-mgmt/app-config/landing',
      },
      {
        name: 'leasing.cms.title',
        icon: 'format_list_bulleted',
        type: 'item',
        link: '/app/leasing-mgmt/cms',
      },
      {
        name: 'leasing.merchants.title',
        icon: 'store',
        type: 'item',
        link: '/app/leasing-mgmt/merchants',
      },
      {
        name: 'leasing.gamification.title',
        icon: 'event_available',
        type: 'item',
        link: '/app/leasing-mgmt/gamification',
      },
      {
        name: 'leasing.notifications.title',
        icon: 'send',
        type: 'item',
        link: '/app/leasing-mgmt/notifications',
      },
      {
        name: 'leasing.merchant.v2.title',
        icon: 'store',
        type: 'item',
        link: '/app/leasing-mgmt/merchant/v2',
      },
      {
        name: 'leasing.content-mgmt.settings.v2.merchant-type.title',
        type: 'item',
        icon: 'design_services',
        link: '/app/leasing-mgmt/merchant-type/v2',
      },
      {
        name: 'leasing.ht-app-common-functions.title',
        icon: 'functions',
        type: 'section',
        link: '/app/leasing-mgmt/ht/app-common-functions',
        children: [{
          name: 'leasing.ht-app-common-functions.privacy_policy.title',
          icon: 'lock',
          type: 'item',
          link: '/app/leasing-mgmt/ht/app-common-functions/privacy-policy'}],
      },
      {
        name: 'leasing.ht-member_and_point.title',
        icon: 'account_box',
        type: 'section',
        link: '/app/leasing-mgmt/ht/member_and_point',
        children: [
          {
            name: 'leasing.ht-member.title',
            icon: 'badge',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member_and_point/member',
          },
          {
            name: 'leasing.ht-spending-transaction.title',
            icon: 'receipt_long',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member_and_point/spending-transaction',
          },
          {
            name: 'leasing.ht-point-transaction.title',
            icon: 'list-bulleted',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member_and_point/point-transaction',
          },
          {
            name: 'leasing.ht-member-points-qr-code',
            icon: 'qr_code',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member_and_point/qr-code',
          },
          {
            name: 'leasing.ht-invitation.title',
            // icon: 'handshake',
            pathD:
              'M16.48 10.41c-.39.39-1.04.39-1.43 0l-4.47-4.46-7.05 7.04-.66-.63c-1.17-1.17-1.17-3.07 0-4.24l4.24-4.24c1.17-1.17 3.07-1.17 4.24 0L16.48 9c.39.39.39 1.02 0 1.41zm.7-2.12c.78.78.78 2.05 0 2.83-1.27 1.27-2.61.22-2.83 0l-3.76-3.76-5.57 5.57c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.42 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.42 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l8.32-8.34c1.17-1.17 1.17-3.07 0-4.24l-4.24-4.24c-1.15-1.15-3.01-1.17-4.18-.06l4.47 4.47z',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member_and_point/invitation',
          },
          {
            name: 'leasing.ht-member-point.settings.title',
            icon: 'design_services',
            type: 'section',
            link: '/app/leasing-mgmt/ht/member_and_point/settings',
            children: [
              {
                name: 'leasing.ht-member-point.settings.invitation_rule.title',
                type: 'item',
                link: '/app/leasing-mgmt/ht/member_and_point/settings/invitation-rule',
              },
              {
                name: 'leasing.ht-spending-transaction.deny-reason.title',
                type: 'item',
                link: '/app/leasing-mgmt/ht/member_and_point/settings/spending-transaction-deny-reason',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: 'sidebar.property_management',
    type: 'section',
    icon: 'assignment',
    link: '/app/property-mgmt',
    children: [
      {
        name: 'pmc_management.overview',
        icon: 'home',
        type: 'item',
        link: '/app/property-mgmt/overview',
      },
      {
        name: 'content.pmc.tenant_management',
        icon: 'store',
        type: 'item',
        link: '/app/property-mgmt/tenants',
      },
      {
        name: 'sidebar.property.facility_mgt',
        icon: 'fitness_center',
        type: 'item',
        link: '/app/property-mgmt/facility',
      },
      {
        name: 'pmc_management.shuttle-bus',
        icon: 'airport_shuttle',
        type: 'item',
        link: '/app/property-mgmt/shuttle-bus',
      },
      {
        name: 'sidebar.property.transaction_record',
        icon: 'receipt_sharp',
        type: 'item',
        link: '/app/property-mgmt/transaction-records',
      },
      {
        name: 'sidebar.property.work_order',
        icon: 'construction',
        type: 'item',
        link: '/app/property-mgmt/work-orders',
      },
      {
        name: 'sidebar.property.value_added',
        icon: 'room_service',
        type: 'item',
        link: '/app/property-mgmt/value-added',
      },
      {
        name: 'sidebar.property.visitor_records',
        icon: 'person',
        type: 'item',
        link: '/app/property-mgmt/visitors',
      },
      {
        name: 'pmc_management.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/property-mgmt/staff/',
      },
      {
        name: 'pmc_management.gamification.title',
        icon: 'event_available',
        type: 'item',
        link: '/app/property-mgmt/gamification',
      },
      {
        name: 'pmc_management.content-mgmt.title',
        icon: 'source',
        type: 'section',
        link: '/app/property-mgmt/content-mgmt',
        children: [
          {
            name: 'pmc_management.merchant.title',
            icon: 'store',
            type: 'item',
            link: '/app/property-mgmt/content-mgmt/merchant',
          },
        ],
      },

      // {
      //     name: 'sidebar.visitor_management',
      //     icon: 'supervised_user_circle',
      //     type: 'item',
      //     link: '/app/property-mgmt/visitor',
      // },
    ],
  },

  {
    name: 'sidebar.tenant_services',
    type: 'section',
    icon: 'store',
    link: '/app/tenant', //
    children: [
      {
        name: 'tenant.overview.header',
        type: 'item',
        icon: 'home',
        link: '/app/tenant/overview',
      },
      {
        name: 'tenant.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/tenant/staff/',
      },

      // {
      //     name: 'sidebar.visitor_management',
      //     icon: 'supervised_user_circle',
      //     type: 'item',
      //     link: '/app/tenant/visitor', // !!! missing
      // },
    ],
  },
]

export default navigationMenus
