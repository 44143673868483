import { User, TokenResult, AuthUser,  } from '../../../schema'
import axios, { AxiosInstance } from "axios"
import APIRestfulProvider, { APIHTTPResponse } from "../../restful-provider"

/**
 * IAM Module operations
 */
interface IAMEndpoints {
    getUsers: () => Promise<User[]>,
    
}


/**
 * Restful endpoints for IAM module
 */
class IAMRestful implements IAMEndpoints {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    getUsers = () => this.client.get<APIHTTPResponse<User>>('/user').then(d => d.data.result)
    
}

export default IAMRestful