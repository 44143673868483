import { Tenant, SimpleBase, SimpleCampus, SimpleBuilding, SimpleFloor, SimpleSpace } from '../../../schema'
import { Action, ACTION_KEY_TENANT } from '../../actions'

interface TenantState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,

    tenants: Tenant[],
    tenantDetail: Tenant,
    detailAvailableSpaces: SimpleSpace[],

}

const initialState: TenantState = {
    success: false,
    loading: false,
    error: null,

    tenants: null,
    tenantDetail: null,
    detailAvailableSpaces: [],
}

const TenantReducer = function (state = initialState, action: Action): TenantState {
    switch (action.type) {
        case ACTION_KEY_TENANT.TENANT_LISTING.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                tenants: action.payload,
            }
        }

        case ACTION_KEY_TENANT.TENANT_DETAIL.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                tenantDetail: action.payload.tenant,
                detailAvailableSpaces: action.payload.spaces
            }
        }

        case ACTION_KEY_TENANT.TENANT_DETAIL_SAVE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false
            }
        }


        case ACTION_KEY_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                tenantDetail: {...state.tenantDetail, locations: [...state.tenantDetail.locations, ...action.payload]},
            }
        }

        case ACTION_KEY_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.SUCCESS: {
            const remove_ids: string[] = action.payload
            return {
                ...state,
                success: true,
                loading: false,
                // tenantDetail: {...state.tenantDetail, spaces: state.tenantDetail.spaces.filter(s => !remove_ids.includes(s.id))},
            }
        }
        default: {
            return state
        }
    }
}

export default TenantReducer
