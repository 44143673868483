import { all, take, call, put, takeEvery } from 'redux-saga/effects'
import { Action, ACTION_KEY_TENANT, TenantActions, NotificationActionFunctions } from '../actions'
import { apiService } from '../../..'

function* assoicateUser(action: Action) {
    const {id, username} = action.payload

    try {
        const result = yield call(apiService.tenant.user.addUser, username)
        if (result.length === 0) {
            throw new Error('Failed Associate User')
        }

        const user = yield call(apiService.tenant.user.getUsersInUserGroup, result[0].id)

        if (user.length > 0) {
            yield put(TenantActions.tenantAddUser.success(user[0]))
        }
    } catch (error) {
        console.warn('[SAGA] assoicateUser', error)
        yield put(TenantActions.tenantAddUser.failure(error))
        yield put(NotificationActionFunctions.enqueue({
            message: error,
            type: "error"
        }))
    }
}


function* unassoicateUser(action: Action) {
    const {id, user_id} = action.payload

    try {
        const result = yield call(apiService.tenant.user.dropUser, user_id)
        if (result.length === 0) {
            throw new Error('Failed ')
        }
        yield put(TenantActions.tenantRemoveUser.success(user_id))

    } catch (error) {
        console.log('[SAGA] unassoicateUser', error)
        yield put(TenantActions.tenantRemoveUser.failure(error))

        yield put(NotificationActionFunctions.enqueue({
            message: error,
            type: "error"
        }))
    }
}

function* requestUsers(action: Action) {
    console.warn('[SAGA] tenant.requestUsers')

    try {
        const [users, tenant] = yield all([call(apiService.tenant.user.users),
            call(apiService.tenant.general.info)])

        yield put(TenantActions.tenantUserList.success(users, tenant[0]))
    } catch (error) {
        console.warn('[SAGA] tenant.requestUsers error', error)
        yield put(TenantActions.tenantUserList.failure(error))
        yield put(NotificationActionFunctions.enqueue({
            message: error,
            type: "error"
        }))
    }
}

export const staffSagas = [

    takeEvery(ACTION_KEY_TENANT.TENANT_ADD_USER.REQUEST, assoicateUser),
    takeEvery(ACTION_KEY_TENANT.TENANT_REMOVE_USER.REQUEST, unassoicateUser),
    takeEvery(ACTION_KEY_TENANT.TENANT_USER_LISTING.REQUEST, requestUsers),
]
