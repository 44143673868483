import { call, put, takeEvery, all } from 'redux-saga/effects'
import { Action, NotificationActionFunctions, ACTION_KEY_ACCESS_CONTROL, AccessControlActions } from '../actions'
import { apiService } from '../../..'

function* requestAccessControlRecords(action: Action) {
    console.warn('[SAGA] requestAccessControlRecords')

    // try {
    //     const campuses = yield call(apiService.bms.getCampuses)

    //     const id = action.payload || campuses[0].id
    //     const details = yield call(apiService.bms.aggregateCampus, id, true, true)

    //     yield put(AccessControlActions.accessControlRecordList.success([]))
    // } catch (error) {
    //     console.warn('[SAGA] requestAccessControlRecords error', error)
    //     yield put(AccessControlActions.accessControlRecordList.failure(error))
    //     yield put(
    //         NotificationActionFunctions.enqueue({
    //             message: error,
    //             type: 'error',
    //         }),
    //     )
    // }
}

export const accessControlSagas = [
    takeEvery(ACTION_KEY_ACCESS_CONTROL.ACCESS_CONTROL_TRANSCATIONS.REQUEST, requestAccessControlRecords),
]
