import { omit } from 'ramda'
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { BaseUser, PropertyManagementCompany, LocalizationObject, UserGroup } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class PropertyMgmtComapnyRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/leasing-management/properties-management-company-management'

    get = (id: string) =>
        this.client
            .get<APIHTTPResponse<PropertyManagementCompany>>(`${this.subRoute}/get-companies`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    getCompanyAdmins = (id: string) =>
        this.client
            .get<APIHTTPResponse<BaseUser>>(`${this.subRoute}/get-user-admins-in-company`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    list = (occupy?: boolean) =>
        this.client
            .get<APIHTTPResponse<PropertyManagementCompany>>(`${this.subRoute}/get-companies`, {
                params: occupy !== undefined ? { occupy } : {},
            })
            .then(parseResult)
            .catch(throwError)

    createCompany = (tenant: PropertyManagementCompany & LocalizationObject) =>
        this.client
            .post<APIHTTPResponse<PropertyManagementCompany>>(`${this.subRoute}/create-company`, omit(['display_name'], tenant))
            .then(parseResult)
            .catch(throwError)

    listByCampus = (campus_id: string) =>
        this.client
            .get<APIHTTPResponse<PropertyManagementCompany>>(`${this.subRoute}/get-company-in-campus`, {
                params: { campus_id },
            })
            .then(parseResult)
            .catch(throwError)

    getUserGroupInCompany = (company_id: string, user_group_id?: string) =>
        this.client
            .get<APIHTTPResponse<UserGroup>>(`${this.subRoute}/get-user-groups-in-company`, {
                params: {
                    company_id,
                    user_group_id,
                },
            })
            .then(parseResult)
            .catch(throwError)



    listUnassociateCampus = () =>
        this.client
            .get<APIHTTPResponse<any>>(`${this.subRoute}/get-unassociated-campuses`)
            .then(parseResult)
            .catch(throwError)

    associateCampus = (company_id: string, campus_id: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/associate-company-with-campus`, {
                company_id,
                campus_id,
            })
            .then(parseResult)
            .catch(throwError)

    unassociateCampus = (company_id: string, campus_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/unassociate-company-with-campus`, {
                params: { company_id, campus_id },
            })
            .then(parseResult)
            .catch(throwError)

    setUserAsAdmin = (company_id: string, username: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/set-user-as-company-admin`, {
                company_id,
                username,
            })
            .then(parseResult)
            .catch(throwError)
}

export default PropertyMgmtComapnyRestful
