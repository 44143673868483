import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  PaginatedResponse,
  parseResults,
  parseResult,
} from '../../restful-provider'
import {
  MerchantsRequired,
  Merchants,
  MerchantsNew,
  MerchantTypeList,
  MerchantsSubTypeNew,
  MerchantsDetail,
  SimpleMerchant,
  LocalizationObject,
} from '@exaleap/common'

/**
 * Restful endpoints for Visitor System module
 */
class HTMemberServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/merchant-management'

  getMerchantsByPage = (campus_id: string, params: MerchantsRequired) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search'], params))
    const location_types = campus_id !== 'unoccupied' ? ['IN_CAMPUS'] : ['OTHER_LOCATION']
    const campus_ids = campus_id && campus_id !== 'unoccupied' ? { campus_ids: [campus_id] } : {}
    return this.client
      .post<PaginatedResponse<Merchants>>(`${this.subRoute}/merchants/leasing/list`, {
        ...campus_ids,
        ...args,
        merchant_type_ids:
          params?.merchant_type_id && params?.merchant_type_id !== 'all' ? [params?.merchant_type_id] : [],
        merchant_subtype_ids: params?.merchant_subtype_ids ? [params?.merchant_subtype_ids] : [],
        location_types,
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  getMerchantsByPagePmc = (campus_id: string, params: MerchantsRequired) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search'], params))
    const location_types = campus_id !== 'unoccupied' ? ['IN_CAMPUS'] : ['OTHER_LOCATION']
    const campus_ids = campus_id && campus_id !== 'unoccupied' ? { campus_ids: [campus_id] } : {}
    return this.client
      .post<PaginatedResponse<Merchants>>(`${this.subRoute}/merchants/pmc/list`, {
        ...campus_ids,
        ...args,
        merchant_type_ids:
          params?.merchant_type_id && params?.merchant_type_id !== 'all' ? [params?.merchant_type_id] : [],
        merchant_subtype_ids: params?.merchant_subtype_ids ? [params?.merchant_subtype_ids] : [],
        location_types,
      })
      .then((d) => d.data)
      .catch(throwError)
      .catch(throwError)
  }

  getMerchantDetailById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantsDetail>>(`${this.subRoute}/merchants/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getMerchantTypes = () => {
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRoute}/merchant/type`)
      .then(parseResults)
      .catch(throwError)
  }

  getMerchantSubTypes = (type: string) => {
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRoute}/merchant/type/subtype/${type}`)
      .then(parseResults)
      .catch(throwError)
  }

  addMerchant = (params: MerchantsNew) => {
    return this.client
      .post<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchants/leasing`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateMerchant = (id: string, params: MerchantsNew) => {
    return this.client
      .put<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchants/leasing/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  sendNotificationById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchants/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getMerchantSubTypesList = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by'], params))
    return this.client
      .post<PaginatedResponse<MerchantTypeList>>(`${this.subRoute}/merchant/subtype/leasing/list`, {
        ...args,
        merchant_type_ids: [],
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  addMerchantSubType = (params) => {
    return this.client
      .post<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchant/subtype/leasing`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateMerchantSubType = (id, params) => {
    return this.client
      .put<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchant/subtype/leasing/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/file/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  // 组务管理>帖子管理>新增资产 中的发行单位
  getMerchantsLeasing = () =>
    this.client
      .post<APIHTTPResponse<SimpleMerchant & LocalizationObject>>(`${this.subRoute}/merchants/leasing/search`, {})
      .then(parseResults)
      .catch(throwError)
  
   // merchants/leasing/search
  getMerchants = (campus_ids) => {
    const args = {
      campus_ids: campus_ids ? [campus_ids] : []
    }
    return this.client
      .post<APIHTTPResponse<SimpleMerchant & LocalizationObject>>(`${this.subRoute}/merchants/leasing/search`, args)
      .then(parseResults)
      .catch(throwError)
   
  }
}


export default HTMemberServices
