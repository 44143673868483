import { ACCESS_POLICY } from '@exaleap/common'
import React from 'react'

const HTAppFunctionsRoutes = [
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/privacy-policy',
    component: React.lazy(() => import('./PrivacyPolicy')),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.privacyPolicy.fullAccess],
  },]

export default HTAppFunctionsRoutes