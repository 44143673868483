import { SimpleBuilding, SimpleMerchant } from '@exaleap/common'
import { Action, ActionType } from '../actions/leasingActions'



export type State = {
  buildings: SimpleBuilding[],
  merchants: SimpleMerchant[],
}

const leasing = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ACTION_SET_BUILDINGS:
      return {
        ...state,
        buildings: action.value,
      }
    case ActionType.ACTION_SET_MERCHANTS:
      return {
        ...state,
        merchants: action.value,
      }
    default:
      return state
  }
}

export default leasing
