import React, { createContext, useContext } from 'react'
import useLeasing, { LeasingState } from '../../state/useLeasing'
import usePMC, { PMCState } from '../../state/usePMC'
import useSystemInfo, { SystemInfoState } from '../../state/useSystemInfo'

const StateContext = createContext<{ leasing: LeasingState; pmc: PMCState; systemInfo: SystemInfoState }>(undefined)

const StateProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const stateHooks = {
    leasing: useLeasing(),
    pmc: usePMC(),
    systemInfo: useSystemInfo(),
  }
  return <StateContext.Provider value={stateHooks}>{children}</StateContext.Provider>
}

export const useStateHooks = () => {
  return useContext(StateContext)
}

export default StateProvider
