import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { ListRequest, User } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class CustomUserManagementRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/platform-management/custom-user-group-management'

  getUserGroups = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${this.subRoute}/get-user-groups`, {
        params,
      })
      .then(parseResult)
      .catch(throwError)

  getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${this.subRoute}/get-users-in-user-group`, {
        params: { user_group_id, ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  addUserToUserGroup = (area_code: string, user_group_id: string, phone_number: string) =>
    this.client
      .post<APIHTTPResponse<User>>(`${this.subRoute}/add-user-to-user-group`, {
        area_code,
        user_group_id,
        phone_number,
      })
      .then(parseResult)
      .catch(throwError)

  dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(`${this.subRoute}/drop-user-from-user-group`, {
        params: { user_id, user_group_id },
      })
      .then(parseResult)
      .catch(throwError)
}

export default CustomUserManagementRestful
