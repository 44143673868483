import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
// import UserInfo from 'components/UserInfo'
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from '@exaleap/common'

import SideBarContent from './SideBarContent'
import { hrdpActions } from '@exaleap/common'

const useStyles = makeStyles(() => ({
  temporaryType: {
    '& .MuiBackdrop-root': {
      background: 'none',
    },
  },
}))

const SideBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(({ settings }) => settings)

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(hrdpActions.updateWindowWidth(window.innerWidth))
      if (window.innerWidth < 1200 && drawerType.includes(FIXED_DRAWER)) {
        dispatch(hrdpActions.toggleCollapsedNav(false))
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (width < 1200) {
      dispatch(hrdpActions.toggleCollapsedNav(false))
    }
  }, [])

  const onToggleCollapsedNav = e => {
    const val = !navCollapsed
    if (!val && drawerType.includes(COLLAPSED_DRAWER)) {
      dispatch(hrdpActions.setDrawerType(FIXED_DRAWER))
      dispatch(hrdpActions.toggleCollapsedNav(val))
    }
    if (drawerType.includes(FIXED_DRAWER)) {
      if (width > 1200) {
        dispatch(hrdpActions.setDrawerType(COLLAPSED_DRAWER))
      } else {
        dispatch(hrdpActions.toggleCollapsedNav(val))
      }
    }
    
  }

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex'
  let type = 'persistent'
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
    type = 'temporary'
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = ''
    type = 'temporary'
  }

  const handleMouseLeave = () => {
    const e = window.event
    if (navCollapsed && (e.clientX >= 260 || e.clientX < 0 || e.clientY >= window.innerHeight ||e.clientY<0)) {
      dispatch(hrdpActions.toggleCollapsedNav(false))
    }
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className={`app-sidebar-content ${type === 'temporary' ? classes.temporaryType : ''}`}
        variant={type}
        open={type === 'temporary' ? navCollapsed : !navCollapsed}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav',
        }}
        ModalProps={{
          keepMounted:true
        }}
      >
        {/* <Toolbar variant="dense" style={{ backgroundColor: '#27AE60', color: 'white' }}>
                    恒基智能平台
                </Toolbar> */}
        {/* <CampusSelection /> */}
        {/* <UserInfo/> */}
        <SideBarContent
         handleMouseLeave={handleMouseLeave}
          navCollapsed={navCollapsed}
          onToggleCollapsedNav={onToggleCollapsedNav}
          width={width}
        />
      </Drawer>
    </div>
  )
}

export default withRouter(SideBar)
