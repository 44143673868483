import React, { useState } from 'react'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
// import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { COLLAPSED_DRAWER, FIXED_DRAWER } from '@exaleap/common'
import IntlMessages from 'util/IntlMessages'
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher'
import { hrdpActions } from '@exaleap/common'
// import { classNames } from "react-select/src/utils"
import { createStyles, makeStyles, Box } from '@material-ui/core'
import UserInfo from 'components/UserInfo'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'

const drawerWidth = 260

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#219653',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    menuLabel: {
      height: '32px',
      lineHeight: '32px',
      padding: '0 10px',
      border: `1px solid #fff`,
      borderRadius: '5px',
      cursor: 'pointer',
      '& .MuiIconButton-root': {
        padding: 0,
        marginTop: '-4px',
        marginRight: '4px',
        color: '#fff',
      },
      '& .menuLabel': {
        display: 'inline-block',
        letterSpacing: '1px',
      },
    },
  }),
)

const getHeadMenuLabel = () => {
  const windowPathname = window.location.pathname
  let headMenuLabel = 'sidebar.platform_management'
  if (windowPathname.indexOf('/app/leasing-mgmt') > -1) {
    headMenuLabel = 'sidebar.leasing_management'
  }
  if (windowPathname.indexOf('/app/property-mgmt') > -1) {
    headMenuLabel = 'sidebar.property_management'
  }
  if (windowPathname.indexOf('/app/tenant') > -1) {
    headMenuLabel = 'sidebar.tenant_services'
  }
  return headMenuLabel
}

const Index = props => {
  const { navCollapsed } = props
  const dispatch = useDispatch()
  const { drawerType, width } = useSelector(({ settings }) => settings)
  const [langSwitcher, setLangSwitcher] = useState(false)
  const [mailNotification, setMailNotification] = useState(false)
  // const [appNotification, setAppNotification] = useState(false)
  const [searchBox, setSearchBox] = useState(false)
  const [apps, setApps] = useState(false)
  const [userInfo, setUserInfo] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [anchorEl, setAnchorEl] = useState(undefined)

  // const onAppNotificationSelect = () => {
  //   setAppNotification(!appNotification)
  // }

  // const onMailNotificationSelect = () => {
  //   setMailNotification(!mailNotification)
  // }
  // const onLangSwitcherSelect = event => {
  //   setLangSwitcher(!langSwitcher)
  //   setAnchorEl(event.target.value)
  // }

  // const onSearchBoxSelect = () => {
  //   setSearchBox(!searchBox)
  // }
  // const onAppsSelect = () => {
  //   setApps(!apps)
  // }
  // const onUserInfoSelect = () => {
  //   setUserInfo(!userInfo)
  // }

  const handleRequestClose = () => {
    setAnchorEl(null)

    setSearchBox(false)
    setLangSwitcher(false)
    setUserInfo(false)
    setMailNotification(false)
    setSearchBox(false)
    setApps(false)
  }

  const onToggleCollapsedNav = e => {
    const val = !navCollapsed
    if (!(drawerType.includes(COLLAPSED_DRAWER) && width > 1200 && val)){
      dispatch(hrdpActions.toggleCollapsedNav(val))
    }
    if (width > 1200 && drawerType.includes(COLLAPSED_DRAWER)) {
      dispatch(hrdpActions.setDrawerType(FIXED_DRAWER))
    }
  }

  // const updateSearchText = evt => {
  //   setSearchText(evt.target.value)
  // }

  const onSwitchLanguage = lang => {
    dispatch(hrdpActions.switchLanguage(lang))
  }

  const classes = useStyles()

  const drawerStyle =
    drawerType.includes(FIXED_DRAWER) && !navCollapsed
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && navCollapsed)
      ? 'd-block'
      : 'd-none'
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerType.includes(FIXED_DRAWER) && !navCollapsed && width > 1200,
      })}
      elevation={0}
    >
      {/* <AppBar position="fixed" className='app-main-header' > */}
      <Toolbar className="app-toolbar" disableGutters={false} variant="dense">
        <Box onClick={onToggleCollapsedNav} className={`mr-3 ${classes.menuLabel} ${drawerStyle}`}>
          <IconButton aria-label="Menu">
            <MenuIcon />
          </IconButton>
          <span className="menuLabel">
            <IntlMessages id={getHeadMenuLabel()} />
          </span>
        </Box>
        <div style={{ letterSpacing: '1px' }}>
          <IntlMessages id="header.hrdp_name" />
        </div>
        {/* <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img src={require("assets/images/logo.png")} alt="Jambo" title="Jambo"/>
          </Link>
 */}

        <ul className="header-notifications list-inline ml-auto">
          {/* <li className="list-inline-item app-tour">
                        <IconButton
                            edge="end"
                            aria-label="Remove space"
                            style={{ width: 40, height: 40, marginRight: 4, color: 'white' }}
                            onClick={event => {
                                setAnchorEl(event.currentTarget)
                            }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </li> */}

          <li className="list-inline-item app-tour">
            <UserInfo variant="dense" />
          </li>
        </ul>

        <LanguageSwitcher
          anchorEl={anchorEl}
          switchLanguage={onSwitchLanguage}
          handleRequestClose={handleRequestClose}
        />

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Index)
