import { ACCESS_POLICY } from '@exaleap/common'
import React from 'react'
import HTMemberAndPointRoutes from './HTMemberAndPoints/index'
import HTAppFunctionsRoutes from './HTAppFuntions/index'

const LeasingPortalRoutes = [
  {
    path: '/app/leasing-mgmt/tenants/:id',
    component: React.lazy(() => import('./TenantDetail')),
    auth: [
      ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
      ACCESS_POLICY.leasingManagement.tenantManagement.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/tenants',
    component: React.lazy(() => import('./TenantList')),
    auth: [
      ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
      ACCESS_POLICY.leasingManagement.tenantManagement.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/pmc/:id',
    component: React.lazy(() => import('./PropertyMgmtCompanyDetail')),
    auth: [
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.fullAccess,
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/pmc',
    component: React.lazy(() => import('./PropertyMgmtCompanyList')),
    auth: [
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.fullAccess,
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/staff',
    component: React.lazy(() => import('./LeasingStaff')),
    auth: [ACCESS_POLICY.leasingManagement.userManagement.fullAccess],
  },
  // {
  //     path: '/app/leasing-mgmt/staff/',
  //     exact: true,
  //     component: () => <Redirect to="/app/leasing-mgmt/staff/user" />,
  // },
  //
  {
    path: '/app/leasing-mgmt/api-keys',
    component: React.lazy(() => import('./APIKeyList')),
    auth: [ACCESS_POLICY.leasingManagement.apiKeyManagement.fullAccess],
  },
  // {
  //    // path: '/app/leasing-mgmt/analytics',
  //    // component: React.lazy(() => import('./LeasingAnalytics')),
  // },
  {
    path: '/app/leasing-mgmt/analytics/member',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.member.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/work-order',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.workOrder.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/visitor',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.visitor.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/access-control',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.accessControl.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/spending-events-and-gift',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.spendingEventsAndGift.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/butler',
    component: React.lazy(() => import('./LeasingAnalytics')),
    auth: [ACCESS_POLICY.analytics.butler.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/cms/:id',
    component: React.lazy(() => import('./Content')),
    auth: [ACCESS_POLICY.communityService.cmsService.admin, ACCESS_POLICY.communityService.cmsService.editor],
  },
  {
    path: '/app/leasing-mgmt/cms',
    component: React.lazy(() => import('./ContentManagement')),
    auth: [ACCESS_POLICY.communityService.cmsService.admin, ACCESS_POLICY.communityService.cmsService.editor],
  },
  {
    path: '/app/leasing-mgmt/merchants',
    component: React.lazy(() => import('./MerchantsManagement')),
    auth: [ACCESS_POLICY.communityService.cmsService.admin],
  },
  {
    path: '/app/leasing-mgmt/app-config/landing',
    component: React.lazy(() => import('./AppConfig')),
    auth: [ACCESS_POLICY.leasingManagement.appConfigManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/notifications',
    component: React.lazy(() => import('./Notifications')),
    auth: [ACCESS_POLICY.communityService.cmsService.admin],
  },
  {
    path: '/app/leasing-mgmt/gamification/:gameId',
    component: React.lazy(() => import('./Game/DetailPage')),
    auth: [ACCESS_POLICY.leasingManagement.gameManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/gamification',
    component: React.lazy(() => import('./Game/Management')),
    auth: [ACCESS_POLICY.leasingManagement.gameManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/overview',
    component: React.lazy(() => import('./Overview')),
    auth: [ACCESS_POLICY.leasingManagement.generalService.basic],
  },
  {
    path: '/app/leasing-mgmt/merchant/v2',
    component: React.lazy(() => import('./MerchantsV2')),
    auth: [ACCESS_POLICY.leasingManagement.merchant.merchantManagement],
  },
  {
    path: '/app/leasing-mgmt/merchant-type/v2',
    component: React.lazy(() => import('./MerchantTypesV2')),
    auth: [ACCESS_POLICY.leasingManagement.merchant.merchantSubTypeManagement],
  },
  ...HTMemberAndPointRoutes,
  ...HTAppFunctionsRoutes,
  {
    path: '/app/leasing-mgmt',
    component: React.lazy(() => import('./DefaultPage')),
  },
]

export default LeasingPortalRoutes
