import React, { Suspense, useState, useEffect, useRef, useMemo } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { CircularProgress } from '@material-ui/core'
import { apiService, jwtAuthService, AuthUser, AuthActions, AuthChecking } from '@exaleap/common'
import { useDispatch } from 'react-redux'
import { useSelector } from 'state/root-store'
import * as R from 'ramda'

import getNavigationByUser, { SET_USER_NAVIGATION } from './NavigationHelper'

const LoadingComponent = () => {
  return (
    <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
      <CircularProgress />
    </div>
  )
}

const isPermissionAllowed = (pathname: string, routes: any[], user: AuthUser) => {
  const matched = matchRoutes(routes, pathname)[0]
  const authenticated = matched ? AuthChecking(user, matched.route.auth) : false
  console.warn('[AUTHORIZATION]', user.policies, matched && matched.route.auth, pathname)
  return authenticated
}

const AppAuthContent = props => {
  const dispatch = useDispatch()
  const { authUser, token, profile, regionCd } = useSelector(({ auth }) => auth)
  const { location, route } = props
  const [loading, setLoading] = useState(true)
  const regionVersion = useRef(regionCd)
  regionVersion.current = useMemo(() => (R.includes('henderson', window?.location?.hostname ?? '') ? 'cn' : 'hk'), [])

  if (token) {
    jwtAuthService.setSession(token)
  }

  const access_token = token ? token.access_token : null
  const refresh_token = token ? token.refresh_token : null

  const authHandler = async () => {
    console.warn('JWT checking: ', token)
    if (!jwtAuthService.assertJWT(access_token)) {
      console.warn('JWT expired. perform refresh token now: ', refresh_token)
      dispatch(AuthActions.refreshToken.request(refresh_token))
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log('[AUTHORIZATION] First time loading App Auth Content')

    // set region of auth restful
    apiService.switchRegionCd(regionCd)

    if (authUser) {
      // get profile to sync system language
      dispatch(AuthActions.requestProfile.request())
    }
  }, [])

  useEffect(() => {
    if (authUser || profile) {
      const nav = getNavigationByUser(route.routes, profile || authUser)
      dispatch({ type: SET_USER_NAVIGATION, payload: nav })
    }
  }, [authUser, profile])

  useEffect(() => {
    console.log('[AUTHORIZATION] useEffect monitoring pathname & access token', props.location.pathname, access_token)
    if (access_token) {
      authHandler()
    } else {
      setLoading(false)
    }
  }, [props.location.pathname, access_token])

  if (loading) return <LoadingComponent />

  if (!authUser) {
    console.warn('!authUser', authUser)
    return (
      <Redirect
        to={{
          pathname: `/${regionVersion.current}/signin`,
          state: { from: props.location },
        }}
      />
    )
  }

  if (!isPermissionAllowed(location.pathname, route.routes, profile ?? authUser)) {
    console.log('[AUTHORIZATION] Permission not allowed', location.pathname, route.routes, authUser)
    return <Redirect to="/error/401" />
  }

  return (
    <Suspense fallback={<LoadingComponent />}>
      {/* sub routes */}
      {renderRoutes(route.routes)}
    </Suspense>
  )
}

export default withRouter(AppAuthContent)
