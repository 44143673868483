import { SimpleBase, LocalizationObject, LocalizationField, ListRequest } from './base'

export enum EntityType {
  GameCoupon = 'game.coupon',
  Coupon = 'comm.coupon',
  Ticket = 'comm.ticket',
}

export enum VoucherState {
  Claimed = 'claimed',
  Pending = 'pending',
  Used = 'used',
  Expired = 'expired',
  Voided = 'voided',
}

export interface Asset extends SimpleBase, LocalizationObject {
  asset_id: string
  quota: number
  description: string
  entity_code: string
  entity_type: EntityType
  expiry_date: string
  merchant_id: string
  merchant_url: string
  picture_id?: string
  picture_url?: string
  remarks: string
  terms: string
}

export interface PostSession extends SimpleBase {
  address?: string
  asset_id: string
  amount: number
  end_time: string
  low_amount: number
  remain_amount: number
  start_time: string
  title: string
}

export type AssetStock = {
  amount: number
  asset_id: string
  low_amount: number
}

export type FormAsset = Pick<Asset, 'entity_type' | 'expiry_date' | 'merchant_id' | 'quota' | 'remarks'> & {
  localization: {
    description: LocalizationField
    terms: LocalizationField
    display_name: LocalizationField
  }
  picture: {
    id: string
    url: string
  }
  low_amount?: number
}

export interface Merchant extends LocalizationObject {
  id: string
  display_name: string
  introduction?: string
  logo?: {
    url: string
    id: string
  }
}

export interface Voucher extends SimpleBase {
  asset_id: string
  get_time?: string
  claim_user_id?: string
  claim_first_name?: string
  claim_last_name?: string
  claim_tenant_display_name: string
  use_time: string
  state: VoucherState
  item_number: string
}
