/* eslint-disable react/jsx-no-target-blank */
import { AREA_CODES, AuthActions, hrdpActions, LANGUAGE_DATA_LIST, apiService } from '@exaleap/common'
import {
  FormControl,
  MenuItem,
  Select,
  withStyles,
  Theme,
  styled,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { injectIntl } from 'react-intl'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import IntlMessages from 'util/IntlMessages'
import * as R from 'ramda'
import { getLanguageData } from 'util/localeHelper'
import { getThemeColorByColorTxt } from 'util/styleHelper'
import LoginForm from 'components/Login/LoginForm'
import { getPhoneMaxLength } from 'util/stringHelper'

const StyledToggleButtonGroup = withStyles((theme: Theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&.Mui-selected': {
      background: 'none',
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      '& .MuiToggleButton-label': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiToggleButton-label': { textTransform: 'none', fontWeight: 'lighter' },
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

const DPFSLegend = styled('legend')(() => ({}))

const Fieldset = styled('fieldset')(({ theme }: any) => ({
  width: 288,
  display: 'flex',
  padding: '0 2px 0 10px',
  borderRadius: '4px',
  border: `2px solid ${getThemeColorByColorTxt('primary.main', theme.palette)}`,
  '& legend': {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '15px',
    fontWeight: 500,
    width: 'auto',
    padding: '0px 2px',
    margin: 0,
    color: getThemeColorByColorTxt('primary.main', theme.palette),
  },
  '&:not(:first-child)': {
    marginTop: '8px',
  },
  '& .MuiInput-underline:before, & .MuiInput-underline:after': {
    display: 'none',
  },
}))

const SignIn = props => {
  const { loader, showMessage, authUser, requestStatus, regionCd, failedError } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const regionVersion = useRef(regionCd)
  regionVersion.current = useMemo(() => (R.includes('cn/signin', window?.location?.pathname ?? '') ? 'CN' : 'HK'), [])
  const [organization_identifier] = useState('henderson_hk')
  const { intl } = props
  // const [email, setEmail] = useState('wfc.tenant.adminsitrator@exaleap.ai')
  const [areaCode, setAreaCode] = useState(AREA_CODES.find(item => item.region === regionVersion.current)?.value)
  const [lang, setLang] = useState<string>(regionVersion.current)
  const [phone, setPhone] = useState('')
  const [isValidPhone, setIsValidPhone] = useState(true)
  // const [rCd, setRCd] = useState<string>(regionVersion.current)
  const [step, setStep] = useState('request-otp')
  const [maxLength, setMaxLength] = useState<number>(11)
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const requestCount = useRef<number>(0)

  const handleChangeLanguage = lang => {
    dispatch(hrdpActions.switchLanguage(getLanguageData(lang)))
    setLang(lang)
  }

  const defaultLocale = regionVersion.current.toLowerCase() === 'cn' ? 'sc' : 'tc'
  useEffect(() => {
    handleChangeLanguage(defaultLocale)
  }, [])

  // 如果是本地环境或者测试环境，可修改地区和区号，否则不行
  // const disabled = !(
  //   R.includes('webportal.dev', window?.location?.hostname ?? '') ||
  //   R.includes('localhost', window?.location?.hostname ?? '')
  // )

  useEffect(() => {
    const length = getPhoneMaxLength(areaCode)
    setMaxLength(length)
  }, [areaCode])

  useEffect(() => {
    if (phone.length > 0) {
      setIsValidPhone(!/([^0-9])+/.test(phone))
    } else {
      setIsValidPhone(true)
    }
  }, [phone])

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hrdpActions.hideMessage())
      }, 100)
    }
    if (authUser !== null) {
      setStep('request-otp')
      props.history.push('/')
    }
  }, [showMessage, authUser, props.history, dispatch])

  useEffect(() => {
    if (!loader) {
      if (requestStatus === 'requested' && requestCount.current > 0) {
        setStep('login')
      }
      if (failedError?.code === 'ERR100173') {
        setOpen(true)
      }
    }
  }, [loader])

  useEffect(() => {
    // set region of auth restful
    apiService.switchRegionCd(regionVersion.current)
  }, [regionVersion.current])

  const handleSendOtp = () => {
    requestCount.current++
    dispatch(hrdpActions.showAuthLoader())
    dispatch(
      AuthActions.requestToken.request({
        areaCode,
        phone,
        organization_identifier,
        regionCd: regionVersion.current,
      }),
    )
  }

  return (
    <div className="app-login-container d-flex flex-column justify-content-between align-items-center ">
      <div
        style={{
          height: 64,
          width: '100%',
          display: 'flex',
          lineHeight: '64px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginLeft: 32, color: '#219653', letterSpacing: 1, fontSize: 18 }}>
          <IntlMessages id="header.hrdp_name" />
        </div>
        <div style={{ marginRight: 32 }}>
          <StyledToggleButtonGroup value={lang}>
            {LANGUAGE_DATA_LIST.map(({ locale }) => (
              <ToggleButton
                key={locale}
                value={locale}
                onClick={() => handleChangeLanguage(locale)}
                style={{ fontSize: 18 }}
              >
                <IntlMessages id={`generic.lang.short.${locale}`} />
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </div>
      </div>

      <div className="app-login-main-content animated slideInUpTiny animation-duration-3">
        {step === 'request-otp' && (
          <div className="app-login-content">
            <div className="app-login-header mb-4 d-flex align-items-baseline">
              <h1 style={{ flex: 1, fontSize: 32 }}>
                <IntlMessages id="appModule.login1" />
              </h1>
            </div>
            <div className="app-login-form">
              <form>
                <Fieldset>
                  <DPFSLegend>
                    <IntlMessages id="appModule.login.phone_number" />
                  </DPFSLegend>
                  <FormControl
                    variant="standard"
                    className="m-1"
                    style={{ display: 'flex', flexDirection: 'row', height: 32, alignItems: 'center' }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      name="area_code"
                      value={areaCode}
                      style={{ width: 70, height: 32 }}
                      margin="dense"
                      onChange={e => setAreaCode(e.target.value as string)}
                      // disabled={disabled}
                    >
                      {AREA_CODES.map(code => (
                        <MenuItem key={code.value} value={code.value}>
                          {code.display_number}
                        </MenuItem>
                      ))}
                    </Select>
                    <Divider orientation="vertical" flexItem style={{ margin: '4px 4px 4px 8px' }} />
                    <TextField
                      onChange={(event: any) => setPhone(event.target.value)}
                      onKeyDown={event => {
                        if (event.keyCode === 13 && !(phone.length === 0 || !isValidPhone)) {
                          handleSendOtp()
                          return
                        }
                        return false
                      }}
                      value={phone}
                      margin="normal"
                      className="m-1"
                      helperText={
                        (!isValidPhone && intl.formatMessage({ id: 'login.phone.format_invalid' })) ||
                        (phone?.length > maxLength && intl.formatMessage({ id: 'login.phone.max_lenth' }))
                      }
                      error={!isValidPhone || phone?.length > maxLength}
                      inputProps={{ maxLength }}
                      autoFocus={true}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setPhone('')
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                      style={{
                        // height: 32,
                        flexGrow: 1,
                        // background:'#f00'
                      }}
                    />
                  </FormControl>
                </Fieldset>
                <Button
                  onClick={() => handleSendOtp()}
                  disabled={phone.length === 0 || !isValidPhone || phone.length > maxLength}
                  color="primary"
                  variant="contained"
                  className="h-40 p-0 align-self-center"
                  style={{
                    width: 288,
                    marginTop: 32,
                    fontSize: 15,
                  }}
                >
                  <IntlMessages id="login.request_sms" />
                </Button>
              </form>
            </div>
          </div>
        )}
        {step === 'login' && (
          <LoginForm setStep={setStep} phone={phone} areaCode={areaCode} resendOtp={handleSendOtp} />
        )}
      </div>

      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          paddingTop: 12,
        }}
      >
        {regionVersion.current === 'CN' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 12,
              marginRight: 12,
              color: '#828282',
              fontSize: 12,
              alignItems: 'center',
            }}
          >
            {/* copyright */}
            <span className="mr-2">
              <a
                href={require('assets/privacy.pdf')}
                target="_blank"
                style={{ color: '#828282', textDecoration: 'underline' }}
              >
                <IntlMessages id="footer.privacy" />
              </a>
            </span>
            |
            <span className="ml-2 mr-2">
              <a
                href={require('assets/copyright.pdf')}
                target="_blank"
                style={{ color: '#828282', textDecoration: 'underline' }}
              >
                <IntlMessages id="footer.copyright" />
              </a>
            </span>
            |
            <span className="ml-2 mr-2">
              <a
                href={require('assets/terms.pdf')}
                target="_blank"
                style={{ color: '#828282', textDecoration: 'underline' }}
              >
                <IntlMessages id="footer.terms" />
              </a>
            </span>
            |
            <span className="ml-2 mr-2">
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                style={{ color: '#828282', textDecoration: 'underline' }}
              >
                ICP: 粤ICP备19125354号
              </a>
            </span>
          </div>
        )}
        <p style={{ fontSize: 12, color: '#828282', marginLeft: 12, marginRight: 12 }}>
          <IntlMessages id="footer.support_browser" />
        </p>

        <p style={{ display: 'none' }}>v2.0.2</p>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: 'dialogPaper',
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IntlMessages id={'login.request.too.often'} />
        </DialogTitle>
        <DialogContent style={{ height: 120, width: 380, color: '#ccc' }}>
          <DialogContentText id="alert-dialog-description">
            <IntlMessages id="api.error.ERR10022" />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #ccc' }}>
          <Button
            onClick={() => {
              setOpen(false)
            }}
            color="primary"
          >
            <IntlMessages id="generic.action.got_it" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default injectIntl(SignIn)
