import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { Tenant } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class TenantRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/properties-management-service/tenant-management'

    get = (id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    list = (occupy?: boolean) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants`, {
                params: occupy !== undefined ? { occupy } : {},
            })
            .then(parseResult)
            .catch(throwError)

    listByCampus = (campus_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants-by-campus-id`, {
                params: { campus_id },
            })
            .then(parseResult)
            .catch(throwError)

    listByBuilding = (building_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/get-tenants-by-building-id`, {
                params: { building_id },
            })
            .then(parseResult)
            .catch(throwError)

    getAdminsInTenant = (tenant_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/user-management/get-tenant-admins-in-tenant`, {
                params: { tenant_id },
            })
            .then(parseResult)
            .catch(throwError)

    getNumberOfStaff = (id: string) =>
        this.client
            .get<APIHTTPResponse<{ number_of_staff: number }>>(`${this.subRoute}/get-number-of-staff-by-tenant-id`, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    getUsersInTenant = (tenant_id: string) =>
        this.client
            .get<APIHTTPResponse<Tenant>>(`${this.subRoute}/user-management/get-users-in-tenant`, {
                params: { tenant_id },
            })
            .then(parseResult)
            .catch(throwError)
}

export default TenantRestful
