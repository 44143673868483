import { HierarchySpace, LocationHierarchy, SimpleCampus, SimpleDisplayObject, SimpleFloor } from './bms'
import { BaseModel, SimpleBase } from './base'
import { getValueWithLocale } from 'util/localeHelper'

export const LocationStringPaddingStar = (
  campus_id: string,
  building_id?: string,
  floor_idx?: number,
  space_id?: string,
) => `${campus_id || '*'}:${building_id || '*'}:${floor_idx === undefined ? '*' : floor_idx}:${space_id || '*'}`

export const LocationString = (campus_id: string, building_id?: string, floor_idx?: number, space_id?: string) =>
  `${campus_id || ''}:${building_id || ''}:${floor_idx === undefined ? '' : floor_idx}:${space_id || ''}`

export const LocationStringFromPermission = (permission: LocationPermission) => {
  console.log('LS permission', permission)

  if (!permission) return ''

  const LS = (campus_id: string, building_id?: string, floor_idx?: string, space_id?: string) =>
    `${campus_id || ''}:${building_id || ''}:${floor_idx === undefined ? '' : floor_idx}:${space_id || ''}`

  return LS(
    permission.all?.campus ? '*' : permission?.campus?.id,
    permission.all?.building ? '*' : permission?.building?.id || '',
    permission.all?.floor ? '*' : (permission?.floor ? permission.floor.idx : '') + '',
    permission.all?.space ? '*' : permission?.space?.id || '',
  )
}

export const LocationStringFromHierarchy = (hierarchy: LocationHierarchy) =>
  LocationString(hierarchy.campus?.id, hierarchy.building?.id, hierarchy.floor?.idx, hierarchy.space?.id)

export const LocationStringFromSpace = (space: HierarchySpace) =>
  LocationString(
    space?.hierarchy?.campus?.id,
    space?.hierarchy?.building?.id || '',
    space?.hierarchy?.floor?.idx || null,
    space.id,
  )

export const LocationStringToIds = (locationString: string) => {
  const [campus_id, building_id, floor_id, space_id] = locationString.split(':')
  return { campus_id, building_id, floor_id, space_id }
}

export const SpaceFromHierarchy: (hierarchy: LocationHierarchy) => HierarchySpace = (hierarchy: LocationHierarchy) => ({
  id: hierarchy.space.id,
  display_name: getValueWithLocale('display_name', hierarchy.space),
  hierarchy: hierarchy,
})
// User

export interface DisplayUser {
  id: string,
  profile_picture_url: string,
  display_name: string,
  first_name?: string,
  last_name?: string,
}

export interface BaseUser extends DisplayUser {
  allow: LocationPermission[],
  exclude: LocationPermission[],
  group_admin?: boolean,
}

export interface User extends BaseUser {
  organization_identifier?: string,
  policies?: string[],

  area_code: string,
  phone_number: string,
  staff_number?: string,
  user_groups?: SimpleBase[],
  user_group_id?: string,
  email?:string,
}

export interface UserProfile extends User {
  birth_year: number,
  birth_month: number,
  sex?: string,
  id_card_number?: string,
  email?: string,
  created?: number,
  preferred_language?: string,
}

export interface SimpleLocationPermission {
  campus: SimpleCampus,
  building?: SimpleDisplayObject,
  all: {
    campus: boolean,
    building: boolean,
  },
}

export type PermissionType = 'TM' | 'LTUM' | 'PMS' | 'OTHER'
export interface LocationPermission extends SimpleLocationPermission {
  type: PermissionType,
  campus: SimpleCampus,
  building: SimpleDisplayObject,
  floor: SimpleFloor,
  space: SimpleDisplayObject,
  all: {
    campus: boolean,
    building: boolean,
    floor: boolean,
    space: boolean,
  },
}

export type UserGroupType = 'system-defined' | 'user-defined'

export interface UserGroupCategory extends SimpleBase {
  subcategory: SimpleBase,
}

// HRDP:APP:*:*:*:*:*:*
export type UserGroupRole = { id: string, resource: string }
export interface UserGroup {
  id: string,
  description: string,
  category: UserGroupCategory,
  display_name: string,
  type: UserGroupType,
  roles: UserGroupRole[],
}

export interface TokenResult {
  access_token: string,
  expires_in: number,
  refresh_token: string,
  token_type: 'bearer',
}

export interface APIKey {
  id: string,
  api_key_group_id: string,
  display_name: string,
  key: string,
  expire_at: number,

  created_at: number,
  revoked_at: number,
  modified_at: number,

  created_by_user: DisplayUser,
  revoked_by_user: DisplayUser,
  modified_by_user: DisplayUser,
}

export interface APIKeyGroup {
  id: string,
  display_name: string,
  number_of_active_keys: number,
  number_of_expire_keys: number,
  locations: SimpleLocationPermission[],
}

export interface AuthUser extends User, TokenResult {}

export interface VerifyPolicies {
  policy: string,
  valid: boolean,
}
export interface VerifyPolicyResponse {
  campus_id: string,
  policies: VerifyPolicies[],
}
