import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse } from '../../restful-provider'
import { SimpleCampus, SimpleBuilding, SimpleFloor, SimpleSpace, CreatingBuilding, SpaceType, WorkOrderListItem, WorkOrderStatusObject, WorkOrderType, SimpleBase, CampusWorkOrderOverview, WorkOrderFilterStatusEnum, WorkOrderDetailItem } from '../../../schema'

export type CreateBuildingResponse = { campus_id: string, building: SimpleBuilding }
export type CreateSpaceInCampusResponse = { campus_id: string, space: SimpleSpace }

export type CreateFloorResponse = { building_id: string, floor: SimpleFloor }
export type CreateSpaceInBuildingResponse = { building_id: string, space: SimpleSpace }

export type CreateSpaceInFloorResponse = { floor_id: string, space: SimpleSpace }
export type CreateSpaceInSpaceResponse = { space_id: string, space: SimpleSpace }

export type CreateFloorParam = {
    building_id: string,
    display_name: string,
    idx: number,
}

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class WorkOrderRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }
    subRoute = '/work-order/properties-management-service/work-order-management'



    getStatuses = () =>
        this.client
            .get<APIHTTPResponse<WorkOrderStatusObject>>(`${this.subRoute}/get-statuses`)
            .then(parseResult)

    getTypes = () =>
        this.client
            .get<APIHTTPResponse<WorkOrderType>>(`${this.subRoute}/get-types`)
            .then(parseResult)


    getRepairAreaTypes = () =>
        this.client
            .get<APIHTTPResponse<SimpleBase>>(`${this.subRoute}/get-repair-area-types`)
            .then(parseResult)


    getOrder = (id: string) =>
        this.client
            .get<PaginatedResponse<WorkOrderDetailItem>>(`${this.subRoute}/get-work-order-by-id`, { params: { id } })
            .then(parseResult)

    getOrders = (searchText?: string, type_ids?: string[], campus_id?: string, building_id?: string,
        status_id?: WorkOrderFilterStatusEnum,
        repair_area_type_ids?: string[], page_number = 1, page_size = 1000) =>
        this.client
            .post<PaginatedResponse<WorkOrderListItem>>(`${this.subRoute}/get-work-orders`, {
                display_name: searchText,
                type_ids: type_ids || [],
                campus_id,
                building_id,
                repair_area_type_ids: repair_area_type_ids || [],
                status_id,
                page_number,
                page_size,
            })
            .then((d) => d.data)

    getOrderStatusCounts = () =>
        this.client
            .get<APIHTTPResponse<CampusWorkOrderOverview>>(`${this.subRoute}/get-work-order-status-counts`)
            .then(parseResult)

}

export default WorkOrderRestful
