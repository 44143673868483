import * as R from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import {
    AnalyticsRequest,
    AnalyticsResponse,
    MemberAnalyticsRecord,
    NonMemberAnalyticsRecord,
} from '../../../schema'

// const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result[0]
const parseResult = (res) => res.data

/**
 * Restful endpoints for BMS module
 */
class AnalyticsRestful {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/leasing-management/analytics'

    getMemberNumber = (params: AnalyticsRequest) =>
        this.client
            .get<AnalyticsResponse<MemberAnalyticsRecord>>(`${this.subRoute}/user-statistic/get-member-count`, { params })
            .then(parseResult)
            .catch(throwError)

    getNonMemberNumber = (params: AnalyticsRequest) =>
        this.client
            .get<AnalyticsResponse<NonMemberAnalyticsRecord>>(`${this.subRoute}/user-statistic/get-non-member-count`, { params: R.omit(['campus_id'], params) })
            .then(parseResult)
            .catch(throwError)

    getQrcodeFrontAccessUsage = (params: AnalyticsRequest) =>
        this.client
            .get<APIHTTPResponse<AnalyticsResponse<MemberAnalyticsRecord>>>(`${this.subRoute}/qrcode-statistic/get-front-access-gate-usage-by-campus`, { params })
            .then(parseResult)
            .catch(throwError)

    getVisitorUsage = (params: AnalyticsRequest) =>
        this.client
            .get<APIHTTPResponse<AnalyticsResponse<MemberAnalyticsRecord>>>(`${this.subRoute}/visitor-service-statistic/get-usage`, { params })
            .then(parseResult)
            .catch(throwError)
}

export default AnalyticsRestful
