import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import firebase from './util/firebase'

firebase.analytics()
const rootEl = document.getElementById('app-site')

// Create a reusable render method that we can call more than once
const render = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').MainApp
  ReactDOM.render(
    <MainApp/>,
    rootEl
  )
}

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').MainApp
    render(
      <MainApp/>,
      rootEl
    )
  })
}

render()
