import React, { Component } from "react"
import { Redirect } from "react-router-dom"

import AppLayout from "./AppLayout/index"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import asyncComponent from "../util/asyncComponent"
import appRoute from '../app/routes'
import access from '../access'
// Need to route based on role

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/app" />
  }
]

const errorRoute = [
  {
    path: "error/401",
    component: asyncComponent(() => import('../app/routes/extraPages/routes/401')) 
  },
  {
    component: asyncComponent(() => import('../app/routes/extraPages/routes/404')) 
  },
]


const routes = [
  // redirectRoute, // crash
  {
    path: '/cn/signin',
    component: SignIn
  },
  {
    path: '/hk/signin',
    component: SignIn
  },
  {
    path: '/signup',
    component: SignUp
  },
  ...access,
  ...appRoute,
  ...redirectRoute,
  ...errorRoute

]

console.warn(routes)

export default routes
