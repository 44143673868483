import React, {useState} from 'react'
import Avatar from '@material-ui/core/Avatar'
import {useDispatch} from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IntlMessages from 'util/IntlMessages'
import { AuthActions, User, UserProfile } from '@exaleap/common'

import { useHistory } from 'react-router-dom'
import { useSelector } from 'state/root-store'
import UserAvatar from 'components/UserAvatar'


type VariantType = "dense" | "normal"

const UserInfo = ({variant}: {variant: VariantType}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorE1, setAnchorE1] = useState(null)
  const [open, setOpen] = useState(false)

  const authUser : User = useSelector((s) => s.auth.authUser)
  const profile: UserProfile = useSelector((s) => s.auth.profile)

  const handleClick = event => {
    setOpen(true)
    setAnchorE1(event.currentTarget)
  }

  const handleRequestClose = () => setOpen(false)

  return (
    <div className="user-profile d-flex flex-row align-items-center ">

      <UserAvatar
       user={profile}
       onClick={e => handleClick(e)}

        style={{
          width: 40,
          cursor: 'pointer',
          backgroundColor: '#6FCF97'
        }}
      />
      {/* <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>{authUser? authUser.display_name: ""}<i
          className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
        </h4>
      </div> */}
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorE1}
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem onClick={() => {
          handleRequestClose()
          history.push('/app/profile')
        }}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
          <IntlMessages id="generic.menu.profile"/>
        </MenuItem>

        <MenuItem onClick={() => {
          handleRequestClose()
          history.push('/app/setting')
        }}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
          <IntlMessages id="generic.menu.setting"/>
        </MenuItem>

        <MenuItem onClick={() => {
          handleRequestClose()
          dispatch(AuthActions.logoutAuthUser())
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

          <IntlMessages id="generic.menu.logout"/>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserInfo


