// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu'
export const DRAWER_TYPE = 'drawer_type'
export const FIXED_DRAWER = 'fixed_drawer'
export const COLLAPSED_DRAWER = 'collapsible'
export const MINI_DRAWER = 'mini_drawer'
export const THEME_COLOR = 'theme_color'
export const DARK_THEME = 'dark_theme'
export const WINDOW_WIDTH = 'window-width'
export const SWITCH_LANGUAGE = 'switch-language'
export const CHANGE_DIRECTION = 'change-direction'

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style'
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation'
export const VERTICAL_NAVIGATION = 'vertical_navigation'

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position'
export const ABOVE_THE_HEADER = 'above_the_header'
export const INSIDE_THE_HEADER = 'inside_the_header'
export const BELOW_THE_HEADER = 'below_the_header'

//Contact Module const

export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'
export const SHOW_MESSAGE = 'show_message'
export const HIDE_MESSAGE = 'hide_message'


//Auth const
export const SIGNUP_USER = 'signup_user'
export const SIGNUP_USER_SUCCESS = 'signup_user_success'

export const SIGNIN_USER = 'signin_user'
export const SIGNIN_USER_SUCCESS = 'signin_user_success'
export const SIGNOUT_USER = 'signout_user'
export const SIGNOUT_USER_SUCCESS = 'signout_user_success'
export const INIT_URL = 'init_url'
export const USER_DATA = 'user_data'
export const USER_TOKEN_SET = 'user_token_set'


export const FILTER_USERS = 'filter_users'
export const ON_SELECT_USER = 'on_select_user'
export const ON_SHOW_LOADER = 'on_show_loader'
export const ON_HIDE_LOADER = 'on_hide_loader'
export const USER_INFO_STATE = 'user_info_state'
export const SUBMIT_COMMENT = 'submit_comment'
export const UPDATE_MESSAGE_VALUE = 'update_message_value'
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user'
