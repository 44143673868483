import { all, call, put, takeEvery } from 'redux-saga/effects'
import { Action, NotificationActionFunctions, ACTION_KEY_DASHBOARD, DashboardActions } from '../actions'
import { apiService } from '../../..'
import { SimpleCampus, SimpleBuilding } from '../../schema'

function* requestCampusDashboard(action: Action) {
    console.warn('[SAGA] requestCampusOverview')

    try {
        const campuses = yield call(apiService.building.campusInfo.getCampuses)

        if (action.payload) {
            console.log('action.payload', action.payload)
            const id = action.payload || campuses[0].id
            const details = yield call(apiService.building.campusInfo.getCampuses, id)

            const detail: SimpleCampus = details[0]
            const buildings: SimpleBuilding[] = yield call(apiService.building.campusInfo.getBuildingsInCampus, detail.id)


            yield put(DashboardActions.campusAnalytics.success(campuses, details[0], buildings))
        } else {
            yield put(DashboardActions.campusAnalytics.success(campuses))
        }
    } catch (error) {
        console.warn('[SAGA] requestCampusOverview error', error)
        yield put(DashboardActions.campusAnalytics.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
    }
}

export const dashboardSagas = [takeEvery(ACTION_KEY_DASHBOARD.CAMPUS_ANALYTICS.REQUEST, requestCampusDashboard)]
