import { BaseModel, LocalizationField, LocalizationObject, SimpleBase } from "./base"
import { LocationHierarchy } from "./bms"
import { User } from "./iam"

export type WorkOrderStatusEnum = 'WAITING_PROCESS' | 'PROCESSING' | 'DONE' | 'CLOSE'
export type WorkOrderFilterStatusEnum = WorkOrderStatusEnum | 'TOTAL'


export const WorkOrderColor = (status: WorkOrderStatusEnum) => {
    switch (status) {
        case 'WAITING_PROCESS':
            return '#EA4B97'
        case 'PROCESSING':
            return '#2D9CDB'
        case 'DONE':
            return '#219653'
        case 'CLOSE':
            return '#828282'
    }
}



export interface WorkOrderListItem extends BaseModel {
    svc_id: string,
    requester: User,
    code: string,
    status_id: WorkOrderStatusEnum,
    type_id: string,
    repair_area_type_id: string,
    repair_location?: string,
    created: number,
}

export interface WorkOrderEvent {
    created: number,
    description: string,
    followup_processor_name: string,
    record_state: string,
    record_type: string,
    record_user_name: string,
    status_id: WorkOrderStatusEnum,
}

interface WorkOrderComment {
    created: number,
    subjectsTitle: string,
    title: string,
}

export interface WorkOrderDetailItem extends WorkOrderListItem {
    locations: LocationHierarchy,
    orig_user_name: string,
    is_paid: boolean,
    work_order_system: string,
    work_order_link: string,
    status_updates: WorkOrderEvent[],
    comments: WorkOrderComment[],
}




export interface BuildingWorkOrderOverview {
    building_id: string,
    statuses: {
        [key in WorkOrderFilterStatusEnum]: number
    },
}

export interface CampusWorkOrderOverview {
    campus_id: string,
    records : BuildingWorkOrderOverview[],
}

export interface WorkOrderStatusObject extends LocalizationObject {
    id: WorkOrderStatusEnum,
    step: number,
    display_name: string,

}

export interface WorkOrderType extends SimpleBase, LocalizationObject {
    img_url: string,
    localization: {
        display_name: LocalizationField,
    },
}



const waitingProcess: WorkOrderStatusObject = {
    id: 'WAITING_PROCESS',
    step: 0,
    display_name: '123',
    localization: {
        'display_name': {
            en: '123',
            tc: '123',
            sc: '123'
        }
    }
}