import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { ListRequest, User, UserGroup, UserProfile } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class TenantUserManagement {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/tenant-service/user-management'

    user = (id: string) =>
        this.client
            .get<APIHTTPResponse<User>>(`${this.subRoute}/get-users`, {
                params: { user_id: id },
            })
            .then((d) => d.data)
            .catch(throwError)

    admins = () =>
        this.client.get<APIHTTPResponse<User>>(`${this.subRoute}/get-user-admins`).then(parseResult).catch(throwError)

    users = (params?: ListRequest) =>
        this.client.get<PaginatedResponse<User>>(`${this.subRoute}/get-users`, {
            params
        }).then(d => d.data).catch(throwError)

    addUser = (username: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/add-user`, {
                type: "username",
                value: username,
            })
            .then(parseResult)
            .catch(throwError)

    dropUser = (user_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/drop-user`, {
                params: { id: user_id },
            })
            .then(parseResult)
            .catch(throwError)

    deleteUserAccessPermission = (params: { user_id: string, allow: string }) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/delete-user-access-permission`, {
                params,
            })
            .then(parseResult)
            .catch(throwError)

    deleteAllUserAccessPermissionsInSpace = (params: { id: string }) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/delete-all-user-access-permissions-in-space`, {
                params,
            })
            .then(parseResult)
            .catch(throwError)

    getUserGroups = (id?: string) =>
        this.client
            .get<APIHTTPResponse<UserGroup>>(`${this.subRoute}/get-user-groups`, { params: id ? { id } : null })
            .then(parseResult)
            .catch(throwError)

    getUsersByUserAccessPermission = (params: ListRequest & {
        allow: string,
        with_allow: boolean,
    }) =>
        this.client
            .get<PaginatedResponse<User>>(`${this.subRoute}/search-users-by-user-access-permission`, { params })
            .then(d => d.data)
            .catch(throwError)

    getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
        this.client
            .get<PaginatedResponse<User>>(`${this.subRoute}/get-users-in-user-group`, { params: { user_group_id, ...params } })
            .then(d => d.data)
            .catch(throwError)

    getUsersNotInUserGroup = (user_group_id: string, params?: ListRequest) =>
        this.client
            .get<PaginatedResponse<User>>(`${this.subRoute}/get-users-not-in-user-group`, { params: { user_group_id, ...params } })
            .then(d => d.data)
            .catch(throwError)

    addUserToUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<User>>(`${this.subRoute}/add-user-to-user-group`, { user_id, user_group_id })
            .then(parseResult)
            .catch(throwError)

    dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<User>>(`${this.subRoute}/drop-user-from-user-group`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    getUsersInSupportedUserGroup = (user_group_id: string) =>
        this.client
            .get<APIHTTPResponse<User>>(`${this.subRoute}/get-users-in-supported-user-group`, {
                params: { user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    addUserToSupportedUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<User>>(`${this.subRoute}/add-user-to-supported-user-group`, {
                user_id,
                user_group_id,
            })
            .then(parseResult)
            .catch(throwError)

    dropUserFromSupportedUserGroup = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<User>>(`${this.subRoute}/drop-user-from-supported-user-group`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    copyUserAccessPermissions = (params: { from_space_id: string, to_space_ids: string[] }) =>
        this.client
            .patch<APIHTTPResponse<any>>(`${this.subRoute}/copy-user-access-permissions`, {
                ...params,
            })
            .then(parseResult)
            .catch(throwError)

    moveUserAccessPermissions = (params: { from_space_id: string, to_space_ids: string[] }) =>
        this.client
            .patch<APIHTTPResponse<any>>(`${this.subRoute}/move-user-access-permissions`, {
                ...params,
            })
            .then(parseResult)
            .catch(throwError)

    setUserAsGroupAdmin = (user_id: string, user_group_id: string) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/set-user-as-user-group-admin`, { user_id, user_group_id })
            .then(parseResult)
            .catch(throwError)

    unsetUserFromGroupAdmin = (user_id: string, user_group_id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(`${this.subRoute}/unset-user-from-user-group-admin`, {
                params: { user_id, user_group_id },
            })
            .then(parseResult)
            .catch(throwError)

    setAccessPermissionForUsers = (allow: string, user_ids: string[]) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/set-user-access-permission-for-users`, {
                user_ids,
                allow,
            })
            .then(parseResult)
            .catch(throwError)

    setAccessPermission = (user_id: string, allow: string[], exclude: string[] = []) =>
        this.client
            .post<APIHTTPResponse<any>>(`${this.subRoute}/set-user-access-permission`, {
                user_id,
                allow,
                exclude
            })
            .then(parseResult)
            .catch(throwError)

    // not calling atm
    getAccessPermission = (user_id: string, permission_id: string) => this.client
        .get<APIHTTPResponse<User>>(`${this.subRoute}/get-user-access-permissions`, {
            params: { user_id, permission_id },
        })
        .then(parseResult)
        .catch(throwError)


    getBindingRequests = (params?: ListRequest) => this.client
        .get<PaginatedResponse<UserProfile>>(`${this.subRoute}/get-user-binding-requests`, {
            params
        })
        .then(d => d.data)
        .catch(throwError)

    actionOnBindingRequest = (user_ids: string[], approve: boolean) => this.client
        .post<APIHTTPResponse<{ failure: number, success: number, total: number }>>(`${this.subRoute}/approve-user-binding-request`, { user_ids, approve })
        .then(parseResult)
        .catch(throwError)

}

export default TenantUserManagement
